<template>
  <div class="spinner m-1 d-flex align-items-center">
    <div class="spinner-text me-1">{{ textToShow }}</div>
    <font-awesome icon="spinner" class="fa-spinner"></font-awesome>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
export default {
  props: {
    text: {
      type: String,
      default: "Loading...",
    },
    texts: {
      type: Array,
    },
  },
  setup(props) {
    const textToShow = ref(props.text);

    let interval;

    onMounted(() => {
      if (props.texts == null || props.texts.length <= 0) {
        return;
      }

      let index = 0;
      interval = setInterval(() => {
        if (props.texts[index] != null) {
          textToShow.value = props.texts[index];
        }
        index++;
      }, 1000);
    });

    onUnmounted(() => {
      if (interval != null) {
        clearInterval(interval);
      }
    });

    return { textToShow };
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  & svg {
    height: 22px;
    width: 22px;
  }
}
</style>
