<template>
  <div class="row" v-show="scene.showUserPanel">
    <panel barColor="sky">
      <div class="row align-items-center">
        <div class="col-4 d-flex align-items-start p-1"></div>
        <div class="col-4 align-self-center panel-title p-1">{{ mainTitle }}</div>
        <div class="col-4 d-flex justify-content-end"></div>
        <div class="row mt-4 text-start">
          <div class="col-2">
            <label for="Code" class="form-label">Code</label>
            <input id="Code" v-model="searchCode" type="text" class="form-control" :class="{ 'is-invalid': isInvalidCode }" placeholder="10000000" />
          </div>
          <div class="col-2">
            <label for="WorkRequest" class="form-label">Work Request</label>
            <input id="WorkRequest" v-model="searchWorkRequest" type="text" class="form-control" placeholder="yy-999999" />
          </div>
          <div class="col-2">
            <label for="State" class="form-label">State</label>
            <select id="State" v-model="searchState" class="form-select">
              <option v-for="(state, index) in states" :key="index" :value="state">
                {{ getLogStateName(state) }}
              </option>
            </select>
          </div>
          <div class="col-2">
            <label for="User" class="form-label">User</label>
            <select id="User" v-model="searchUser" class="form-select">
              <option v-for="(user, index) in users" :key="index" :value="user">
                {{ user ? user.fullName : "" }}
              </option>
            </select>
          </div>
        </div>
        <div class="row text-start mt-2">
          <div class="col-4">
            <label for="FromPublication" class="form-label">From Publication</label>
            <select id="FromPublication" v-model="searchFromPublication" class="form-select">
              <option v-for="(publication, index) in publications" :key="index" :value="publication">
                {{ publication ? publication.publicationName + " (" + publication.version + ")" : "" }}
              </option>
            </select>
          </div>
          <div class="col-4">
            <label for="ToPublication" class="form-label">To Publication</label>
            <select id="ToPublication" v-model="searchToPublication" class="form-select">
              <option v-for="(publication, index) in publications" :key="index" :value="publication">
                {{ publication ? publication.publicationName + " (" + publication.version + ")" : "" }}
              </option>
            </select>
          </div>
          <div class="col-4 d-flex align-self-end justify-content-between">
            <button @click="search" class="btn btn-light me-3">Search<font-awesome icon="search" class="ms-1 button-icon"></font-awesome></button>
            <button @click="download" class="btn btn-light" :disabled="isDownloadingLogs || mappedLogs.length == 0">
              Download<font-awesome icon="file-download" class="ms-1 button-icon"></font-awesome>
            </button>
          </div>
        </div>
        <div class="col-12 text-start mt-4">
          <alert v-bind="bindableAlert" class="mt-3 mb-3"></alert>
          <loader
            v-if="isFetchingLogs || isGettingUsers || isFetchingPublications || isDownloadingLogs"
            class="d-flex justify-content-center mb-3"
          ></loader>
          <div v-show="mappedLogs.length > 0" class="gpc-table table-responsive">
            <table class="table table-hover table-sm caption-top" aria-label="GPC history">
              <caption class="panel-title orange">
                Classifications
              </caption>
              <thead>
                <tr>
                  <th scope="col" class="fw-bold">Level</th>
                  <th scope="col" class="fw-bold">Code</th>
                  <th scope="col" class="fw-bold">Title</th>
                  <th scope="col" class="fw-bold">State</th>
                  <th scope="col" class="fw-bold">WR</th>
                  <th scope="col" class="fw-bold">User</th>
                  <th scope="col" class="fw-bold">Date</th>
                  <th scope="col" class="fw-bold">Comment</th>
                  <th scope="col" class="fw-bold"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(log, index) in mappedLogs" :key="index">
                  <td>{{ log.level }}</td>
                  <td>{{ log.code }}</td>
                  <td class="text-truncate max-width-300" :title="log.title">{{ log.title }}</td>
                  <td>{{ log.state }}</td>
                  <td>{{ log.workRequest }}</td>
                  <td>{{ log.userName }}</td>
                  <td>{{ log.insertDate }}</td>
                  <td class="text-truncate max-width-300">{{ log.comment }}</td>
                  <td><button @click="undoClassificationLog(log)" class="btn btn-sm btn-outline-danger">Undo</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <loader v-if="isFetchingRelationLogs" class="d-flex justify-content-center my-3"></loader>
          <div v-show="mappedRelationLogs.length > 0" class="gpc-relation-table table-responsive">
            <table class="table table-hover table-sm caption-top" aria-label="GPC relation history">
              <caption class="panel-title orange">
                Relations
              </caption>
              <thead>
                <tr>
                  <th scope="col" class="fw-bold">Brick Code</th>
                  <th scope="col" class="fw-bold">Brick Title</th>
                  <th scope="col" class="fw-bold">Attribute Code</th>
                  <th scope="col" class="fw-bold">Attribute Title</th>
                  <th scope="col" class="fw-bold">Attribute Value Code</th>
                  <th scope="col" class="fw-bold">Attribute Value Title</th>
                  <th scope="col" class="fw-bold">State</th>
                  <th scope="col" class="fw-bold">WR</th>
                  <th scope="col" class="fw-bold">User</th>
                  <th scope="col" class="fw-bold">Date</th>
                  <th scope="col" class="fw-bold">Comment</th>
                  <th scope="col" class="fw-bold"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(log, index) in mappedRelationLogs" :key="index">
                  <td>{{ log.brick.code }}</td>
                  <td class="text-truncate max-width-100" :title="log.brick.title">{{ log.brick.title }}</td>
                  <td>{{ log.attribute.code }}</td>
                  <td class="text-truncate max-width-100" :title="log.attribute.title">{{ log.attribute.title }}</td>
                  <td>{{ log.attributeValue.code }}</td>
                  <td class="text-truncate max-width-100" :title="log.attributeValue.title">{{ log.attributeValue.title }}</td>
                  <td>{{ log.state }}</td>
                  <td>{{ log.workRequest }}</td>
                  <td>{{ log.userName }}</td>
                  <td>{{ log.insertDate }}</td>
                  <td class="text-truncate max-width-100">{{ log.comment }}</td>
                  <td><button @click="undoRelationLog(log)" class="btn btn-sm btn-outline-danger">Undo</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import Loader from "../components/shared/Loader.vue";
import Panel from "../components/shared/Panel.vue";
import Alert from "../components/shared/Alert.vue";
import useAlert from "../composables/shared/useAlert";
import useClassification from "../composables/classification/useClassification";
import useUserSingleton from "../composables/shared/useUserSingleton";
import useLogs from "../composables/log/useLogs";
import gpcEnumRepository from "../repositories/gpcEnumRepository";
import usePublication from "../composables/publication/usePublication";
import { downloadXlsx } from "../utils/fileUtil";

export default {
  components: { Loader, Panel, Alert },
  setup() {
    const alert = useAlert();
    const {
      getLogStateName,
      getRelationLogStateName,
      isFetchingLogs,
      isFetchingRelationLogs,
      fetchLogsAsync,
      fetchRelationLogsAsync,
      logs,
      relationLogs,
      downloadLogsAsXlsxAsync,
      isDownloadingLogs,
      isUndoingLog,
      undoClassificationLogAsync,
      undoRelationLogAsync,
    } = useLogs();
    const { languageId, user, getUsersByLanguageAsync, isGettingUsers } = useUserSingleton();
    const { getLevelName } = useClassification();
    const { fetchPublicationsAsync, isFetchingPublications } = usePublication();

    const mainTitle = ref(`Classification History`);
    const scene = reactive({
      showUserPanel: true,
    });
    const states = ref([]);
    const users = ref([]);
    const publications = ref([]);

    const searchCode = ref(null);
    const searchWorkRequest = ref(null);
    const searchState = ref(null);
    const searchUser = ref(null);
    const searchFromPublication = ref(null);
    const searchToPublication = ref(null);

    const validationErrorMessage = reactive({
      invalidMessageCode: "",
    });

    const mappedLogs = computed(() => {
      if (logs.value && logs.value.length) {
        return logs.value.map((item) => {
          item.insertDate = new Date(item.insertDate).toLocaleString();
          item.state = getLogStateName(item.state);
          item.level = getLevelName(item.level);
          return item;
        });
      }
      return [];
    });

    const mappedRelationLogs = computed(() => {
      if (relationLogs.value && relationLogs.value.length) {
        return relationLogs.value.map((item) => {
          item.insertDate = new Date(item.insertDate).toLocaleString();
          item.state = getRelationLogStateName(item.state);
          return item;
        });
      }
      return [];
    });

    const isInvalidCode = computed(() => {
      if (searchCode.value != null && searchCode.value != "" && (searchCode.value < 10000000 || searchCode.value > 99999999)) {
        validationErrorMessage.invalidMessageCode = "Code must be between 10000000 to 99999999";
        return true;
      }
      return false;
    });

    onMounted(async () => {
      mainTitle.value = `${user.value.language.languageName} Classification History`;
      states.value = Object.values(gpcEnumRepository.ClassificationState);
      states.value.unshift(null);
      const usersResponse = await getUsersByLanguageAsync(languageId.value);
      if (usersResponse.isSuccess) {
        users.value = usersResponse.result;
        users.value.unshift(null);
      } else {
        alert.error([usersResponse.message]);
      }
      const publicationsResponse = await fetchPublicationsAsync(languageId.value);
      if (publicationsResponse.isSuccess) {
        publications.value = publicationsResponse.result;
        publications.value.sort(function(a, b) {
          return new Date(b.publicationDate) - new Date(a.publicationDate);
        });
        publications.value.unshift(null);
      } else {
        alert.error([publicationsResponse.message]);
      }
    });

    function search() {
      if (isInvalidCode.value) {
        return;
      }

      logs.value = [];
      relationLogs.value = [];

      let searchedCode = searchCode.value != null && searchCode.value != "" ? searchCode.value : null;
      let searchedWr = searchWorkRequest.value != null && searchWorkRequest.value != "" ? searchWorkRequest.value : "";
      let searchedState = searchState.value != null && searchState.value != "" ? searchState.value : null;
      let searchedUser = searchUser.value != null && searchState.value != "" ? searchUser.value.userId : null;
      let searchedFromPublication =
        searchFromPublication.value != null && searchFromPublication.value != "" ? searchFromPublication.value.publicationDate : null;
      let searchedToPublication =
        searchToPublication.value != null && searchToPublication.value != "" ? searchToPublication.value.publicationDate : null;

      fetchLogsAsync(
        user.value.language.languageId,
        searchedCode,
        searchedWr,
        searchedState,
        searchedUser,
        searchedFromPublication,
        searchedToPublication
      );
      fetchRelationLogsAsync(
        user.value.language.languageId,
        searchedCode,
        searchedWr,
        searchedState,
        searchedUser,
        searchedFromPublication,
        searchedToPublication
      );
    }

    async function download() {
      if (isDownloadingLogs.value) {
        return;
      }
      let data = await downloadLogsAsXlsxAsync(
        user.value.language.languageId,
        searchCode.value != null && searchCode.value != "" ? searchCode.value : null,
        searchWorkRequest.value != null && searchWorkRequest.value != "" ? searchWorkRequest.value : "",
        searchState.value != null && searchState.value != "" ? searchState.value : null,
        searchUser.value != null && searchState.value != "" ? searchUser.value.userId : null,
        searchFromPublication.value != null && searchFromPublication.value != "" ? searchFromPublication.value.publicationDate : null,
        searchToPublication.value != null && searchToPublication.value != "" ? searchToPublication.value.publicationDate : null
      );
      downloadXlsx(data.name, data.file);
    }

    async function undoClassificationLog(log) {
      if (isUndoingLog.value) {
        return;
      }
      const response = await undoClassificationLogAsync(log.id);
      if (response.isSuccess) {
        alert.success([response.message]);
      } else {
        alert.error([response.message]);
      }
    }

    async function undoRelationLog(log) {
      if (isUndoingLog.value) {
        return;
      }
      const response = await undoRelationLogAsync(log.id);
      if (response.isSuccess) {
        alert.success([response.message]);
      } else {
        alert.error([response.message]);
      }
    }

    return {
      isFetchingLogs,
      isFetchingRelationLogs,
      mainTitle,
      scene,
      states,
      users,
      publications,
      bindableAlert: alert.bindableAlert,
      isInvalidCode,
      searchCode,
      searchWorkRequest,
      searchState,
      searchUser,
      searchFromPublication,
      searchToPublication,
      validationErrorMessage,
      mappedLogs,
      mappedRelationLogs,
      search,
      download,
      getLogStateName,
      isGettingUsers,
      isFetchingPublications,
      isDownloadingLogs,
      isUndoingLog,
      undoClassificationLog,
      undoRelationLog
    };
  },
};
</script>

<style lang="scss" scoped>
.panel {
  & .panel-title.orange {
    color: $gs1-color-orange;
  }
  & .classification-row:hover {
    & td .button-icon {
      &.small {
        cursor: pointer;
        user-select: initial;
        pointer-events: initial;
        opacity: 1;
      }
    }
  }

  & .button-icon {
    height: 20px;
    width: 20px;
    cursor: pointer;

    &.small {
      height: 17px;
      width: 17px;
    }
  }
  & .disabled {
    cursor: default;
    user-select: none;
    opacity: 0.3;
  }
  & .max-width-300 {
    max-width: 300px;
  }
  & .max-width-100 {
    max-width: 100px;
  }
}
</style>
