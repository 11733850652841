import { httpCustom, getFileName } from "../services/httpService";

export default (function() {
  async function downloadBrickBySegmentReportAsXlsxAsync(publicationId, languageId) {
    let response = await httpCustom(`/report/download/brick-by-segment/xlsx?publicationId=${publicationId}&languageId=${languageId}`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }
  async function downloadTranslationVerificationReportAsXlsxAsync(publicationId, languageId) {
    let response = await httpCustom(`/report/download/translation-verification/xlsx?publicationId=${publicationId}&languageId=${languageId}`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }
  async function downloadHierarchyReportAsXlsxAsync(languageId) {
    let response = await httpCustom(`/report/download/hierarchy/xlsx?languageId=${languageId}`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }
  async function downloadSegmentReportAsXlsxAsync(languageId) {
    let response = await httpCustom(`/report/download/segment/xlsx?languageId=${languageId}`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }
  async function downloadTopTenSegmentsReportAsXlsxAsync(languageId) {
    let response = await httpCustom(`/report/download/top-10-segments/xlsx?languageId=${languageId}`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }
  async function downloadAttributesByBrickReportAsXlsxAsync(languageId) {
    let response = await httpCustom(`/report/download/attribute-by-brick/xlsx?languageId=${languageId}`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }
  async function downloadNumberOfAttributesByBrickReportAsXlsxAsync(languageId) {
    let response = await httpCustom(`/report/download/usage-attribute-by-brick/xlsx?languageId=${languageId}`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }
  async function downloadGdsnBricksMappingReportAsXlsxAsync(publicationId, languageId) {
    let response = await httpCustom(`/report/download/gdsn-brick-map/xlsx?publicationId=${publicationId}&languageId=${languageId}`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }
  return Object.freeze({
    downloadBrickBySegmentReportAsXlsxAsync,
    downloadTranslationVerificationReportAsXlsxAsync,
    downloadHierarchyReportAsXlsxAsync,
    downloadSegmentReportAsXlsxAsync,
    downloadTopTenSegmentsReportAsXlsxAsync,
    downloadAttributesByBrickReportAsXlsxAsync,
    downloadNumberOfAttributesByBrickReportAsXlsxAsync,
    downloadGdsnBricksMappingReportAsXlsxAsync,
  });
})();
