import { httpGet, httpPost, httpPut } from "../services/httpService";

export default (function() {
  async function getLanguages() {
    return httpGet(`/language`);
  }

  async function addLanguageAsync(language) {
    return httpPost(`/language`, language);
  }

  async function updateLanguageAsync(language) {
    return httpPut(`/language`, language);
  }

  async function getLanguagePublicationReportAsync() {
    return httpGet(`/language/publication/report`);
  }

  return Object.freeze({
    getLanguages,
    addLanguageAsync,
    updateLanguageAsync,
    getLanguagePublicationReportAsync,
  });
})();
