<template>
  <language-publication v-if="scene.showLanguagePanel"></language-publication>
  <div class="row" v-show="scene.showLanguagePanel">
    <loader v-if="isLoadingLangueges"></loader>
    <panel>
      <div class="row align-items-center">
        <div class="col-4 d-flex align-items-start p-1"></div>
        <div class="col-4 align-self-center panel-title p-1">{{ mainTitle }}</div>
        <div class="col-4 d-flex justify-content-end">
          <div class="ms-2" title="Create New Publication">
            <span @click="addorUpdateTranslation(null)">
              <font-awesome icon="plus-circle" class="button-icon"></font-awesome>
            </span>
          </div>
        </div>
        <div class="col-12 text-start mt-2">
          <alert v-bind="bindableAlert" class="mt-3 mb-3"></alert>
          <div class="gpc-table table-responsive">
            <table class="table table-hover table-sm" aria-label="GPC languages">
              <thead>
                <tr>
                  <th scope="col" class="col-3 fw-bold">Language name</th>
                  <th scope="col" class="col-1 fw-bold">Lang code</th>
                  <th scope="col" class="col-1 fw-bold">Country code</th>
                  <th scope="col" class="col-1 fw-bold">Culture</th>
                  <th scope="col" class="col-1 fw-bold">Show in Browser</th>
                  <th scope="col" class="col-1 fw-bold">Free to Download</th>
                  <th scope="col" class="col-1 fw-bold">Include Attr/Value</th>
                  <th scope="col" class="col-1 fw-bold">Use publication static files</th>
                  <th scope="col" class="col-2 fw-bold">Browser Contact Email</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="language in languagesToShow" :key="language.id" class="classification-row">
                  <td>{{ language.languageName }}</td>
                  <td>{{ language.languageCode }}</td>
                  <td>{{ language.countryCode }}</td>
                  <td>{{ language.culture }}</td>
                  <td class="align-middle"><input class="form-check-input" type="checkbox" v-model="language.showInBrowser" disabled /></td>
                  <td class="align-middle"><input class="form-check-input" type="checkbox" v-model="language.freeToDownload" disabled /></td>
                  <td class="align-middle">
                    <input class="form-check-input" type="checkbox" v-model="language.includeAttributeAndValues" disabled />
                  </td>
                  <td class="align-middle"><input class="form-check-input" type="checkbox" v-model="language.usePublicationBlobs" disabled /></td>
                  <td>{{ language.moContactEmail }}</td>
                  <td>
                    <span class="float-end" @click="addorUpdateTranslation(language)">
                      <font-awesome icon="pencil-alt" class="hw-20 button-icon small"></font-awesome>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </panel>
  </div>
  <div class="row" v-if="scene.showLanguageEditPanel">
    <language-edit @onSave="handleSaveLanguageEditor" @onClose="handleCloseLanguageEditor" :inputLanguage="inputLanguage"></language-edit>
  </div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import useLanguageSingleton from "../composables/language/useLanguageSingleton";
import Loader from "../components/shared/Loader.vue";
import Panel from "../components/shared/Panel.vue";
import Alert from "../components/shared/Alert.vue";
import LanguageEdit from "../components/language/LanguageEdit.vue";
import useAlert from "../composables/shared/useAlert";
import LanguagePublication from "../components/language/LanguagePublication.vue";

export default {
  components: { Loader, Panel, Alert, LanguageEdit, LanguagePublication },
  setup() {
    const alert = useAlert();
    const { languages, isLoadingLangueges, fetchLanguages } = useLanguageSingleton();

    const mainTitle = ref(`Languages`);
    const inputLanguage = ref({});

    const scene = reactive({
      showLanguagePanel: true,
      showLanguageEditPanel: false,
    });

    const languagesToShow = computed(() => languages.value);

    function addorUpdateTranslation(language) {
      if (language == null) {
        inputLanguage.value = { languageId: 0 };
      } else {
        inputLanguage.value = language;
      }
      scene.showLanguageEditPanel = true;
      scene.showLanguagePanel = false;
    }

    function handleSaveLanguageEditor(response) {
      handleCloseLanguageEditor();
      if (!response.isSuccess) {
        alert.error([response.message]);
      } else {
        alert.success(["Saved successfully!"]);
        fetchLanguages(false);
      }
    }

    function handleCloseLanguageEditor() {
      scene.showLanguageEditPanel = false;
      scene.showLanguagePanel = true;
    }

    return {
      isLoadingLangueges,
      mainTitle,
      languagesToShow,
      scene,
      inputLanguage,
      bindableAlert: alert.bindableAlert,
      addorUpdateTranslation,
      handleSaveLanguageEditor,
      handleCloseLanguageEditor,
    };
  },
};
</script>

<style lang="scss" scoped>
.panel {
  & .classification-row:hover {
    & td .button-icon {
      &.small {
        cursor: pointer;
        user-select: initial;
        pointer-events: initial;
        opacity: 1;
      }
    }
  }

  & .button-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;

    &.small {
      height: 17px;
      width: 17px;
      user-select: none;
      pointer-events: none;
      opacity: 0;
    }
  }
  & .disabled {
    cursor: default;
    user-select: none;
    opacity: 0.3;
  }
}
</style>
