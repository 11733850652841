import { httpGet } from "../services/httpService";

export default (function() {
  async function loginAsync() {
    return httpGet(`/user/login`);
  }

  async function getUsersAsync() {
    return httpGet(`/user/list/detailed`);
  }

  async function getUsersByLanguageAsync(languageId) {
    return httpGet(`/user/list?languageId=${languageId}`);
  }

  return Object.freeze({
    loginAsync,
    getUsersAsync,
    getUsersByLanguageAsync
  });
})();
