<template>
  <div class="row" v-show="scene.showUserPanel">
    <loader v-if="isGettingUsers"></loader>
    <panel barColor="sky">
      <div class="row align-items-center">
        <div class="col-4 d-flex align-items-start p-1"></div>
        <div class="col-4 align-self-center panel-title p-1">{{ mainTitle }}</div>
        <div class="col-4 d-flex justify-content-end"></div>
        <div class="col-12 text-start mt-2">
          <alert v-bind="bindableAlert" class="mt-3 mb-3"></alert>
          <div class="gpc-table table-responsive">
            <table class="table table-hover table-sm" aria-label="GPC users">
              <thead>
                <tr>
                  <th scope="col" class="fw-bold">Last Login</th>
                  <th scope="col" class="fw-bold">Name</th>
                  <th scope="col" class=" fw-bold">Email</th>
                  <th scope="col" class=" fw-bold">Company Name</th>
                  <th scope="col" class=" fw-bold">Company Adress</th>
                  <th scope="col" class=" fw-bold">GPC Status</th>
                  <th scope="col" class=" fw-bold">GPC Role</th>
                  <th scope="col" class=" fw-bold">GPC Language</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in users" :key="user.userId" class="classification-row">
                  <td>{{ user.relativeLoginDate }}</td>
                  <td class="text-nowrap">{{ user.fullName }}</td>
                  <td class="text-nowrap">{{ user.email }}</td>
                  <td>{{ user.companyName }}</td>
                  <td>{{ user.companyAddressCountry }}</td>
                  <td>{{ user.gpcStatus }}</td>
                  <td>{{ user.gpcRole }}</td>
                  <td>{{ user.gpcLanguage }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import Loader from "../components/shared/Loader.vue";
import Panel from "../components/shared/Panel.vue";
import Alert from "../components/shared/Alert.vue";
import useAlert from "../composables/shared/useAlert";
import useUserSingleton from "../composables/shared/useUserSingleton";
import useLanguageSingleton from "../composables/language/useLanguageSingleton";

export default {
  components: { Loader, Panel, Alert },
  setup() {
    const alert = useAlert();
    const { languages } = useLanguageSingleton();
    const { isGettingUsers, getUsersAsync } = useUserSingleton();

    const mainTitle = ref(`Users`);
    const scene = reactive({
      showUserPanel: true,
    });
    const users = ref([]);

    onMounted(async () => {
      let response = await getUsersAsync();
      if (!response.isSuccess) {
        alert.error([response.message]);
      } else {
        users.value = response.result.map((user) => {
          user.loginDate = new Date(user.loginDate).toLocaleString("en-US", { timeZone: "UTC" });
          user.gpcLanguage = user.gpcLanguage.length
            ? user.gpcLanguage
                .map((gpcLang) => {
                  let lang = languages.value.find((f) => f.countryCode.toUpperCase() == gpcLang.toUpperCase());
                  return lang == null ? "" : `${lang.languageName}(${gpcLang})`;
                })
                .filter(Boolean)
                .join()
            : "";
          return user;
        });
      }
    });

    return {
      isGettingUsers,
      mainTitle,
      scene,
      bindableAlert: alert.bindableAlert,
      users,
    };
  },
};
</script>

<style lang="scss" scoped>
.panel {
  & .classification-row:hover {
    & td .button-icon {
      &.small {
        cursor: pointer;
        user-select: initial;
        pointer-events: initial;
        opacity: 1;
      }
    }
  }

  & .button-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;

    &.small {
      height: 17px;
      width: 17px;
      user-select: none;
      pointer-events: none;
      opacity: 0;
    }
  }
  & .disabled {
    cursor: default;
    user-select: none;
    opacity: 0.3;
  }
}
</style>
