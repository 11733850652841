import { getFileName, httpCustom, httpDelete, httpGet, httpPost } from "../services/httpService";

export default (function() {
  async function getLogsAsync(languageId, code = null, wr = "", state = null, userId = null, fromDate = null, toDate = null) {
    return httpGet(
      `/classification/log?languageId=${languageId}&code=${code}&wr=${wr}&state=${state}&userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  async function getLogsRangeAsync(languageId, fromDate, toDate) {
    return httpGet(`/classification/log/range?languageId=${languageId}&fromDate=${fromDate}&toDate=${toDate}`);
  }

  async function getNewCodeAsync(parentCode, level, languageId) {
    return httpGet(`/classification/code/new?parentCode=${parentCode}&level=${level}&languageId=${languageId}`);
  }

  async function saveClassificationAsync(log, classification) {
    return httpPost(`/classification/save`, {
      LanguageId: classification.languageId,
      Level: log.level,
      State: log.state,
      Properties: log.properties,
      WorkRequest: log.workRequest,
      Comment: log.comment,
      EditedClassification: classification,
      Date: log.date,
    });
  }

  async function saveClassificationMoveAsync(parentCode, classification, workRequest, comment) {
    return httpPost(`/classification/move`, {
      ParentCode: parentCode,
      ClassificationToMove: classification,
      WorkRequest: workRequest,
      Comment: comment,
    });
  }

  async function removeClassificationAsync(code, level, langaugeId, workRequest, comment) {
    return httpPost(`/classification/remove`, {
      Code: code,
      Level: level,
      LanguageId: langaugeId,
      WorkRequest: workRequest,
      Comment: comment,
    });
  }

  async function undoLogAsync(logId) {
    return httpDelete(`/classification/log/${logId}`);
  }

  async function downloadLogsAsXlsxAsync(languageId, code = null, wr = "", state = null, userId = null, fromDate = null, toDate = null) {
    let response = await httpCustom(
      `/classification/log/download/xlsx?languageId=${languageId}&code=${code}&wr=${wr}&state=${state}&userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
      }
    );
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  return Object.freeze({
    getLogsAsync,
    getLogsRangeAsync,
    getNewCodeAsync,
    saveClassificationAsync,
    saveClassificationMoveAsync,
    removeClassificationAsync,
    undoLogAsync,
    downloadLogsAsXlsxAsync,
  });
})();
