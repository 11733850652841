<template>
  <div>
    <panel barColor="blue" class="mb-3 col-12">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <label for="excelFile" class="form-label">Upload/Download Schema Modification template sheet</label>
        <button @click="close" class="btn btn-light float-end">
          <font-awesome icon="times" class="button-icon close-modification-btn"></font-awesome>
        </button>
      </div>
      <div v-if="!isLoading" class="d-flex justify-content-between align-items-center mb-2">
        <div class="input-group mb-1 w-50 needs-validation" :class="{ 'was-validated': toImport.data }">
          <input
            class="form-control"
            type="file"
            id="excelFile"
            @change="selectFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <div class="valid-tooltip">
            File is selected!
          </div>
          <button
            :class="{ 'btn-success': toImport.data, 'btn-outline-success': !toImport.data }"
            class="btn "
            type="button"
            @click="clickUpload"
            title="Upload Translation sheet as .xlsx"
          >
            Upload Template
          </button>
        </div>
        <button class="btn btn-outline-success" type="button" @click="clickDownload" title="Download Translation sheet as .xlsx">
          Download Template
        </button>
      </div>
      <spinner v-if="isLoading" :text="spinnerText" class="d-flex justify-content-center"></spinner>
      <alert v-bind="bindableAlert" class="mt-3 mb-3"></alert>
    </panel>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import useSchema from "../../composables/schema/useSchema";
import useAlert from "../../composables/shared/useAlert";
import useUserSingleton from "../../composables/shared/useUserSingleton";
import { downloadXlsx } from "../../utils/fileUtil";
import Alert from "../shared/Alert.vue";
import Panel from "../shared/Panel.vue";
import Spinner from "../shared/Spinner.vue";

export default {
  components: { Alert, Spinner, Panel },
  emits: ["onSuccess", "onClose"],
  setup(props, { emit }) {
    const { user } = useUserSingleton();
    const alert = useAlert();
    const { downloadSchemaModificationAsync, uploadSchemaModificationAsync } = useSchema();

    const spinnerText = ref("Loading");

    const isCollapsed = ref(true);
    const isLoading = ref(false);

    const toImport = reactive({
      languageId: user.value.language.languageId,
      languageCode: user.value.language.languageCode,
      name: "",
      data: null,
      formData: null,
    });

    function close() {
      isCollapsed.value = true;
      toImport.formData = null;
      toImport.name = null;
      toImport.data = null;
      emit("onClose");
    }

    function selectFile(event) {
      let file = event.target.files[0];

      if (file == null) {
        return;
      }

      toImport.formData = new FormData();
      toImport.formData.append("File", file);
      toImport.name = file.name;
      toImport.data = file;
    }

    async function clickUpload() {
      if (toImport.data == null) {
        alert.warning(["Please Choose File."]);
        return;
      }
      if (isLoading.value) {
        return;
      }

      isLoading.value = true;
      spinnerText.value = "Uploading...";

      const response = await uploadSchemaModificationAsync(toImport);

      isLoading.value = false;
      toImport.formData = null;
      toImport.name = null;
      toImport.data = null;

      if (!response.isSuccess) {
        alert.error([response.message]);
        return;
      }
      alert.success(["Uploaded successfully!"]);
      emit("onSuccess");
    }

    async function clickDownload() {
      if (isLoading.value) {
        return;
      }
      isLoading.value = true;
      spinnerText.value = "Downloading...";

      let data = await downloadSchemaModificationAsync();
      downloadXlsx(data.name, data.file);
      isLoading.value = false;
      alert.success(["Downloaded successfully!"]);
    }
    return {
      props,
      isLoading,
      isCollapsed,
      clickUpload,
      clickDownload,
      selectFile,
      spinnerText,
      bindableAlert: alert.bindableAlert,
      toImport,
      close,
    };
  },
};
</script>
<style scoped>
.open-modification-btn {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.close-modification-btn {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
</style>
