<template>
  <schema-relation-result v-show="state.showRelationResult" @onSave="handleSaveRelation" :bricks="bricks"></schema-relation-result>
  <schema-classification
    v-if="state.showClassification"
    @onSave="handleSaveClassification"
    @onClose="handleCloseClassification"
    :item="itemToAddOrUpdate"
  ></schema-classification>
  <panel v-show="state.showRelation" barColor="blue" class="relation-panel">
    <div class="row mb-3">
      <div class="col-2 col-sm-2 col-md-4 d-flex align-items-start">
        <div class="button-icon" @click="closeRelation">
          <font-awesome icon="arrow-circle-left"></font-awesome>
        </div>
      </div>
      <div class="col-3 col-sm-4 align-self-center panel-title">Relations</div>
      <div class="col-1 d-none d-sm-none d-md-none d-lg-none d-xl-block"></div>
    </div>
    <alert v-bind="bindableAlert" class="mb-3" />
    <div class="row text-start">
      <div class="col-12">
        <div class="accordion " id="HierarchyAccordion">
          <div class="accordion-item" v-for="brick in bricks" :key="brick.code" :title="brick.title">
            <div class="accordion-header" :id="brick.code + '_header'">
              <button
                class="accordion-button"
                :class="{collapsed:!isBrickSelected(brick.code)}"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#' + 'brick_' + brick.code"
                aria-expanded="false"
                :aria-controls="'brick_' + brick.code"
                @click.prevent="selectBrick(brick)"
              >
                <span>(Brick)</span>
                <span class="fw-bold mx-1">{{ brick.code }}</span>
                <span>{{ brick.title }}</span>
              </button>
            </div>
            <div
              :id="'brick_' + brick.code"
              class="accordion-collapse collapse"
              :class="{show:isBrickSelected(brick.code)}"
              :aria-labelledby="brick.code + '_header'"
              data-bs-parent="#HierarchyAccordion"
            >
              <div class="accordion-body">
                <div class="row">
                  <div v-show="userSelection.brickCode != null" class="d-flex justify-content-end my-2">
                    <button
                      @click="brick.showOrHideRelationAttributeTable = !brick.showOrHideRelationAttributeTable"
                      class="btn btn-sm btn-primary me-2"
                      title="List of Attributes to link"
                    >
                      Attributes
                    </button>
                    <button @click="addNewAttribute" class="btn btn-sm btn-success" title="Add new Attribute">New Attribute</button>
                  </div>
                </div>
                <div class="row">
                  <div :class="{ col: !brick.showOrHideRelationAttributeTable, 'col-6': brick.showOrHideRelationAttributeTable }">
                    <div class="accordion" :id="'accordion_' + brick.code">
                      <div class="accordion-item" v-for="attribute in brick.childs" :key="attribute.code" :title="attribute.title">
                        <div class="accordion-header" :id="attribute.code + '_header'">
                          <button
                            class="accordion-button"
                            :class="{ collapsed:!isAttributeSelected(attribute.code), 'accordion-btn-danger': attribute.isRemoved, 'accordion-btn-success': attribute.isAdded }"
                            type="button"
                            @click.prevent="selectAttribute(attribute)"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#' + 'attr_' + attribute.code"
                            aria-expanded="false"
                            :aria-controls="'attr_' + attribute.code"
                          >
                            <span>(Attribute)</span>
                            <span class="fw-bold mx-1">{{ attribute.code }}</span>
                            <span>{{ attribute.title }}</span>
                          </button>
                        </div>
                        <div
                          :id="'attr_' + attribute.code"
                          class="accordion-collapse collapse"
                          :class="{show:isAttributeSelected(attribute.code)}"
                          :aria-labelledby="attribute.code + '_header'"
                          :data-bs-parent="'#accordion_' + brick.code"
                        >
                          <div class="accordion-body">
                            <div class="row">
                              <div v-show="userSelection.attributeCode != null" class="d-flex justify-content-end my-2">
                                <button
                                  v-show="!attribute.isRemoved"
                                  @click="removeRelation(attribute)"
                                  title="Remove relation"
                                  class="btn btn-sm btn-danger me-2"
                                >
                                  Remove
                                </button>
                                <button
                                  v-show="attribute.isRemoved"
                                  @click="restoreRelation(attribute)"
                                  title="Restore relation"
                                  class="btn btn-sm btn-warning me-2"
                                >
                                  Restore
                                </button>
                                <button
                                  @click="attribute.showOrHideRelationkAttributeValueTable = !attribute.showOrHideRelationkAttributeValueTable"
                                  class="btn btn-sm btn-primary me-2"
                                  title="List of Attributes Values to link"
                                >
                                  Values
                                </button>
                                <button @click="addNewAttributeValue" class="btn btn-sm btn-success" title="Add new Attribute Value">
                                  New Value
                                </button>
                              </div>
                            </div>
                            <div class="row">
                              <div
                                :class="{
                                  col: !attribute.showOrHideRelationkAttributeValueTable,
                                  'col-6': attribute.showOrHideRelationkAttributeValueTable,
                                }"
                              >
                                <ul class="list-group list-group-flush">
                                  <li
                                    v-for="attributeValue in attribute.childs"
                                    :key="attributeValue.code"
                                    class="list-group-item list-group-item-action p-2"
                                    :class="{ 'list-group-item-danger': attributeValue.isRemoved, 'list-group-item-success': attributeValue.isAdded }"
                                    :title="attributeValue.title"
                                  >
                                    <div class="d-flex justify-content-between align-items-center">
                                      <div class="overflow-auto">
                                        <span>(Value)</span>
                                        <span class="fw-bold mx-1">{{ attributeValue.code }}</span>
                                        <span>{{ attributeValue.title }}</span>
                                      </div>
                                      <div class="d-flex">
                                        <div class="d-flex button-icon small">
                                          <button
                                            v-show="!attributeValue.isRemoved"
                                            @click="removeRelation(attributeValue)"
                                            title="Remove relation"
                                            class="btn btn-sm btn-danger"
                                          >
                                            Remove
                                          </button>
                                          <button
                                            v-show="attributeValue.isRemoved"
                                            @click="restoreRelation(attributeValue)"
                                            title="Restore relation"
                                            class="btn btn-sm btn-warning"
                                          >
                                            Restore
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <schema-relation-table
                                v-if="!!attribute.showOrHideRelationkAttributeValueTable"
                                :items="attribute.childs"
                                :itemsInitSearch="getRelatedAttributeValues(attribute)"
                                :itemsToSearchIn="allAttributeValues"
                                :title="'Values'"
                                :class="{ 'col-6': attribute.showOrHideRelationkAttributeValueTable }"
                                @onAdd="handleAddItem"
                              ></schema-relation-table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <schema-relation-table
                    v-if="!!brick.showOrHideRelationAttributeTable"
                    :items="brick.childs"
                    :itemsToSearchIn="allAttributes"
                    :title="'Attributes'"
                    :class="{ 'col-6': brick.showOrHideRelationAttributeTable }"
                    @onInspect="handleInspectAttribute"
                    @onAdd="handleAddItem"
                  ></schema-relation-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import Panel from "../shared/Panel.vue";
import SchemaRelationTable from "../schema/SchemaRelationTable";
import SchemaRelationResult from "../schema/SchemaRelationResult";
import SchemaClassification from "../schema/SchemaClassification";
import useSchemaRelation from "../../composables/schema/useSchemaRelation";
import { reactive, ref } from "@vue/reactivity";
import useSchema from "../../composables/schema/useSchema";
import gpcEnumRepository from "../../repositories/gpcEnumRepository";
import useUserSingleton from "../../composables/shared/useUserSingleton";
import Alert from "../shared/Alert.vue";
import useAlert from "../../composables/shared/useAlert";
import { onMounted, watchEffect } from "@vue/runtime-core";
import useUnsavedChanges from "../../composables/shared/useUnsavedChanges";

export default {
  components: { Panel, SchemaRelationTable, SchemaRelationResult, SchemaClassification, Alert },
  emits: ["onSave", "onClose"],
  props: {
    items: {
      type: Array,
      required: true,
    },
    classifications: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const alert = useAlert();
    const { languageId } = useUserSingleton();
    const { acceptUnsavedChanges } = useUnsavedChanges();
    const {
      isSaving,
      bricks,
      attributes,
      attributeValues,
      selectBrick,
      selectAttribute,
      addRelation,
      removeRelation,
      restoreRelation,
      isSelectedBrick,
      isSelectedAttribute,
      userSelection,
      init,
      saveAsync,
      isDirty,
    } = useSchemaRelation();
    const { getClassificationsByLevel, getAttributeValuesAsync, getAttributesAsync, isGettingAttributes, isGettingAttributeValues } = useSchema();

    const state = reactive({
      showRelation: true,
      showClassification: false,
      showRelationResult: true,
    });

    const itemToAddOrUpdate = ref({});

    const allAttributes = ref([]);
    const allAttributeValues = ref([]);
    const schemaAttributes = ref([]);

    const relationTableState = reactive({
      isAttributeTableVisible: false,
      isAttributeValueTableVisible: false,
    });

    onMounted(async () => {
      allAttributes.value = await getAttributesAsync(languageId.value);
      allAttributeValues.value = await getAttributeValuesAsync(languageId.value);
      schemaAttributes.value = getClassificationsByLevel(props.classifications, gpcEnumRepository.ClassificationLevel.Attribute);
    });

    watchEffect(() => {
      init(props.items, props.classifications);
      selectBrick(null);
    });

    async function handleSaveRelation(workRequest, comment) {
      const response = await saveAsync(languageId.value, workRequest, comment);
      if (!response.isSuccess) {
        alert.error([response.message]);
      } else {
        alert.success(["Saved successfully!"]);
        emit("onSave", bricks);
      }
      relationTableState.isAttributeTableVisible = false;
      relationTableState.isAttributeValueTableVisible = false;
    }

    function handleSaveClassification(item) {
      if (item.level == gpcEnumRepository.ClassificationLevel.Attribute) {
        allAttributes.value = getClassificationsByLevel(props.classifications, gpcEnumRepository.ClassificationLevel.Attribute);
        addRelation(item);
        selectAttribute(item);
      }
      if (item.level == gpcEnumRepository.ClassificationLevel.AttributeValue) {
        allAttributeValues.value = getClassificationsByLevel(props.classifications, gpcEnumRepository.ClassificationLevel.AttributeValue);
        addRelation(item);
      }
    }

    function handleCloseClassification() {
      state.showRelation = true;
      state.showRelationResult = true;
      state.showClassification = false;
    }

    function handleInspectAttribute(item) {
      allAttributeValues.value = item.childs;
      relationTableState.isAttributeValueTableVisible = true;
    }
    function handleAddItem(item) {
      addRelation(item);
    }

    function closeRelation() {
      if (isDirty()) {
        if (acceptUnsavedChanges()) {
          emit("onClose");
        }
      } else {
        emit("onClose");
      }
    }

    function addNewAttribute() {
      itemToAddOrUpdate.value = {
        level: gpcEnumRepository.ClassificationLevel.Attribute,
        languageId: languageId.value,
        parentCode: userSelection.brickCode,
        isNew: true,
      };
      state.showClassification = true;
      state.showRelation = false;
      state.showRelationResult = false;
    }

    function addNewAttributeValue() {
      itemToAddOrUpdate.value = {
        level: gpcEnumRepository.ClassificationLevel.AttributeValue,
        languageId: languageId.value,
        parentCode: userSelection.attributeCode,
        isNew: true,
      };
      state.showClassification = true;
      state.showRelation = false;
      state.showRelationResult = false;
    }

    function getRelatedAttributeValues(attribute) {
      let values = {};
      schemaAttributes.value
        .filter((f) => f.code == attribute.code)
        .forEach((m) => {
          m.childs.forEach((attrV) => (values[attrV.code] = attrV));
        });
      return Object.values(values);
    }

    function isBrickSelected(code) {
      return userSelection.brickCode == code;
    }

    function isAttributeSelected(code) {
      return userSelection.attributeCode == code;
    }

    return {
      isBrickSelected,
      isAttributeSelected,
      state,
      isSaving,
      closeRelation,
      bricks,
      attributes,
      attributeValues,
      selectBrick,
      selectAttribute,
      removeRelation,
      restoreRelation,
      isSelectedBrick,
      isSelectedAttribute,
      relationTableState,
      allAttributes,
      allAttributeValues,
      handleInspectAttribute,
      handleAddItem,
      userSelection,
      addNewAttribute,
      addNewAttributeValue,
      handleSaveRelation,
      handleSaveClassification,
      handleCloseClassification,
      itemToAddOrUpdate,
      bindableAlert: alert.bindableAlert,
      isGettingAttributes,
      isGettingAttributeValues,
      getRelatedAttributeValues,
    };
  },
};
</script>

<style lang="scss">
.accordion-body {
  padding-top: 0;
}
.accordion-btn-danger {
  background-color: #dfc2c4 !important;
}
.accordion-btn-success {
  background-color: #bcd0c7 !important;
}
.relation-panel {
  & .button-icon {
    width: auto;
    height: auto;
    &.small {
      & svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
.list-group-item-action {
  cursor: pointer;

  & .button-icon {
    opacity: 0;
    width: auto;
    height: auto;

    &.small {
      & svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
  &:hover {
    & .button-icon {
      opacity: 1;
    }
  }
}
</style>
