<template>
  <header>
    <div class="header-top">
      <div class="container">
        <div class="d-flex p-2 align-items-center">
          <div class="col-8">
            <div class="logo d-flex align-items-center">
              <a href="/" title="Home">
                <img width="72" height="59" border="0" alt="GS1 logo" src="../assets/logo.png" />
              </a>
              <div class="text-start d-none d-md-block ms-3">
                <a class="" href="/" title="Home">
                  <span class="main-title">Welcome to GS1</span>
                </a>
                <br />The Global Language of Business
              </div>
            </div>
          </div>
          <div class="col-2">
            <h3 class="fw-bold" v-if="environment != null">{{environment}}</h3>
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div v-show="userLanguages.length > 0" class="dropdown  me-2">
              <button class="btn btn-outline-secondary gs1-dropdown dropdown-toggle" type="button" id="LanguageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                {{ selectedLanguageName }}
              </button>
              <ul class="dropdown-menu gs1-dropdown-menu" aria-labelledby="LanguageDropdown">
                <li v-for="language in userLanguages" :key="language.languageId" @click="selectedLanguageId = language.languageId">
                  <a class="dropdown-item" href="#">{{ language.languageName }}</a>
                </li>
              </ul>
            </div>
            <the-login></the-login>
          </div>
        </div>
      </div>
    </div>
    <div class="navigation-menu">
      <div class="container">
        <nav class="navbar navbar-expand p-0" :style="{ visibility: navbarVisibility }">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbars" aria-controls="navbars" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbars">
            <ul class="navbar-nav me-auto mb-2 mb-sm-0">
              <li class="nav-item">
                <router-link :to="{ name: 'Home' }" class="nav-link" aria-current="page">Home</router-link>
              </li>
              <li v-if="isAdmin" class="nav-item">
                <router-link :to="{ name: 'User' }" class="nav-link">User</router-link>
              </li>
              <li v-if="isAdmin" class="nav-item">
                <router-link :to="{ name: 'Language' }" class="nav-link">Language</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'History' }" class="nav-link">History</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Schema' }" class="nav-link">Schema</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Translation' }" class="nav-link">Translation</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Publication' }" class="nav-link">Publication</router-link>
              </li>
              <li v-if="isAdmin" class="nav-item">
                <router-link :to="{ name: 'Blob' }" class="nav-link">Publication Files</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Report' }" class="nav-link">Report</router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { computed, ref, watch } from "vue";
import useUser from "../composables/shared/useUserSingleton";
import TheLogin from "./TheLogin.vue";
import appSettings from "../configuration/appSettings";

export default {
  components: { TheLogin },
  setup() {
    const { user, isAdmin, isAutheticated, changeUserLanguage } = useUser();

    const selectedLanguageId = ref(0);
    const userLanguages = computed(() => user.value.availableLanguages);
    const navbarVisibility = computed(() => (isAutheticated.value ? "visible" : "hidden"));
    const selectedLanguageName = computed(() => user.value.language.languageName);
    const environment = ["Development", "Staging"].includes(appSettings.ENVIRONMENT_NAME) ? appSettings.ENVIRONMENT_NAME : null;

    watch(selectedLanguageId, (langId) => {
      changeUserLanguage(langId);
    });

    watch(isAutheticated, (value) => {
      if (value) {
        selectedLanguageId.value = user.value.language.languageId ?? 0;
      }
    });

    return {
      isAdmin,
      selectedLanguageId,
      userLanguages,
      navbarVisibility,
      selectedLanguageName,
      environment,
    };
  },
};
</script>

<style lang="scss" scoped>
.header-top {
  background-color: $gs1-color-gray-light;

  span.main-title {
    font-weight: 500;
  }
}
.navigation-menu {
  background-color: $gs1-color-white;
  -webkit-box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.15);

  .navbar {
    .nav-item {
      a.nav-link {
        padding: 10px;
        font-weight: 500;
        color: $gs1-color-blue;
        background-color: transparent;
        border-top: 0.25rem solid transparent;
        text-decoration: none;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

        &.router-link-active {
          color: $gs1-color-orange;
          border-top-color: $gs1-color-orange !important;
        }

        &:hover {
          border-top-color: $gs1-color-blue;
        }
      }
    }
  }
}

button,
li {
  font-family: "Gotham SSm A", "Gotham SSm B", Verdana, sans-serif !important;
  font-variant-numeric: tabular-nums !important;
  font-size: 1rem;
}
.gs1-dropdown {
  color: var(--gs1-color-ui-8);
  border: 1px solid var(--gs1-color-ui-3);
}
.gs1-dropdown:hover {
  cursor: pointer;
  color: #002c6c;
  background-color: #e5f0fc;
}
.gs1-dropdown:focus {
  box-shadow: none !important;
  color: inherit;
  background-color: inherit;
  border-color: #c4d7ed;
}
.gs1-dropdown:active {
  color: inherit;
  background-color: inherit;
  border-color: #c4d7ed;
}
.gs1-dropdown.show {
  box-shadow: none;
  color: inherit;
  background-color: inherit;
  border-color: #c4d7ed;
}
.gs1-dropdown-menu {
  border: 1px solid #c4d7ed;
}
.gs1-dropdown-menu .dropdown-item {
  color: #262626;
}
.gs1-dropdown-menu .dropdown-item:hover {
  color: #002c6c;
  background-color: #e5f0fc;
}
.gs1-dropdown-menu .dropdown-item:active {
  color: #262626;
  border: 1px solid #002c6c;
  background-color: inherit;
}
</style>
