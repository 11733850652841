import { computed, nextTick, reactive, ref } from "vue";
import gpcSchemaRepository from "../../repositories/gpcSchemaRepository";
import gpcEnumRepository from "../../repositories/gpcEnumRepository";

export default function useSchema() {
  const isFetchingSchema = ref(false);
  const isFetchingSecondPartOfSchema = ref(false);
  const isGettingAttributes = ref(false);
  const isGettingAttributeValues = ref(false);

  const classifications = ref([]);
  const currentLevel = reactive({
    parent: null,
    level: 0,
    title: "",
    items: [],
  });

  async function fetchSchemaAsync(languageId) {
    if (classifications.value.length > 0 && classifications.value[0].languageId == languageId) {
      setCurrentLevel(null);
      return;
    }
    isFetchingSchema.value = true;
    let segmentResponse = await gpcSchemaRepository.getSegmentFamilyClassAsync(languageId);

    classifications.value = segmentResponse.result;
    setCurrentLevel(null);
    isFetchingSchema.value = false;
    await nextTick();

    await fetchSchemaSecondPartAsync(languageId);

    return segmentResponse;
  }

  async function fetchSchemaSecondPartAsync(languageId) {
    isFetchingSecondPartOfSchema.value = true;
    let brickResponse = await gpcSchemaRepository.getBricksAsync(languageId);
    let attrResponse = await gpcSchemaRepository.getAttributesWithValuesAsync(languageId);

    brickResponse.result.forEach((brick) => {
      let relatedAttrs = attrResponse.result.filter((f) => f.parentCode == brick.code);
      if (relatedAttrs.length > 0) {
        relatedAttrs.forEach((rAttr) => brick.childs.push(rAttr));
      }
    });

    let brickDictionary = brickResponse.result.reduce((prev, curr) => {
      if (prev[curr.parentCode] == null) {
        prev[curr.parentCode] = [curr];
      } else {
        prev[curr.parentCode].push(curr);
      }
      return prev;
    }, {});

    classifications.value.forEach((segment) =>
      segment.childs.forEach((family) =>
        family.childs.forEach((clas) => {
          if (brickDictionary[clas.code] != null) {
            clas.childs.push(...brickDictionary[clas.code]);
          }
        })
      )
    );
    isFetchingSecondPartOfSchema.value = false;
  }

  async function getAttributesAsync(languageId) {
    isGettingAttributes.value = true;
    const response = await gpcSchemaRepository.getAttributesAsync(languageId);
    isGettingAttributes.value = false;
    return response.result;
  }

  async function getAttributeValuesAsync(languageId) {
    isGettingAttributeValues.value = true;
    const response = await gpcSchemaRepository.getAttributeValuesAsync(languageId);
    isGettingAttributeValues.value = false;
    return response.result;
  }

  function setCurrentLevel(parent) {
    if (parent == null) {
      currentLevel.parent = null;
      currentLevel.level = gpcEnumRepository.ClassificationLevel.Segment;
      currentLevel.title = Object.keys(gpcEnumRepository.ClassificationLevel).find(
        (key) => gpcEnumRepository.ClassificationLevel[key] == gpcEnumRepository.ClassificationLevel.Segment
      );
      currentLevel.items = classifications.value;
      return;
    }
    currentLevel.parent = parent;
    currentLevel.level = parent.level + 1;
    currentLevel.title = Object.keys(gpcEnumRepository.ClassificationLevel).find(
      (key) => gpcEnumRepository.ClassificationLevel[key] == currentLevel.level
    );
    currentLevel.items = parent.childs;
  }

  function goToLevel(parent) {
    if (parent == null) {
      setCurrentLevel(null);
      return;
    }
    if (parent.level == gpcEnumRepository.ClassificationLevel.AttributeValue) {
      return;
    }
    setCurrentLevel(parent);
  }

  function goBackToLevel(item) {
    setCurrentLevel(item);
  }

  function upsertClassificationToSchema(itemToUpsert) {
    if (
      itemToUpsert.level == gpcEnumRepository.ClassificationLevel.Attribute ||
      itemToUpsert.level == gpcEnumRepository.ClassificationLevel.AttributeValue
    ) {
      return;
    }
    if (itemToUpsert.parentCode == null) {
      let foundSegment = classifications.value.find((f) => f.code == itemToUpsert.code);
      if (foundSegment != null) {
        foundSegment.title = itemToUpsert.title;
        foundSegment.definition = itemToUpsert.definition;
        foundSegment.definitionExcludes = itemToUpsert.definitionExcludes;
      } else {
        classifications.value.splice(0, 0, itemToUpsert);
      }
      return;
    }
    updateRecursive(classifications.value, itemToUpsert);
  }

  function updateRecursive(list, item) {
    if (list == null) {
      return;
    }

    list.forEach((classification) => {
      if (classification.code == item.parentCode) {
        let foundItem = classification.childs.find((f) => f.code == item.code);
        if (foundItem != null) {
          foundItem.title = item.title;
          foundItem.definition = item.definition;
          foundItem.definitionExcludes = item.definitionExcludes;
        } else {
          classification.childs.splice(0, 0, item);
        }
        return;
      }
      updateRecursive(classification.childs, item);
    });
  }

  function getClassificationsByLevel(items, level) {
    const resultArray = [];
    fetchItemsByLevelFn(items, level, resultArray);
    return resultArray;
  }

  function fetchItemsByLevelFn(tree, level, resultArray) {
    if (tree == null) {
      return;
    }
    tree.forEach((item) => {
      if (item.level == level) {
        resultArray.push(item);
      }
      fetchItemsByLevelFn(item.childs, level, resultArray);
    });
  }

  async function uploadSchemaModificationAsync(toImport) {
    return gpcSchemaRepository.uploadSchemaModificationAsync(toImport);
  }

  async function downloadSchemaModificationAsync(languageId) {
    return gpcSchemaRepository.downloadSchemaModificationAsync(languageId);
  }

  return {
    currentLevel,
    isFetchingSchema: computed(() => isFetchingSchema.value),
    isFetchingSecondPartOfSchema: computed(() => isFetchingSecondPartOfSchema.value),
    isGettingAttributes: computed(() => isGettingAttributes.value),
    isGettingAttributeValues: computed(() => isGettingAttributeValues.value),
    classifications: computed(() => classifications.value),
    isGoBackAvailable: computed(() => currentLevel.level != gpcEnumRepository.ClassificationLevel.Segment),
    fetchSchemaAsync,
    getAttributesAsync,
    getAttributeValuesAsync,
    goToLevel,
    goBackToLevel,
    upsertClassificationToSchema,
    getClassificationsByLevel,
    uploadSchemaModificationAsync,
    downloadSchemaModificationAsync
  };
}
