<template>
  <div class="row">
    <loader v-if="isLoadingLanguagePublicationReport"></loader>
    <panel barColor="sky">
      <div class="row align-items-center">
        <div class="col-4 d-flex align-items-start p-1"></div>
        <div class="col-4 align-self-center panel-title p-1">{{ mainTitle }}</div>
        <div class="col-4 d-flex justify-content-end"></div>
        <div class="col-12 text-start mt-2">
          <alert v-bind="bindableAlert" class="mt-3 mb-3"></alert>
          <div class="gpc-table table-responsive">
            <table class="table table-hover table-sm" aria-label="Publication by Langauge table">
              <thead>
                <tr>
                  <th scope="col" class="fw-bold">Language Name</th>
                  <th scope="col" class=" fw-bold">User Name</th>
                  <th scope="col" class=" fw-bold">User Email</th>
                  <th scope="col" class="fw-bold">Publication Name</th>
                  <th scope="col" class="fw-bold">Publication Date</th>
                  <th scope="col" class=" fw-bold">Insert Date</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in reportItems"
                  :key="item.countryCode"
                  class="classification-row"
                  :class="{ 'table-danger': item.publicationDate == null, 'table-success': item.isUpToDate, 'table-warning': !item.isUpToDate }"
                >
                  <td>{{ item.languageName }}</td>
                  <td class="text-nowrap">{{ item.userName }}</td>
                  <td class="text-nowrap">{{ item.email }}</td>
                  <td class="text-nowrap">{{ item.publicationName }}</td>
                  <td>{{ item.publicationDate }}</td>
                  <td>{{ item.insertPublicationRelativeDate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useLanguageSingleton from "../../composables/language/useLanguageSingleton";
import { onMounted } from "@vue/runtime-core";
import Loader from "../shared/Loader.vue";
import Panel from "../shared/Panel.vue";
import Alert from "../shared/Alert.vue";
import useAlert from "../../composables/shared/useAlert";
export default {
  components: { Loader, Panel, Alert },
  setup() {
    const alert = useAlert();
    const { isLoadingLanguagePublicationReport, getLanguagePublicationReportAsync } = useLanguageSingleton();

    const reportItems = ref([]);
    const mainTitle = ref(`Publication status per language`);

    onMounted(async () => {
      let response = await getLanguagePublicationReportAsync();
      if (!response.isSuccess) {
        alert.error([response.message]);
      } else {
        reportItems.value = response.result.map((item) => {
          item.publicationDate = item.publicationDate == null ? null : new Date(item.publicationDate).toLocaleString("en-US", { timeZone: "UTC" });
          return item;
        });
      }
    });

    return { mainTitle, isLoadingLanguagePublicationReport, getLanguagePublicationReportAsync, reportItems, bindableAlert: alert.bindableAlert };
  },
};
</script>
