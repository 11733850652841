import appSettings from "../configuration/appSettings";
import authService from "./authService";

export async function httpGet(path, useJwt = true) {
  let response = await fetch(`${appSettings.API_URL}${path}`, getConfig("get", null, useJwt)).then(handleErrors);
  return response.ok ? response.json() : response;
}

export async function httpPost(path, body, useJwt = true) {
  let response = await fetch(`${appSettings.API_URL}${path}`, getConfig("post", body, useJwt)).then(handleErrors);
  return response.ok ? response.json() : response;
}

export async function httpPut(path, body, useJwt = true) {
  let response = await fetch(`${appSettings.API_URL}${path}`, getConfig("put", body, useJwt)).then(handleErrors);
  return response.ok ? response.json() : response;
}

export async function httpDelete(path, useJwt = true) {
  let response = await fetch(`${appSettings.API_URL}${path}`, getConfig("delete", null, useJwt)).then(handleErrors);
  return response.ok ? response.json() : response;
}

export async function httpCustom(path, config, useJwt = true) {
  if (useJwt) {
    config.headers.Authorization = authService.getAuthToken();
  }
  return fetch(`${appSettings.API_URL}${path}`, config);
}

export function getFileName(response) {
  if (response != null && response.headers != null) {
    const header = response.headers.get("Content-Disposition");
    if (header) {
      return header.match(/filename=(.+);/)[1].replace(/(^")|("$)/g, '');
    }
  }
  return "File";
}

async function handleErrors(response) {
  if (!response.ok) {
    let msg = "Unknown error";
    if (response.status == 401) {
      msg = "Unauthorized. Token is not valid.";
    }
    if (response.status == 429) {
      let json =  await response.json();
      msg = json.message;
    }
    return { isSuccess: false, message: msg };
  }
  return response;
}

function getConfig(method, body = "", useJwt = true) {
  let config = {
    headers: { "Content-Type": "application/json;charset=utf-8" },
  };
  if (useJwt) {
    config.headers.Authorization = authService.getAuthToken();
  }
  if (method === "get") {
    config.method = method.toUpperCase();
  }
  if (method === "post") {
    config.method = method.toUpperCase();
    config.body = JSON.stringify(body);
  }
  if (method === "put") {
    config.method = method.toUpperCase();
    config.body = JSON.stringify(body);
  }
  if (method === "delete") {
    config.method = method.toUpperCase();
  }

  return config;
}
