import { computed, ref } from "vue";
import appSettings from "../../configuration/appSettings";
import gpcLanguageRepository from "../../repositories/gpcLanguageRepository";

const isLoadingLangueges = ref(false);
const isLoadedLangueges = ref(false);
const languages = ref([]);
const appLanguage = ref({});

export default function useLanguageSingleton() {
  const isSavingLanguage = ref(false);
  const isLoadingLanguagePublicationReport = ref(false);

  async function fetchLanguages(useCache = true) {
    if (useCache && languages.value.length > 0) {
      return {
        isSuccess: true,
        result: languages.value,
      };
    }

    isLoadedLangueges.value = false;
    isLoadingLangueges.value = true;
    const response = await gpcLanguageRepository.getLanguages();
    languages.value = response.result;
    appLanguage.value = languages.value.find((f) => f.languageCode == appSettings.MAIN_LANGUAGE_CODE);
    isLoadingLangueges.value = false;
    isLoadedLangueges.value = true;
    return response;
  }

  async function addLanguageAsync(language) {
    isSavingLanguage.value = true;
    const response = await gpcLanguageRepository.addLanguageAsync(language);
    isSavingLanguage.value = false;
    return response;
  }

  async function updateLanguageAsync(language) {
    isSavingLanguage.value = true;
    const response = await gpcLanguageRepository.updateLanguageAsync(language);
    isSavingLanguage.value = false;
    return response;
  }

  function getLanguageCode(languageId) {
    const matchedLanguage = languages.value.find((f) => f.languageId == languageId);
    return matchedLanguage != null ? matchedLanguage.languageCode : "";
  }

  async function getLanguagePublicationReportAsync() {
    isLoadingLanguagePublicationReport.value = true;
    const response = await gpcLanguageRepository.getLanguagePublicationReportAsync();
    isLoadingLanguagePublicationReport.value = false;
    return response;
  }

  return {
    isLoadedLangueges: computed(() => isLoadedLangueges.value),
    isLoadingLangueges: computed(() => isLoadingLangueges.value),
    isSavingLanguage: computed(() => isSavingLanguage.value),
    isLoadingLanguagePublicationReport: computed(() => isLoadingLanguagePublicationReport.value),
    languages: computed(() => languages.value),
    appLanguage: computed(() => appLanguage.value),
    fetchLanguages,
    getLanguageCode,
    addLanguageAsync,
    updateLanguageAsync,
    getLanguagePublicationReportAsync,
  };
}
