import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Schema from "@/views/Schema.vue";
import Translation from "@/views/Translation.vue";
import Publication from "@/views/Publication.vue";
import Language from "@/views/Language.vue";
import History from "@/views/History.vue";
import Report from "@/views/Report.vue";
import User from "@/views/User.vue";
import Blob from "@/views/Blob.vue";
import authService from "../services/authService";
import useToast from "../composables/shared/useToast";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/User",
    name: "User",
    component: User,
    meta: { requiresAuth: true },
  },
  {
    path: "/Language",
    name: "Language",
    component: Language,
    meta: { requiresAuth: true },
  },
  {
    path: "/History",
    name: "History",
    component: History,
    meta: { requiresAuth: true },
  },
  {
    path: "/Schema",
    name: "Schema",
    component: Schema,
    meta: { requiresAuth: true },
  },
  {
    path: "/Translation",
    name: "Translation",
    component: Translation,
    meta: { requiresAuth: true },
  },
  {
    path: "/Publication",
    name: "Publication",
    component: Publication,
    meta: { requiresAuth: true },
  },
  {
    path: "/Blobs",
    name: "Blob",
    component: Blob,
    meta: { requiresAuth: true },
  },
  {
    path: "/Report",
    name: "Report",
    component: Report,
    meta: { requiresAuth: true },
  },
  // otherwise redirect to home
  { path:  "/:catchAll(.*)", redirect: "/" }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const { showToast } = useToast();

router.beforeEach((to, from, next) => {
  if (authService.isAuthenticated() && !authService.isSessionExpired()) {
    next();
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authService.isAuthenticated() || authService.isSessionExpired()) {
      showToast("You are not logged in", false);
    }
  } else {
    next();
  }
});

export default router;
