import { computed, ref } from "vue";
import appSettings from "../../configuration/appSettings";
import gpcUserRepository from "../../repositories/gpcUserRepository";

const userData = ref({
  userId: null,
  firstName: "",
  lastName: "",
  email: "",
  isAdmin: false,
  language: {},
  availableLanguages: [],
});

export default function useUserSingleton() {
  const GPC_ROLE_SUPER_ADMIN = "Super Admin";
  const GPC_ROLE_ADMIN = "Admin";
  const isGettingUsers = ref(false);

  function setupUser(authentificatedUser, languages) {
    if (authentificatedUser == null || languages.length == 0) {
      return;
    }

    userData.value.userId = authentificatedUser.userId;
    userData.value.firstName = authentificatedUser.firstName;
    userData.value.lastName = authentificatedUser.lastName;
    userData.value.email = authentificatedUser.email;

    userData.value.isAdmin = isAdminRole(authentificatedUser.gpcRole);

    if (userData.value.isAdmin) {
      userData.value.language = languages.find((lang) => lang.languageCode == appSettings.MAIN_LANGUAGE_CODE);
      userData.value.availableLanguages = languages;
    } else {
      userData.value.language = languages.find((lang) => authentificatedUser.gpcLanguage.find((f) => f.toLowerCase() === lang.countryCode.toLowerCase()) != null) ?? {};
      userData.value.availableLanguages = languages.filter((lang) => authentificatedUser.gpcLanguage.find((f) => f.toLowerCase() === lang.countryCode.toLowerCase()) != null);
    }
  }

  function clearUser() {
    userData.value.userId = null;
    userData.value.firstName = "";
    userData.value.lastName = "";
    userData.value.email = "";
    userData.value.isAdmin = false;
    userData.value.language = {};
    userData.value.availableLanguages = [];
  }

  function changeUserLanguage(languageId) {
    userData.value.language = userData.value.availableLanguages.find((f) => f.languageId == languageId);
  }

  function isAdminRole(gpcRole) {
    if (gpcRole == null) {
      return false;
    }
    if (gpcRole.toLowerCase() === GPC_ROLE_SUPER_ADMIN.toLowerCase()) {
      return true;
    }
    return gpcRole.toLowerCase() === GPC_ROLE_ADMIN.toLowerCase();
  }

  async function loginAsync() {
    return gpcUserRepository.loginAsync();
  }

  async function getUsersAsync() {
    isGettingUsers.value = true;
    const response = await gpcUserRepository.getUsersAsync();
    isGettingUsers.value = false;
    return response;
  }

  async function getUsersByLanguageAsync(languageId) {
    isGettingUsers.value = true;
    const response = await gpcUserRepository.getUsersByLanguageAsync(languageId);
    isGettingUsers.value = false;
    return response;
  }

  return {
    user: computed(() => userData.value),
    languageId: computed(() => userData.value.language.languageId),
    isAutheticated: computed(() => userData.value.userId != null),
    isAdmin: computed(() => userData.value.isAdmin),
    isMainLanguage: computed(() => userData.value.language.languageCode == appSettings.MAIN_LANGUAGE_CODE),
    isGettingUsers: computed(() => isGettingUsers.value),
    setupUser,
    clearUser,
    changeUserLanguage,
    loginAsync,
    getUsersAsync,
    getUsersByLanguageAsync,
  };
}
