<template>
  <panel barColor="blue">
    <div class="row align-items-center">
      <div class="col-4 d-flex align-items-start p-1">
        <div class="button-icon ms-2" @click="clickClose" title="Go back">
          <font-awesome icon="arrow-circle-left"></font-awesome>
        </div>
      </div>
      <div class="col-4 align-self-center panel-title p-1">{{ panelTitle }}</div>
      <div class="col-4 d-flex justify-content-end"></div>
      <div class="col-12">
        <div class="row text-start mt-3">
          <div class="col-3">
            <label for="LanguageName" class="form-label">Language name</label>
            <input
              v-model="languageToAddOrUpdate.languageName"
              type="text"
              class="form-control"
              id="LanguageName"
              placeholder="English"
              :class="{ 'is-invalid': languageValidation.isInvalidLanguageName }"
              required
            />
            <div class="invalid-feedback">{{ languageValidation.invalidLanguageNameMessage }}</div>
          </div>
          <div class="col-2">
            <label for="LanguageCode" class="form-label">Language code</label>
            <input
              v-model="languageToAddOrUpdate.languageCode"
              type="text"
              class="form-control"
              id="LanguageCode"
              placeholder="EN"
              :class="{ 'is-invalid': languageValidation.isInvalidLanguageCode }"
              required
            />
            <div class="invalid-feedback">{{ languageValidation.invalidLanguageCodeMessage }}</div>
          </div>
          <div class="col-2">
            <label for="CountryCode" class="form-label">Country code</label>
            <input
              v-model="languageToAddOrUpdate.countryCode"
              type="text"
              class="form-control"
              id="CountryCode"
              placeholder="US"
              :class="{ 'is-invalid': languageValidation.isInvalidCountryCode }"
              required
            />
            <div class="invalid-feedback">{{ languageValidation.invalidCountryCodeMessage }}</div>
          </div>
          <div class="col-2">
            <label for="Culture" class="form-label">Culture</label>
            <input v-model="languageToAddOrUpdate.culture" type="text" class="form-control" id="Culture" placeholder="en-US" :class="{ 'is-invalid': languageValidation.isInvalidCulture }" required />
            <div class="invalid-feedback">{{ languageValidation.invalidCultureMessage }}</div>
          </div>
          <div class="col-3">
            <label for="MOContactEmail" class="form-label">MO Contact email</label>
            <input
              v-model="languageToAddOrUpdate.moContactEmail"
              type="email"
              class="form-control"
              id="MOContactEmail"
              placeholder="user@gs1.org"
              :class="{ 'is-invalid': languageValidation.isInvalidMOContactEmail }"
            />
            <div class="invalid-feedback">{{ languageValidation.invalidMOContactEmailMessage }}</div>
          </div>
        </div>
        <div class="row text-start mt-3">
          <div class="col-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="languageToAddOrUpdate.includeAttributeAndValues" id="IncludeAttributeAndValues" />
              <label class="form-check-label" for="IncludeAttributeAndValues">Include Attribute/Value</label>
            </div>
          </div>
          <div class="col-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="languageToAddOrUpdate.freeToDownload" id="FreeToDownload" />
              <label class="form-check-label" for="FreeToDownload">Free to download</label>
            </div>
          </div>
          <div class="col-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="languageToAddOrUpdate.showInBrowser" id="ShowInBrowser" />
              <label class="form-check-label" for="ShowInBrowser">Show in Browser</label>
            </div>
          </div>
          <div class="col-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="languageToAddOrUpdate.usePublicationBlobs" id="UseStatic" />
              <label class="form-check-label" for="UseStatic">Use publication static files</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex  justify-content-end">
        <div class="button-icon" @click="clickSave" :class="{ disabled: languageValidation.isSaveDisabled }" title="Save/Update Language">
          <font-awesome v-if="!isSavingLanguage" icon="save"></font-awesome>
          <font-awesome v-if="isSavingLanguage" icon="spinner" class="fa-spinner"></font-awesome>
        </div>
      </div>
      <div class="col-12"><alert v-bind="bindableAlert" class="mt-3 mb-3"></alert></div>
    </div>
  </panel>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import Panel from "../shared/Panel.vue";
import { computed, onMounted } from "@vue/runtime-core";
import useLanguageSingleton from "../../composables/language/useLanguageSingleton";
import Alert from "../shared/Alert.vue";
import useAlert from "../../composables/shared/useAlert";
import useUnsavedChanges from "../../composables/shared/useUnsavedChanges";
export default {
  components: { Panel, Alert },
  emits: ["onSave", "onClose"],
  props: {
    inputLanguage: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const alert = useAlert();
    const { addLanguageAsync, updateLanguageAsync, isSavingLanguage } = useLanguageSingleton();
    const { acceptUnsavedChanges } = useUnsavedChanges();
    const panelTitle = ref("New Language");

    const isNewLanguage = ref(false);

    const languageToAddOrUpdate = reactive({
      languageId: 0,
      languageCode: null,
      countryCode: null,
      languageName: null,
      culture: null,
      rightToLeft: false,
      moContactEmail: null,
      freeToDownload: true,
      includeAttributeAndValues: false,
      usePublicationBlobs: true,
      showInBrowser: true,
      isActive: true,
    });

    const languageValidation = reactive({
      invalidLanguageNameMessage: "Please enter valid language name",
      invalidLanguageCodeMessage: "Please enter valid language code",
      invalidCountryCodeMessage: "Please enter valid country code",
      invalidCultureMessage: "Please enter valid culture",
      invalidMOContactEmailMessage: "Please enter valid email",
      isInvalidLanguageName: computed(() => languageToAddOrUpdate.languageName == null || languageToAddOrUpdate.languageName == ""),
      isInvalidLanguageCode: computed(() => languageToAddOrUpdate.languageCode == null || languageToAddOrUpdate.languageCode == "" || languageToAddOrUpdate.languageCode.length != 2),
      isInvalidCountryCode: computed(() => languageToAddOrUpdate.countryCode == null || languageToAddOrUpdate.countryCode == "" || languageToAddOrUpdate.countryCode.length != 2),
      isInvalidCulture: computed(() => languageToAddOrUpdate.culture == null || languageToAddOrUpdate.culture == "" || languageToAddOrUpdate.culture.length != 5),
      isInvalidMOContactEmail: computed(() => languageToAddOrUpdate.moContactEmail == null || languageToAddOrUpdate.moContactEmail == "" || !/\S+@\S+\.\S+/.test(languageToAddOrUpdate.moContactEmail)),
      isSaveDisabled: computed(
        () =>
          languageValidation.isInvalidLanguageName ||
          languageValidation.isInvalidLanguageCode ||
          languageValidation.isInvalidCountryCode ||
          languageValidation.isInvalidCulture ||
          languageValidation.isInvalidMOContactEmail ||
          !isDirty()
      ),
    });

    onMounted(() => {
      if (props.inputLanguage.languageId != 0) {
        languageToAddOrUpdate.languageId = props.inputLanguage.languageId;
        languageToAddOrUpdate.languageCode = props.inputLanguage.languageCode;
        languageToAddOrUpdate.countryCode = props.inputLanguage.countryCode;
        languageToAddOrUpdate.languageName = props.inputLanguage.languageName;
        languageToAddOrUpdate.culture = props.inputLanguage.culture;
        languageToAddOrUpdate.rightToLeft = props.inputLanguage.rightToLeft;
        languageToAddOrUpdate.moContactEmail = props.inputLanguage.moContactEmail ?? "";
        languageToAddOrUpdate.freeToDownload = props.inputLanguage.freeToDownload;
        languageToAddOrUpdate.includeAttributeAndValues = props.inputLanguage.includeAttributeAndValues;
        languageToAddOrUpdate.showInBrowser = props.inputLanguage.showInBrowser;
        languageToAddOrUpdate.usePublicationBlobs = props.inputLanguage.usePublicationBlobs;
        languageToAddOrUpdate.isActive = props.inputLanguage.isActive;

        panelTitle.value = languageToAddOrUpdate.languageName;
      } else {
        isNewLanguage.value = true;
      }
    });

    function isDirty() {
      return (
        isNewLanguage.value ||
        languageToAddOrUpdate.languageCode != props.inputLanguage.languageCode ||
        languageToAddOrUpdate.countryCode != props.inputLanguage.countryCode ||
        languageToAddOrUpdate.languageName != props.inputLanguage.languageName ||
        languageToAddOrUpdate.culture != props.inputLanguage.culture ||
        languageToAddOrUpdate.rightToLeft != props.inputLanguage.rightToLeft ||
        languageToAddOrUpdate.moContactEmail != (props.inputLanguage.moContactEmail ?? "") ||
        languageToAddOrUpdate.freeToDownload != props.inputLanguage.freeToDownload ||
        languageToAddOrUpdate.includeAttributeAndValues != props.inputLanguage.includeAttributeAndValues ||
        languageToAddOrUpdate.showInBrowser != props.inputLanguage.showInBrowser ||
        languageToAddOrUpdate.usePublicationBlobs != props.inputLanguage.usePublicationBlobs 
      );
    }

    async function clickSave() {
      if (isSavingLanguage.value) {
        return;
      }
      let response;
      if (languageToAddOrUpdate.languageId == 0) {
        response = await addLanguageAsync(languageToAddOrUpdate);
      } else {
        response = await updateLanguageAsync(languageToAddOrUpdate);
      }
      emit("onSave", response);
    }

    function clickClose() {
      if (isDirty()) {
        if (acceptUnsavedChanges()) {
          emit("onClose");
        }
      } else {
        emit("onClose");
      }
    }
    return { panelTitle, languageToAddOrUpdate, languageValidation, clickSave, clickClose, bindableAlert: alert.bindableAlert, isSavingLanguage };
  },
};
</script>
