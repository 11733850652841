<template>
  <panel v-if="result.length > 0">
    <div class="row mb-3">
      <div class="col-2 col-sm-2 col-md-4 d-flex align-items-start"></div>
      <div class="col-3 col-sm-4 align-self-center panel-title">Result</div>
      <div class="col-1 d-none d-sm-none d-md-none d-lg-none d-xl-block"></div>
      <div class="col d-flex justify-content-end">
        <div class="input-group">
          <span class="input-group-text rounded-0" id="WorkRequest">WR</span>
          <input
            v-model="workRequest"
            type="text"
            class="form-control rounded-0"
            :class="{ 'is-invalid': validation.isInvalidWorkRequest, 'is-valid': !validation.isInvalidWorkRequest }"
            placeholder="yy-999999"
            required
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table table-sm text-center" aria-label="Result of moving classification">
          <thead>
            <tr>
              <th scope="col">{{parentTitle}}</th>
              <th scope="col"></th>
              <th scope="col">{{childTitle}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in result" :key="index">
              <td class="col-6" :class="{ 'table-danger': item.isRemoved, 'table-success': item.isAdded }">({{ item.parentItem.code }}) {{ item.parentItem.title }}</td>
              <td :class="{ 'table-danger': item.isRemoved, 'table-success': item.isAdded }"><font-awesome icon="long-arrow-alt-right" class="hw-20"></font-awesome></td>
              <td class="col-6" :class="{ 'table-danger': item.isRemoved, 'table-success': item.isAdded }">({{ item.childItem.code }}) {{ item.childItem.title }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row align-items-center mt-2">
      <div class="col-11">
        <input v-model="comment" id="Comment" class="form-control rounded-0" type="text" placeholder="Comment" autocomplete="off" />
      </div>
      <div class="col-1 d-flex justify-content-center">
        <div class="button-icon" @click="save" :class="{ disabled: validation.isInvalidWorkRequest || result.length == 0 }">
          <font-awesome icon="save"></font-awesome>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import Panel from "../shared/Panel.vue";
import { computed } from "@vue/runtime-core";
import useClassification from "../../composables/classification/useClassification";

export default {
  components: { Panel },
  emits: ["onSave"],
  props: {
    parentLevel: {
      type: Number,
      required: true,
    },
    parentItems: {
      type: Array,
      required: true,
    },
    allParentItems: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { getLevelName } = useClassification();
    const workRequest = ref("");
    const comment = ref("");

    const childLevel = props.parentLevel + 1;
    const parentTitle = ref(getLevelName(props.parentLevel));
    const childTitle = ref(getLevelName(childLevel));

    const result = computed(() => {
      const movedItems = [];
      props.parentItems.forEach((parentItem) => {
        parentItem.childs.forEach((childItem) => {
          if (childItem.isAdded) {
            movedItems.push({
              isAdded: true,
              parentItem: parentItem,
              childItem: childItem,
            });
            movedItems.push({
              isRemoved: true,
              parentItem: props.allParentItems.find((f) => f.code == childItem.parentCode),
              childItem: childItem,
            });
          }
        });
      });
      return movedItems;
    });

    const validationErrorMessage = reactive({
      workRequest: "",
    });

    const validation = reactive({
      isInvalidWorkRequest: computed(() => {
        const regex = /^\d{2}-\d{6}$/gm; // nn-nnnnnn
        if (workRequest.value == null || workRequest.value == "") {
          validationErrorMessage.workRequest = "Please enter WR";
          return true;
        }
        if (!workRequest.value.match(regex)) {
          validationErrorMessage.workRequest = "WR must match YY-NNNNNN";
          return true;
        }
        return false;
      }),
    });

    function save() {
      emit("onSave", workRequest.value, comment.value);
    }

    return {
      parentTitle,
      childTitle,
      result,
      workRequest,
      comment,
      validation,
      save,
    };
  },
};
</script>
