import { computed, ref } from "vue";
import gpcEnumRepository from "../../repositories/gpcEnumRepository";
import gpcClassificationRepository from "../../repositories/gpcClassificationRepository";
import gpcRelationRepository from "../../repositories/gpcRelationRepository";

export default function useLogs() {
  const logs = ref([]);
  const relationLogs = ref([]);
  const isFetchingLogs = ref(false);
  const isFetchingRelationLogs = ref(false);
  const isDownloadingLogs = ref(false);
  const isUndoingLog = ref(false);

  async function fetchLogsAsync(languageId, code = null, wr = "", state = null, userId = null, fromDate = null, toDate = null) {
    isFetchingLogs.value = true;
    const response = await gpcClassificationRepository.getLogsAsync(languageId, code, wr, state, userId, fromDate, toDate);
    logs.value = response.result;
    isFetchingLogs.value = false;
    return response;
  }

  async function fetchRelationLogsAsync(languageId, code = null, wr = "", state = null, userId = null, fromDate = null, toDate = null) {
    isFetchingRelationLogs.value = true;
    const response = await gpcRelationRepository.getRelationLogsAsync(languageId, code, wr, state, userId, fromDate, toDate);
    relationLogs.value = response.result;
    isFetchingRelationLogs.value = false;
    return response;
  }

  async function downloadLogsAsXlsxAsync(languageId, code = null, wr = "", state = null, userId = null, fromDate = null, toDate = null) {
    isDownloadingLogs.value = true;
    let response = await gpcClassificationRepository.downloadLogsAsXlsxAsync(languageId, code, wr, state, userId, fromDate, toDate);
    isDownloadingLogs.value = false;
    return response;
  }

  function getLogStatesLabel(states) {
    return states.map((m) => getLogStateName(m)).join("/");
  }

  function getLogStatesClass(states) {
    return getLogStateClass(states.length > 0 ? states[0] : null);
  }

  function getLogStateName(state) {
    switch (state) {
      case gpcEnumRepository.ClassificationState.None:
        return "None";
      case gpcEnumRepository.ClassificationState.Migrated:
        return "Migrated";
      case gpcEnumRepository.ClassificationState.Added:
        return "Added";
      case gpcEnumRepository.ClassificationState.Updated:
        return "Updated";
      case gpcEnumRepository.ClassificationState.Removed:
        return "Removed";
      case gpcEnumRepository.ClassificationState.Moved:
        return "Moved";
      case gpcEnumRepository.ClassificationState.Translated:
        return "Translated";
      default:
        return "";
    }
  }

  function getRelationLogStateName(state) {
    switch (state) {
      case gpcEnumRepository.RelationState.Added:
        return "Added";
      case gpcEnumRepository.RelationState.Removed:
        return "Removed";
      default:
        return "";
    }
  }

  function getLogStateClass(state) {
    switch (state) {
      case gpcEnumRepository.ClassificationState.None:
        return "";
      case gpcEnumRepository.ClassificationState.Migrated:
        return "text-info";
      case gpcEnumRepository.ClassificationState.Added:
        return "text-success";
      case gpcEnumRepository.ClassificationState.Updated:
        return "text-warning";
      case gpcEnumRepository.ClassificationState.Removed:
        return "text-danger";
      case gpcEnumRepository.ClassificationState.Moved:
        return "";
      case gpcEnumRepository.ClassificationState.Translated:
        return "";
      default:
        return "";
    }
  }

  function unmount() {
    logs.value = [];
  }

  async function undoClassificationLogAsync(logId) {
    isUndoingLog.value = true;
    const response = await gpcClassificationRepository.undoLogAsync(logId);
    if (response.isSuccess) {
      let index = logs.value.findIndex((l) => l.id == logId);
      logs.value.splice(index, 1);
    }
    isUndoingLog.value = false;
    return response;
  }

  async function undoRelationLogAsync(logId) {
    isUndoingLog.value = true;
    const response = await gpcRelationRepository.undoLogAsync(logId);
    if (response.isSuccess) {
      let index = relationLogs.value.findIndex((l) => l.id == logId);
      relationLogs.value.splice(index, 1);
    }
    isUndoingLog.value = false;
    return response;
  }

  return {
    logs,
    relationLogs,
    isFetchingLogs: computed(() => isFetchingLogs.value),
    isFetchingRelationLogs: computed(() => isFetchingRelationLogs.value),
    isDownloadingLogs: computed(() => isDownloadingLogs.value),
    isUndoingLog: computed(() => isUndoingLog.value),
    fetchLogsAsync,
    fetchRelationLogsAsync,
    getLogStateName,
    getRelationLogStateName,
    getLogStateClass,
    getLogStatesLabel,
    getLogStatesClass,
    downloadLogsAsXlsxAsync,
    undoClassificationLogAsync,
    undoRelationLogAsync,
    unmount,
  };
}
