<template>
  <loader v-if="isFetchingPublications"></loader>
  <panel v-if="!isFetchingPublications" barColor="sky">
    <div class="row align-items-center">
      <div class="col-4 d-flex align-items-start p-1"></div>
      <div class="col-4 align-self-center panel-title p-1">{{ mainTitle }}</div>
      <div class="col-4 d-flex justify-content-end"></div>
      <div class="col-12 text-start mt-2">
        <alert v-bind="tableAlert" class="mt-3 mb-3"></alert>
        <div class="gpc-table table-responsive">
          <table class="table table-hover table-sm" aria-label="List of publications">
            <thead>
              <tr>
                <th scope="col" class="col-2 fw-bold">Publication Date</th>
                <th scope="col" class="col fw-bold">Publication Name</th>
                <th scope="col" class="col-2 fw-bold">Published by</th>
                <th scope="col" class="col-1 fw-bold">Browser</th>
                <th scope="col" class="col-1 fw-bold">GDSN</th>
                <th scope="col" class="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="publication in publicationsToShow" :key="publication.id">
                <td>{{ new Date(publication.publicationDate).toISOString().slice(0, 10) }}</td>
                <td>{{ publication.publicationName }}</td>
                <td>{{ publication.userName }}</td>
                <td><input class="form-check-input" type="checkbox" v-model="publication.browser" disabled /></td>
                <td><input class="form-check-input" type="checkbox" v-model="publication.isGDSN" disabled /></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import { computed, onMounted, ref } from "@vue/runtime-core";
import Alert from "../shared/Alert.vue";
import Loader from "../shared/Loader.vue";
import Panel from "../shared/Panel.vue";
import usePublication from "../../composables/publication/usePublication";
import useLanguageSingleton from '../../composables/language/useLanguageSingleton';

export default {
  components: { Loader, Panel, Alert },
  setup() {
    const { appLanguage} = useLanguageSingleton();
    const { isFetchingPublications, fetchPublicationsAsync } = usePublication();

    const mainTitle = ref(`Publications ${appLanguage.value.languageCode}`);
    const publications = ref([]);

    const publicationsToShow = computed(() => {
      return publications.value.sort(function(a, b) {
        return new Date(b.publicationDate) - new Date(a.publicationDate);
      });
    });

    onMounted(async () => {
      const response = await fetchPublicationsAsync(appLanguage.value.languageId);
      if (!response.isSuccess) {
        alert.error([response.message]);
      }
      publications.value = response.result;
    });

    return { mainTitle, isFetchingPublications, publicationsToShow, tableAlert: alert.bindableAlert };
  },
};
</script>