<template>
  <div v-if="modal.show" class="modal fade show" id="Modal" tabindex="-1" :style="{display: modal.show ? 'block' : 'none'}">
    <div class="modal-dialog">
      <div class="modal-content rounded-0">
        <div class="modal-header">
          <h5 class="modal-title" id="ModalTitle">{{ modal.title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
        </div>
        <div class="modal-body">{{ modal.text }}</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary rounded-0" data-bs-dismiss="modal" @click="close">Close</button>
          <button type="button" class="btn btn-primary rounded-0" @click="ok">Ok</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="modal.show" class="modal-backdrop fade show"></div>
</template>

<script>
import useModal from "../composables/shared/useModal";
export default {
  setup() {
    const { modal, ok, close } = useModal();
    return { modal, ok, close };
  },
};
</script>
