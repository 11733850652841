import {  reactive } from "vue";

const modal = reactive({
  show: false,
  title: "",
  text: "",
});

let modalConfirmFn = null;
let modalCloseFn = null;

export default function useModal() {
  function showModal(title, text, onConfirmFn, onCloseFn) {
    modal.title = title;
    modal.text = text;

    modalConfirmFn = onConfirmFn;
    modalCloseFn = onCloseFn;

    modal.show = true;
  }

  function hideModal() {
    modal.title = "";
    modal.text = "";
    modal.show = false;

    modalConfirmFn = null;
    modalCloseFn = null;
  }

  function ok() {
    if (modalConfirmFn != null && typeof modalConfirmFn === "function") {
      modalConfirmFn();
    }
    hideModal();
  }

  function close() {
    if (modalCloseFn != null && typeof modalCloseFn === "function") {
      modalCloseFn();
    }
    hideModal();
  }

  return {
    modal,
    showModal,
    hideModal,
    ok,
    close,
  };
}
