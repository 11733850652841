import { computed, ref } from "vue";
import gpcReportRepository from "../../repositories/gpcReportRepository";

export default function useReport() {
  const isDownloadingBrickBySegmentReport = ref(false);
  const isDownloadingTranslationVerificationReport = ref(false);
  const isDownloadingHierarchyReport = ref(false);
  const isDownloadingSegmentReport = ref(false);
  const isDownloadingTopTenSegmentsReport = ref(false);
  const isDownloadingAttributesByBrickReport = ref(false);
  const isDownloadingNumberOfAttributesByBrickReport = ref(false);
  const isDownloadingGdsnBricksMappingReport = ref(false);

  async function downloadBrickBySegmentReportAsXlsxAsync(publicationId, languageId) {
    isDownloadingBrickBySegmentReport.value = true;
    let response = await gpcReportRepository.downloadBrickBySegmentReportAsXlsxAsync(publicationId, languageId);
    isDownloadingBrickBySegmentReport.value = false;
    return response;
  }
  async function downloadTranslationVerificationReportAsXlsxAsync(publicationId, languageId) {
    isDownloadingTranslationVerificationReport.value = true;
    let response = await gpcReportRepository.downloadTranslationVerificationReportAsXlsxAsync(publicationId, languageId);
    isDownloadingTranslationVerificationReport.value = false;
    return response;
  }
  async function downloadHierarchyReportAsXlsxAsync(languageId) {
    isDownloadingHierarchyReport.value = true;
    let response = await gpcReportRepository.downloadHierarchyReportAsXlsxAsync(languageId);
    isDownloadingHierarchyReport.value = false;
    return response;
  }
  async function downloadSegmentReportAsXlsxAsync(languageId) {
    isDownloadingSegmentReport.value = true;
    let response = await gpcReportRepository.downloadSegmentReportAsXlsxAsync(languageId);
    isDownloadingSegmentReport.value = false;
    return response;
  }
  async function downloadTopTenSegmentsReportAsXlsxAsync(languageId) {
    isDownloadingTopTenSegmentsReport.value = true;
    let response = await gpcReportRepository.downloadTopTenSegmentsReportAsXlsxAsync(languageId);
    isDownloadingTopTenSegmentsReport.value = false;
    return response;
  }
  async function downloadAttributesByBrickReportAsXlsxAsync(languageId) {
    isDownloadingAttributesByBrickReport.value = true;
    let response = await gpcReportRepository.downloadAttributesByBrickReportAsXlsxAsync(languageId);
    isDownloadingAttributesByBrickReport.value = false;
    return response;
  }
  async function downloadNumberOfAttributesByBrickReportAsXlsxAsync(languageId) {
    isDownloadingNumberOfAttributesByBrickReport.value = true;
    let response = await gpcReportRepository.downloadNumberOfAttributesByBrickReportAsXlsxAsync(languageId);
    isDownloadingNumberOfAttributesByBrickReport.value = false;
    return response;
  }
  async function downloadGdsnBricksMappingReportAsXlsxAsync(publicationId, languageId) {
    isDownloadingGdsnBricksMappingReport.value = true;
    let response = await gpcReportRepository.downloadGdsnBricksMappingReportAsXlsxAsync(publicationId, languageId);
    isDownloadingGdsnBricksMappingReport.value = false;
    return response;
  }

  return {
    isDownloadingBrickBySegmentReport: computed(() => isDownloadingBrickBySegmentReport.value),
    isDownloadingTranslationVerificationReport: computed(() => isDownloadingTranslationVerificationReport.value),
    isDownloadingHierarchyReport: computed(() => isDownloadingHierarchyReport.value),
    isDownloadingSegmentReport: computed(() => isDownloadingSegmentReport.value),
    isDownloadingTopTenSegmentsReport: computed(() => isDownloadingTopTenSegmentsReport.value),
    isDownloadingAttributesByBrickReport: computed(() => isDownloadingAttributesByBrickReport.value),
    isDownloadingNumberOfAttributesByBrickReport: computed(() => isDownloadingNumberOfAttributesByBrickReport.value),
    isDownloadingGdsnBricksMappingReport: computed(() => isDownloadingGdsnBricksMappingReport.value),
    downloadBrickBySegmentReportAsXlsxAsync,
    downloadTranslationVerificationReportAsXlsxAsync,
    downloadHierarchyReportAsXlsxAsync,
    downloadSegmentReportAsXlsxAsync,
    downloadTopTenSegmentsReportAsXlsxAsync,
    downloadAttributesByBrickReportAsXlsxAsync,
    downloadNumberOfAttributesByBrickReportAsXlsxAsync,
    downloadGdsnBricksMappingReportAsXlsxAsync,
  };
}
