<template>
  <schema-move-classification-result
    v-show="scene.showMoveClassificationResult"
    :parentLevel="parentLevel"
    :parentItems="parentItems"
    :allParentItems="allParentItems"
    @onSave="handleSaveMove"
  ></schema-move-classification-result>
  <panel v-show="scene.showMoveClassification" barColor="blue" class="relation-panel">
    <div class="row mb-3">
      <div class="col-2 col-sm-2 col-md-4 d-flex align-items-start">
        <div class="button-icon" @click="clickBack">
          <font-awesome icon="arrow-circle-left"></font-awesome>
        </div>
      </div>
      <div class="col-3 col-sm-4 align-self-center panel-title">{{ mainTitle }}</div>
      <div class="col-1 d-none d-sm-none d-md-none d-lg-none d-xl-block"></div>
    </div>
    <alert v-bind="bindableAlert" class="mb-3" />
    <div class="row text-start">
      <schema-relation-table :items="allChildItems" :itemsToSearchIn="allChildItems" :title="allChildItemsTitle" class="col" @onAdd="handleAddItem"></schema-relation-table>
    </div>
    <div class="row arrows">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="button-icon">
          <font-awesome icon="chevron-down"></font-awesome>
        </div>
      </div>
    </div>
    <div class="row text-start">
      <div class="col-6">
        <div class="card">
          <div class="card-header p-2">
            {{ parentListTitle }}
          </div>
          <ul class="list-group list-group-flush">
            <li
              v-for="item in parentItems"
              :key="item.code"
              @click="selectParentItem(item)"
              :class="{ 'list-group-item-secondary': isSelected(item.code) }"
              class="list-group-item list-group-item-action p-2"
              :title="item.title"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="text-truncate">
                  <h5>{{ item.code }}</h5>
                  <span>{{ item.title }}</span>
                </div>
                <div class="d-flex">
                  <div v-show="item.childs.length > 0" class="badge bg-secondary rounded-pill">{{ item.childs.length }}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-header d-flex justify-content-between p-2">
            <div>{{ childListTitle }}</div>
          </div>
          <ul class="list-group list-group-flush">
            <li v-for="item in selectedParentItem.childs" :key="item.code" :class="{ 'list-group-item-success': item.isAdded }" class="list-group-item list-group-item-action p-2" :title="item.title">
              <div class="d-flex justify-content-between align-items-center">
                <div class="text-truncate">
                  <h5>{{ item.code }}</h5>
                  <span>{{ item.title }}</span>
                </div>
                <div class="d-flex">
                  <div class="d-flex button-icon small">
                    <span v-show="item.isAdded" @click="removeItem(item)" title="Remove relation">
                      <font-awesome icon="minus" class="me-1"></font-awesome>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import Panel from "../shared/Panel.vue";
import Alert from "../shared/Alert.vue";
import SchemaRelationTable from "../schema/SchemaRelationTable";
import SchemaMoveClassificationResult from "../schema/SchemaMoveClassificationResult";
import useAlert from "../../composables/shared/useAlert";
import { reactive, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import useSchema from "../../composables/schema/useSchema";
import useClassification from "../../composables/classification/useClassification";
import useUnsavedChanges from "../../composables/shared/useUnsavedChanges";
export default {
  components: { Panel, Alert, SchemaRelationTable, SchemaMoveClassificationResult },
  emits: ["onSave", "onClose"],
  props: {
    parentLevel: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    classifications: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const alert = useAlert();
    const { getClassificationsByLevel } = useSchema();
    const { getLevelName, saveClassificationMoveAsync } = useClassification();
    const { acceptUnsavedChanges } = useUnsavedChanges();

    const childLevel = props.parentLevel + 1;
    const mainTitle = ref(`Move ${getLevelName(childLevel)} under ${getLevelName(props.parentLevel)}`);
    const parentListTitle = ref(getLevelName(props.parentLevel));
    const childListTitle = ref(getLevelName(childLevel));
    const allChildItemsTitle = ref(`${getLevelName(childLevel)} List`);

    const scene = reactive({
      showMoveClassification: true,
      showMoveClassificationResult: true,
    });

    const parentItems = ref([]);
    const allParentItems = ref([]);
    const allChildItems = ref([]);
    const selectedParentItem = ref({ childs: [] });

    const cloned = JSON.parse(JSON.stringify(props.items));
    parentItems.value = cloned;

    onMounted(() => {
      if (parentItems.value.length > 0) {
        selectedParentItem.value = parentItems.value[0];
      }
      allChildItems.value = getClassificationsByLevel(props.classifications, childLevel);
      allParentItems.value = getClassificationsByLevel(props.classifications, props.parentLevel);
    });

    function selectParentItem(parrent) {
      selectedParentItem.value = parrent;
    }

    function isSelected(code) {
      return selectedParentItem.value.code == code;
    }

    function clickBack() {
      if (isDirty()) {
        if (acceptUnsavedChanges()) {
          emit("onClose");
        }
      } else {
        emit("onClose");
      }
    }

    function handleAddItem(item) {
      if (selectedParentItem.value.childs.find((child) => child.code == item.code) != null) {
        return;
      }
      item.isAdded = true;
      selectedParentItem.value.childs.push(item);
    }

    function removeItem(item) {
      item.isAdded = false;
      const childToRemoveIndex = selectedParentItem.value.childs.findIndex((child) => child.code == item.code);
      if (childToRemoveIndex >= 0) {
        selectedParentItem.value.childs.splice(childToRemoveIndex, 1);
      }
    }

    async function handleSaveMove(workRequest, comment) {
      let promises = [];
      parentItems.value.forEach((parent) => {
        parent.childs.forEach(async (child) => {
          if (child.isAdded) {
            promises.push(saveClassificationMoveAsync(parent.code, child, workRequest, comment));
            child.isAdded = false;
          }
        });
      });
      const resultArray = await Promise.all(promises);
      if (resultArray.every((response) => response.isSuccess)) {
        alert.success(["Saved successfully!"]);
        emit("onSave");
      } else {
        resultArray.forEach((response) => {
          if (!response.isSuccess) {
            alert.error([response.message]);
          }
        });
      }
    }

    function isDirty() {
      let someDirty = false;
      parentItems.value.forEach((parent) => {
        parent.childs.forEach(async (child) => {
          if (child.isAdded) {
            someDirty = true;
          }
        });
      });
      return someDirty;
    }

    return {
      mainTitle,
      parentListTitle,
      childListTitle,
      allChildItemsTitle,
      scene,
      selectedParentItem,
      parentItems,
      allParentItems,
      allChildItems,
      selectParentItem,
      bindableAlert: alert.bindableAlert,
      handleAddItem,
      isSelected,
      clickBack,
      removeItem,
      handleSaveMove,
    };
  },
};
</script>
