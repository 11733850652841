import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import gpcClassificationRepository from "../../repositories/gpcClassificationRepository";
import gpcEnumRepository from "../../repositories/gpcEnumRepository";
import gpcRelationRepository from "../../repositories/gpcRelationRepository";

export default function useClassification() {
  const isGettingNewCode = ref(false);
  const isSavingClassificationMove = ref(false);
  const isGettingDetailedRelations = ref(false);

  async function getNewCodeAsync(parentCode, level, languageId) {
    isGettingNewCode.value = true;
    const response = await gpcClassificationRepository.getNewCodeAsync(parentCode, level, languageId);
    isGettingNewCode.value = false;
    return response;
  }

  async function saveClassificationMoveAsync(parentCode, classification, workRequest, comment) {
    isSavingClassificationMove.value = true;
    let response = await gpcClassificationRepository.saveClassificationMoveAsync(parentCode, classification, workRequest, comment);
    isSavingClassificationMove.value = false;
    return response;
  }

  async function getDetailedRelationsAsync(languageId, code, level) {
    isGettingDetailedRelations.value = true;
    const response = await gpcRelationRepository.getDetailedRelationsAsync(languageId, code, level);
    isGettingDetailedRelations.value = false;
    return response;
  }

  return {
    getLevelName: (level) => Object.keys(gpcEnumRepository.ClassificationLevel).find((key) => gpcEnumRepository.ClassificationLevel[key] == level),
    isBrick: (item) => item.level == gpcEnumRepository.ClassificationLevel.Brick,
    isClass: (item) => item.level == gpcEnumRepository.ClassificationLevel.Class,
    isFamily: (item) => item.level == gpcEnumRepository.ClassificationLevel.Family,
    isSegment: (item) => item.level == gpcEnumRepository.ClassificationLevel.Segment,
    isAttribute: (item) => item.level == gpcEnumRepository.ClassificationLevel.Attribute,
    isAttributeValue: (item) => item.level == gpcEnumRepository.ClassificationLevel.AttributeValue,
    isGettingNewCode: computed(() => isGettingNewCode.value),
    isGettingDetailedRelations: computed(() => isGettingDetailedRelations.value),
    getNewCodeAsync,
    saveClassificationMoveAsync,
    getDetailedRelationsAsync,
  };
}
