export default (function() {
  const ClassificationLevel = {
    Segment: 1,
    Family: 2,
    Class: 3,
    Brick: 4,
    Attribute: 5,
    AttributeValue: 6,
  };

  const ClassificationState = {
    Migrated: 1,
    Added: 2,
    Updated: 3,
    Removed: 4,
    Moved: 5,
    Translated: 6,
  };

  const ClassificationProperty = {
    None: 1,
    All: 2,
    Title: 3,
    Definition: 4,
    DefinitionExcludes: 5,
  };

  const RelationState = {
    Added: 1,
    Removed: 2,
  };

  return Object.freeze({
    ClassificationLevel,
    ClassificationState,
    ClassificationProperty,
    RelationState,
  });
})();
