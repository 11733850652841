<template>
  <panel barColor="blue">
    <div class="row">
      <div class="col-2 col-sm-2 col-md-4 d-flex align-items-start">
        <div class="button-icon" @click="close">
          <font-awesome icon="arrow-circle-left"></font-awesome>
        </div>
      </div>
      <div class="col-3 col-sm-4 align-self-center panel-title">{{ titleToShow }}</div>
      <div class="col-1 d-none d-sm-none d-md-none d-lg-none d-xl-block"></div>
      <div v-if="isAdmin && isMainLanguage" class="col d-flex justify-content-end">
        <div class="input-group">
          <span class="input-group-text rounded-0" id="WorkRequest">WR</span>
          <input
            v-model="editableProperties.workRequest"
            type="text"
            class="form-control rounded-0"
            :class="{ 'is-invalid': validation.isInvalidWorkRequest, 'is-valid': !validation.isInvalidWorkRequest }"
            placeholder="yy-999999"
            required
          />
        </div>
      </div>
    </div>
    <div class="row text-start mt-2">
      <div class="col-8 col-sm-8 col-md-9 mb-3">
        <label for="TitleEdit" class="form-label">Title</label>
        <input
          id="TitleEdit"
          v-model="editableProperties.title"
          type="text"
          class="form-control rounded-0"
          :class="{ 'is-invalid': validation.isInvalidTitle, 'is-attribute-value': isAttributeValue }"
          :disabled="editableProperties.isDeleted"
          autocomplete="off"
          required
        />
        <div class="invalid-feedback">
          {{ validationErrorMessage.title }}
        </div>
      </div>
      <div class="col-4 col-sm-4 col-md-3 mb-3">
        <label for="Code" class="form-label">Code</label>
        <input
          id="Code"
          v-model="editableProperties.code"
          type="number"
          class="form-control rounded-0"
          :class="{ 'is-invalid': validation.isInvalidCode, 'is-valid': !validation.isInvalidCode }"
          :disabled="!isAdmin || !state.isNew"
          autocomplete="off"
        />
        <div class="invalid-feedback">
          {{ validationErrorMessage.code }}
        </div>
      </div>
      <div v-if="!isBrick" class="mb-3">
        <label for="DefinitionEdit" class="form-label">Definition</label>
        <textarea
          id="DefinitionEdit"
          v-model="editableProperties.definition"
          :disabled="editableProperties.isDeleted"
          class="form-control rounded-0"
          rows="6"
          autocomplete="off"
        ></textarea>
      </div>
      <div v-if="isBrick" class="mb-3">
        <label for="Includes" class="form-label">Definition Includes</label>
        <textarea
          id="Includes"
          v-model="editableProperties.definition"
          :disabled="editableProperties.isDeleted"
          class="form-control rounded-0"
          rows="6"
          autocomplete="off"
        ></textarea>
      </div>
      <div v-if="isBrick" class="mb-3">
        <label for="Excludes" class="form-label">Definition Excludes</label>
        <textarea
          id="Excludes"
          v-model="editableProperties.definitionExcludes"
          :disabled="editableProperties.isDeleted"
          class="form-control rounded-0"
          rows="3"
          autocomplete="off"
        ></textarea>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 col-sm-10">
        <input
          v-model="editableProperties.comment"
          :disabled="editableProperties.isDeleted"
          id="Comment"
          class="form-control rounded-0"
          type="text"
          placeholder="Comment"
          autocomplete="off"
        />
      </div>
      <div class="col-12 col-sm-2 d-flex justify-content-between">
        <div class="form-check form-switch pt-2" :class="{ disabled: isDeleteDisabled }">
          <input v-model="editableProperties.isDeleted" class="form-check-input" type="checkbox" id="Delete" />
          <label class="form-check-label" for="Delete">Deleted</label>
        </div>
        <div class="button-icon" @click="saveClassification" :class="{ disabled: isSaveDisabled }">
          <font-awesome v-if="!state.isSaving" icon="save"></font-awesome>
          <font-awesome v-if="state.isSaving" icon="spinner" class="fa-spinner"></font-awesome>
        </div>
      </div>
    </div>
    <alert v-bind="bindableAlert" class="mt-3" />
  </panel>
  <logs v-if="!state.isNew" :languageId="languageId" :code="editableProperties.code" :key="refreshLogs"></logs>
  <relations v-if="isAttribute || isAttributeValue" :languageId="languageId" :code="editableProperties.code" :level="item.level"></relations>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import useAlert from "../../composables/shared/useAlert";
import Panel from "../shared/Panel.vue";
import Alert from "../shared/Alert.vue";
import Logs from "../shared/Logs.vue";
import Relations from "../shared/Relations.vue";
import useSchemaClassification from "../../composables/schema/useSchemaClassification";
import useModal from "../../composables/shared/useModal";
import useUserSingleton from "../../composables/shared/useUserSingleton";
import useClassification from "../../composables/classification/useClassification";
import useUnsavedChanges from "../../composables/shared/useUnsavedChanges";

export default {
  components: { Panel, Alert, Logs, Relations },
  emits: ["onSave", "onClose"],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const alert = useAlert();
    const { showModal } = useModal();
    const { acceptUnsavedChanges } = useUnsavedChanges();
    const { languageId, isAdmin, isMainLanguage } = useUserSingleton();
    const { isAttributeValue: isAttributeValueLevel, isAttribute: isAttributeLevel, isBrick: isBrickLevel } = useClassification();
    const {
      init,
      titleToShow,
      editableProperties,
      saveAsync,
      state,
      isDirty,
      setNewCodeAsync,
      validation,
      validationErrorMessage,
    } = useSchemaClassification();

    init(props.item, props.item.isNew);

    const isBrick = isBrickLevel(props.item);
    const isAttribute = isAttributeLevel(props.item);
    const isAttributeValue = isAttributeValueLevel(props.item);
    const refreshLogs = ref(0);

    onMounted(() => {
      if (props.item.isNew) {
        setNewCodeAsync();
      }
    });

    const isSaveDisabled = computed(() => {
      if (validation.isInvalidTitle || validation.isInvalidCode) {
        return true;
      }
      if (isAdmin.value && isMainLanguage.value && validation.isInvalidWorkRequest) {
        return true;
      }

      return !isDirty();
    });

    const isDeleteDisabled = computed(() => {
      return !isAdmin.value || !isMainLanguage.value || state.isNew || (!state.isNew && validation.isInvalidWorkRequest);
    });

    async function save() {
      if (state.isSaving) {
        return;
      }
      const response = await saveAsync(isMainLanguage.value);
      if (response.isSuccess) {
        alert.success(["Saved successfully!"]);
        refreshLogs.value = ++refreshLogs.value;
        emit("onSave", response.result);
        init(response.result, false);
      } else {
        alert.error([response.message]);
      }
    }

    function close() {
      if (isDirty()) {
        if (acceptUnsavedChanges()) {
          emit("onClose");
        }
      } else {
        emit("onClose");
      }
    }

    async function saveClassification() {
      if (editableProperties.isDeleted) {
        showModal(
          "Warning",
          `You are about to delete ${editableProperties.title}(${editableProperties.code}) and its associated GPC hierarchy down to the attribute/value level. Are you sure?`,
          save
        );
      } else {
        save();
      }
    }

    return {
      languageId,
      titleToShow,
      isAdmin,
      isMainLanguage,
      editableProperties,
      isSaveDisabled,
      isDeleteDisabled,
      validationErrorMessage,
      validation,
      close,
      saveClassification,
      state,
      isBrick,
      isAttribute,
      isAttributeValue,
      refreshLogs,
      bindableAlert: alert.bindableAlert,
    };
  },
};
</script>

<style lang="scss" scoped>
.panel {
  & .button-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  & .disabled {
    cursor: default;
    user-select: none;
    pointer-events: none;
    opacity: 0.3;
  }
}
.is-attribute-value {
  text-transform: uppercase !important;
}
</style>
