<template>
  <div v-if="state.showIconButtons" class="d-flex icon-buttons justify-content-end mt-3">
    <button @click="state.showSearch = !state.showSearch" class="btn btn-light me-2">
      <font-awesome icon="search" class="button-icon"></font-awesome>
    </button>
    <button v-if="isAdmin" @click="state.showModification = !state.showModification" class="btn btn-light">
      <font-awesome icon="file" class="button-icon"></font-awesome>
    </button>
  </div>
  <div>
    <schema-search
      v-if="state.showSearch"
      @onGoToSearchResult="handleGoToSearchResult"
      :classifications="classifications"
      @onClose="state.showSearch = false"
    ></schema-search>
    <schema-modification v-if="state.showModification" @onSuccess="handleSaveMove" @onClose="state.showModification = false"></schema-modification>
  </div>
  <div>
    <schema-navigation @onLinkChange="handleLinkChange"></schema-navigation>
  </div>
  <div class="row" v-if="state.showClassification">
    <schema-classification @onSave="handleSaveClassification" @onClose="handleCloseClassification" :item="itemToAddOrUpdate"></schema-classification>
  </div>
  <div class="row" v-if="state.showRelation">
    <schema-brick-attribute-value-relation
      @onSave="handleSaveRelation"
      @onClose="handleCloseRelation"
      :items="currentLevel.items"
      :classifications="classifications"
    ></schema-brick-attribute-value-relation>
  </div>
  <div class="row" v-if="state.showMove">
    <schema-move-classification
      @onSave="handleSaveMove"
      @onClose="handleCloseMove"
      :parentLevel="currentLevel.level"
      :items="currentLevel.items"
      :classifications="classifications"
    ></schema-move-classification>
  </div>
  <div class="row" v-show="state.showSchema">
    <loader v-show="isFetchingSchema"></loader>
    <spinner
      v-if="isFetchingSecondPartOfSchema && !isFetchingSchema"
      :texts="['Loading Bricks...', 'Loading Attributes...', 'Loading Attribute Values...', 'Finishing...']"
      style="margin-bottom: -10px !important"
      class="justify-content-center"
    ></spinner>
    <panel v-show="!isFetchingSchema">
      <div class="row align-items-center">
        <div class="col-4 d-flex align-items-start p-1">
          <div class="button-icon mx-2" v-show="isGoBackAvailable" @click="clickBack">
            <font-awesome icon="arrow-circle-left"></font-awesome>
          </div>
          <div class="button-icon ms-2" v-show="!isGoBackAvailable" @click="clickExportJson" title="Download Combined Schema as .json">
            <font-awesome v-if="!exportState.isDownloadingJson" icon="file-alt"></font-awesome>
            <font-awesome v-if="exportState.isDownloadingJson" icon="spinner" class="fa-spinner"></font-awesome>
          </div>
          <div class="button-icon" v-show="!isGoBackAvailable" @click="clickExportXml" title="Download Combined Schema as .xml">
            <font-awesome v-if="!exportState.isDownloadingXml" icon="file-code"></font-awesome>
            <font-awesome v-if="exportState.isDownloadingXml" icon="spinner" class="fa-spinner"></font-awesome>
          </div>
        </div>
        <div class="col-4 align-self-center panel-title p-1">{{ currentLevel.title }}</div>
        <div class="col-4 d-flex">
          <input v-model="searchTitle" id="SearchTitle" class="form-control rounded-0" type="text" placeholder="Type to search title or code..." />
          <div v-if="isAdmin && (isSegment(currentLevel) || isFamily(currentLevel) || isClass(currentLevel))" class="d-flex justify-content-end ms-2">
            <span id="Move" @click="clickMove">
              <font-awesome icon="exchange-alt" class="button-icon"></font-awesome>
            </span>
          </div>
          <div v-if="isAdmin && isBrick(currentLevel)" class="d-flex justify-content-end ms-2">
            <span id="Relation" @click="clickRelation">
              <font-awesome icon="share-alt" class="button-icon"></font-awesome>
            </span>
          </div>
          <div v-if="isAdmin && !(isAttribute(currentLevel) || isAttributeValue(currentLevel))" class="d-flex justify-content-end ms-2">
            <span id="AddItem" @click="clickAdd">
              <font-awesome icon="plus-circle" class="button-icon"></font-awesome>
            </span>
          </div>
        </div>
        <div class="col-12 text-start">
          <div ref="schemaTableElement" class="level-table table-responsive">
            <table class="table table-hover table-sm" aria-label="Schema table">
              <thead>
                <tr>
                  <th scope="col" class="col-9 fw-bold">Title</th>
                  <th scope="col" class="col-3 fw-bold text-end pe-5">Code</th>
                  <th scope="col" class="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in searchedItems" :key="item" @dblclick="doubleClickItem(item)" class="classification-row">
                  <td :class="{ disabled: !item.isActive }">{{ item.title }}</td>
                  <td class="text-end" :class="{ disabled: !item.isActive }">{{ item.code }}</td>
                  <td @click="clickEdit(item)" class="fw-bold tex-end"><font-awesome icon="pencil-alt" class="small button-icon"></font-awesome></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>
import Panel from "../components/shared/Panel.vue";
import SchemaNavigation from "../components/schema/SchemaNavigation.vue";
import SchemaClassification from "../components/schema/SchemaClassification.vue";
import useSchema from "../composables/schema/useSchema";
import useClassification from "../composables/classification/useClassification";
import useSchemaExport from "../composables/schema/useSchemaExport";
import useUserSingleton from "../composables/shared/useUserSingleton";
import { downloadJson, downloadXml, downloadXlsx } from "../utils/fileUtil";
import { reactive, ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import useSchemaNavigationSingleton from "../composables/schema/useSchemaNavigationSingleton";
import Loader from "../components/shared/Loader.vue";
import Spinner from "../components/shared/Spinner.vue";
import useSchemaRelation from "../composables/schema/useSchemaRelation";
import SchemaBrickAttributeValueRelation from "../components/schema/SchemaBrickAttributeValueRelation.vue";
import SchemaMoveClassification from "../components/schema/SchemaMoveClassification.vue";
import SchemaSearch from "../components/schema/SchemaSearch.vue";
import SchemaModification from "../components/schema/SchemaModification.vue";
import useToast from "../composables/shared/useToast";

export default {
  components: {
    SchemaNavigation,
    SchemaClassification,
    Panel,
    Loader,
    Spinner,
    SchemaBrickAttributeValueRelation,
    SchemaMoveClassification,
    SchemaSearch,
    SchemaModification,
  },
  setup() {
    const { user, isAdmin } = useUserSingleton();
    const { addLink, removeLastLink, getLink, removeLinks } = useSchemaNavigationSingleton();
    const { exportAsJsonAsync, exportAsXmlAsync, exportAsXlsxAsync } = useSchemaExport();
    const {
      isFetchingSchema,
      isFetchingSecondPartOfSchema,
      fetchSchemaAsync,
      currentLevel,
      goToLevel,
      goBackToLevel,
      isGoBackAvailable,
      upsertClassificationToSchema,
      classifications,
    } = useSchema();
    const { isBrick, isClass, isFamily, isSegment, isAttribute, isAttributeValue } = useClassification();
    const { resolveRelationChanges } = useSchemaRelation();
    const { showToast } = useToast();

    const state = reactive({
      showSchema: true,
      showClassification: false,
      showRelation: false,
      showMove: false,
      showIconButtons: true,
      showSearch: false,
      showModification: false,
    });

    const exportState = reactive({
      isDownloadingJson: false,
      isDownloadingXml: false,
      isDownloadingExcel: false,
    });

    const itemToAddOrUpdate = ref({});

    const searchTitle = ref("");

    const schemaTableElement = ref(null);

    const searchedItems = computed(() => {
      if (searchTitle.value === "") {
        return sortItems(currentLevel.items);
      }
      return sortItems(currentLevel.items).filter(
        (item) => item.title.toLowerCase().includes(searchTitle.value.toLowerCase()) || item.code.toString().includes(searchTitle.value.toLowerCase())
      );
    });

    const sortItems = (items) => {
      return items.sort((a, b) => {
        {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          if (textA < textB) {
            return -1;
          }
          return textA > textB ? 1 : 0;
        }
      });
    };

    onMounted(async () => {
      fetchSchemaAsync(user.value.language.languageId);
    });

    function handleLinkChange(link) {
      goToLevel(link);
      state.showSchema = true;
      state.showIconButtons = true;
      state.showClassification = false;
      state.showRelation = false;
      state.showMove = false;
      searchTitle.value = "";
      scrollToSchema();
    }

    function handleGoToSearchResult(searchResult) {
      removeLinks();
      searchResult.parents.forEach((p) => {
        addLink(p);
      });
      goToLevel(getLink());
      searchTitle.value = searchResult.item.code.toString();
      scrollToSchema();
    }

    function handleSaveClassification(item) {
      upsertClassificationToSchema(item);
    }

    function handleCloseClassification() {
      state.showSchema = true;
      state.showIconButtons = true;
      state.showClassification = false;
    }

    function handleSaveRelation(bricks) {
      resolveRelationChanges(currentLevel.items, bricks);
    }

    function handleCloseRelation() {
      state.showSchema = true;
      state.showIconButtons = true;
      state.showRelation = false;
    }

    function handleSaveMove() {
      showToast(`Please, click button below to apply changes.
    <div class="mt-2 pt-2 border-top"></div>
      <a href="/Schema" class="btn btn-secondary text-white btn-sm">Refresh schema</a>
    </div>`);
    }

    function handleCloseMove() {
      state.showSchema = true;
      state.showIconButtons = true;
      state.showMove = false;
    }

    function doubleClickItem(item) {
      addLink(item);
      goToLevel(getLink());
      searchTitle.value = "";
    }

    function clickBack() {
      removeLastLink();
      goBackToLevel(getLink());
      searchTitle.value = "";
    }

    function clickAdd() {
      itemToAddOrUpdate.value = {
        isNew: true,
        level: currentLevel.level,
        languageId: user.value.language.languageId,
        parentCode: currentLevel.parent != null ? currentLevel.parent.code : null,
      };
      state.showSchema = false;
      state.showIconButtons = false;
      state.showClassification = true;
    }

    function clickRelation() {
      state.showSchema = false;
      state.showIconButtons = false;
      state.showRelation = true;
    }

    function clickMove() {
      state.showSchema = false;
      state.showIconButtons = false;
      state.showMove = true;
    }

    function clickEdit(item) {
      itemToAddOrUpdate.value = item;
      state.showSchema = false;
      state.showIconButtons = false;
      state.showClassification = true;
    }

    async function clickExportJson() {
      if (exportState.isDownloadingJson) {
        return;
      }
      exportState.isDownloadingJson = true;
      let data = await exportAsJsonAsync(user.value.language.languageId, true, false);
      downloadJson(data.name, data.file);
      exportState.isDownloadingJson = false;
    }

    async function clickExportXml() {
      if (exportState.isDownloadingXml) {
        return;
      }
      exportState.isDownloadingXml = true;
      let data = await exportAsXmlAsync(user.value.language.languageId, true, false);
      downloadXml(data.name, data.file);
      exportState.isDownloadingXml = false;
    }

    async function clickExportExcel() {
      if (exportState.isDownisDownloadingExcelloadingJson) {
        return;
      }
      exportState.isDownloadingExcel = true;
      let data = await exportAsXlsxAsync(user.value.language.languageId, true, false);
      downloadXlsx(data.name, data.file);
      exportState.isDownloadingExcel = false;
    }

    function scrollToSchema() {
      window.scrollTo(0, schemaTableElement.value.offsetTop);
    }

    return {
      isAdmin,
      state,
      exportState,
      isFetchingSchema,
      isFetchingSecondPartOfSchema,
      isGoBackAvailable,
      isBrick,
      isClass,
      isFamily,
      isSegment,
      isAttribute,
      isAttributeValue,
      searchTitle,
      searchedItems,
      currentLevel,
      goBackToLevel,
      doubleClickItem,
      clickBack,
      clickEdit,
      clickAdd,
      clickRelation,
      clickMove,
      clickExportJson,
      clickExportXml,
      clickExportExcel,
      handleLinkChange,
      handleSaveClassification,
      handleCloseClassification,
      handleSaveRelation,
      handleCloseRelation,
      handleSaveMove,
      handleCloseMove,
      handleGoToSearchResult,
      classifications,
      itemToAddOrUpdate,
      schemaTableElement,
    };
  },
};
</script>

<style lang="scss" scoped>
.panel {
  & .classification-row:hover {
    & td .button-icon {
      &.small {
        cursor: pointer;
        user-select: initial;
        pointer-events: initial;
        opacity: 1;
      }
    }
  }

  & .button-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;

    &.small {
      height: 17px;
      width: 17px;
      user-select: none;
      pointer-events: none;
      opacity: 0;
    }
  }
  & .disabled {
    cursor: default;
    user-select: none;
    opacity: 0.3;
  }
}
.level-table {
  & tr.selected-item {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
  }
}

.classification-row {
  cursor: default;
}

.icon-buttons .button-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
</style>
