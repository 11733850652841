<template>
  <loader-grid class="mt-3" v-if="isMergingPublication"></loader-grid>
  <div v-if="!isCheckingForUpdates && !isMergingPublication && card.isAnyUpdate" class="card border-success mt-3 text-start">
    <div class="card-header bg-success text-white">{{ card.headerText }}</div>
    <div class="card-body d-flex justify-content-between align-items-center">
      <div class="card-text">
        <span>{{ card.bodyTextStart }}</span>
        <span class="fw-bold">{{ card.publicationName }}</span>
        <span>{{ card.bodyTextEnd }}</span>
      </div>
      <button @click="clickUpdate" type="button" class="btn btn-success">Merge</button>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "@vue/runtime-core";
import usePublication from "../../composables/publication/usePublication";
import useUserSingleton from "../../composables/shared/useUserSingleton";
import LoaderGrid from "../shared/LoaderGrid.vue";
import useLanguageSingleton from "../../composables/language/useLanguageSingleton";
import useBlob from "../../composables/shared/useBlob";
import useToast from "../../composables/shared/useToast";
export default {
  components: { LoaderGrid },
  emits: ["onMerge"],
  setup(props, { emit }) {
    const { appLanguage } = useLanguageSingleton();
    const { user } = useUserSingleton();
    const { isCheckingForUpdates, checkForUpdatesAsync, isMergingPublication, mergePublicationAsync } = usePublication();
    const { uploadBlobsForLanguageAsync } = useBlob();
    const { showToast } = useToast();

    const publication = ref({});
    const card = reactive({
      isAnyUpdate: false,
      headerText: "",
      bodyTextStart: "",
      bodyTextEnd: "",
      publicationName: "",
    });

    onMounted(async () => {
      const response = await checkForUpdatesAsync(appLanguage.value.languageId, user.value.language.languageId);
      publication.value = response.result;
      if (response.statusCode == 200) {
        card.isAnyUpdate = true;
        card.headerText = `New update available`;
        card.bodyTextStart = `Newest publication `;
        card.bodyTextEnd = ` is available for you.`;
        card.publicationName = publication.value.publicationName;
      }
    });

    async function clickUpdate() {
      const response = await mergePublicationAsync(user.value.language.languageId, publication.value.publicationId);
      emit("onMerge", response);
      if (response.isSuccess && user.value.language.usePublicationBlobs) {
        uploadBlobsForLanguageAsync(user.value.language.languageId);
        showToast("Processing publication files...");
      }
    }

    return { isCheckingForUpdates, isMergingPublication, card, clickUpdate };
  },
};
</script>
