import { computed, reactive, ref } from "vue";

const toasts = ref([]);

export default function useToast() {
  function showToast(message, success = true) {
    let toast = reactive({
      isSuccess: false,
      status: "",
      message: "",
      buttonText: null,
      action: null,
    });

    if (success) {
      toast.isSuccess = true;
      toast.status = "Success";
    } else {
      toast.status = "Failed";
    }
    toast.message = message;
    toasts.value.push(toast);
  }

  function showToastWithAction(message, buttonText, buttonActionFn) {
    toasts.value.push(
      reactive({
        isSuccess: true,
        status: "Info",
        message: message,
        buttonText: buttonText,
        buttonActionFn: () => {
          buttonActionFn();
          toasts.value = [];
        },
      })
    );
  }

  function removeToast(index) {
    toasts.value.splice(index, 1);
  }

  return {
    toasts: computed(() => toasts.value),
    showToast,
    showToastWithAction,
    removeToast,
  };
}
