import { computed, reactive } from "vue";
import gpcEnumRepository from "../../repositories/gpcEnumRepository";
import gpcTranslationRepository from "../../repositories/gpcTranslationRepository";

export default function useTranslationClassification() {
  const translation = reactive({
    from: {},
    to: {},
    properties: [],
    state: 0,
    level: 0,
    context: "",
  });
  const actionState = reactive({
    isSaving: false,
    isTranslating: false,
  });
  const translatedProperties = reactive({
    title: "",
    definition: "",
    definitionExcludes: "",
  });
  const propertiesToTranslate = reactive({
    title: computed(() => translation.properties.find((f) => f == gpcEnumRepository.ClassificationProperty.All || f == gpcEnumRepository.ClassificationProperty.Title)),
    definition: computed(() => translation.properties.find((f) => f == gpcEnumRepository.ClassificationProperty.All || f == gpcEnumRepository.ClassificationProperty.Definition)),
    definitionExcludes: computed(() => translation.properties.find((f) => f == gpcEnumRepository.ClassificationProperty.All || f == gpcEnumRepository.ClassificationProperty.DefinitionExcludes)),
  });

  async function saveAsync(languageId) {
    if (translatedProperties.title == null || translatedProperties.title == "") {
      return { isSuccess: false, message: "Title is required" };
    }

    const translationToSave = {
      languageId: languageId,
      level: translation.from.level,
      parentCode: translation.from.parentCode,
      code: translation.from.code,
      title: translatedProperties.title,
      definition: translatedProperties.definition,
      definitionExcludes: translatedProperties.definitionExcludes,
      properties: translation.properties,
      state: gpcEnumRepository.ClassificationState.Translated,
      workRequest: translation.from.workRequest,
      comment: translation.from.comment,
    };

    actionState.isSaving = true;
    let response = await gpcTranslationRepository.saveAsync(translationToSave);
    if (response.isSuccess) {
      translation.to = translation.to || {};
      translation.to.title = translatedProperties.title;
      translation.to.definition = translatedProperties.definition;
      translation.to.definitionExcludes = translatedProperties.definitionExcludes;
    }
    actionState.isSaving = false;
    return response;
  }

  function isDirty() {
    if (translatedProperties.title === "") {
      return false;
    }
    if (translation.to == null) {
      return true;
    }
    return (
      translatedProperties.title != translation.to.title || translatedProperties.definition != translation.to.definition || translatedProperties.definitionExcludes != translation.to.definitionExcludes
    );
  }

  return {
    propertiesToTranslate: computed(() => propertiesToTranslate),
    translation: computed(() => translation),
    translatedProperties: computed(() => translatedProperties),
    actionState: computed(() => actionState),
    saveAsync,
    isDirty,
  };
}
