import { computed, ref } from "vue";
import gpcPublicationRepository from "../../repositories/gpcPublicationRepository";

export default function usePublication() {
  const isFetchingPublications = ref(false);
  const isFetchingPublicationDelta = ref(false);
  const isImportingCombinedPublished = ref(false);
  const isImportingCombinedPublishedDelta = ref(false);
  const isSavingPublication = ref(false);
  const isCheckingForUpdates = ref(false);
  const isMergingPublication = ref(false);
  const isDownloadingPublicationDeltaJson = ref(false);
  const isDownloadingPublicationDeltaXlsx = ref(false);
  const isUploadingReleaseNoteAttachment = ref(false);
  const isDeletingReleaseNoteAttachment = ref(false);
  const isDeletingPublication = ref(false);

  async function fetchPublicationsAsync(languageId) {
    isFetchingPublications.value = true;
    const response = await gpcPublicationRepository.getPublications(languageId);
    isFetchingPublications.value = false;
    return response;
  }

  async function fetchPublicationClassificationDeltaAsync(languageId, publicationId) {
    isFetchingPublicationDelta.value = true;
    const response = await gpcPublicationRepository.getPublicationClassificationDeltaAsync(languageId, publicationId);
    isFetchingPublicationDelta.value = false;
    return response;
  }

  async function fetchPublicationRelationDeltaAsync(languageId, publicationId) {
    isFetchingPublicationDelta.value = true;
    const response = await gpcPublicationRepository.getPublicationRelationDeltaAsync(languageId, publicationId);
    isFetchingPublicationDelta.value = false;
    return response;
  }

  async function checkForUpdatesAsync(fromLanguageId, forLanguageId) {
    isCheckingForUpdates.value = true;
    const response = await gpcPublicationRepository.checkForUpdatesAsync(fromLanguageId, forLanguageId);
    isCheckingForUpdates.value = false;
    return response;
  }
  async function mergePublicationAsync(mergeTolanguageId, mergeFromPublicationId) {
    isMergingPublication.value = true;
    const response = await gpcPublicationRepository.mergePublicationAsync(mergeTolanguageId, mergeFromPublicationId);
    isMergingPublication.value = false;
    return response;
  }

  async function savePublicationAsync(publication) {
    isSavingPublication.value = true;
    const request = {
      PublicationId: publication.publicationId,
      languageId: publication.languageId,
      PublicationName: publication.publicationName,
      PublicationDate: publication.publicationDate,
      Browser: publication.browser,
      IsGDSN: publication.isGDSN,
    };
    let response;
    if (request.PublicationId == 0) {
      response = await gpcPublicationRepository.addPublicationAsync(request);
    } else {
      response = await gpcPublicationRepository.updatePublicationAsync(request);
    }
    isSavingPublication.value = false;
    return response;
  }

  async function importCombinedPublishedXmlAsync(toImport, isMainLanguage) {
    isImportingCombinedPublished.value = true;
    const response = await gpcPublicationRepository.importCombinedPublishedXmlAsync(toImport, isMainLanguage);
    isImportingCombinedPublished.value = false;
    return response;
  }

  async function importCombinedPublishedDeltaXmlAsync(toImport, isMainLanguage) {
    isImportingCombinedPublishedDelta.value = true;
    const response = await gpcPublicationRepository.importCombinedPublishedDeltaXmlAsync(toImport, isMainLanguage);
    isImportingCombinedPublishedDelta.value = false;
    return response;
  }

  async function exportPublicationDeltaAsJsonAsync(languageId, publicationId) {
    isDownloadingPublicationDeltaJson.value = true;
    const response = await gpcPublicationRepository.exportPublicationDeltaAsJsonAsync(languageId, publicationId);
    isDownloadingPublicationDeltaJson.value = false;
    return response;
  }

  async function exportPublicationDeltaAsXlsxAsync(languageId, publicationId) {
    isDownloadingPublicationDeltaXlsx.value = true;
    const response = await gpcPublicationRepository.exportPublicationDeltaAsXlsxAsync(languageId, publicationId);
    isDownloadingPublicationDeltaXlsx.value = false;
    return response;
  }

  async function uploadReleaseNoteAttachmentAsync(attachment) {
    isUploadingReleaseNoteAttachment.value = true;
    const response = await gpcPublicationRepository.uploadReleaseNoteAttachmentAsync(attachment);
    isUploadingReleaseNoteAttachment.value = false;
    return response;
  }

  async function deleteReleaseNoteAttachmentAsync(publicationId) {
    isDeletingReleaseNoteAttachment.value = true;
    const response = await gpcPublicationRepository.deleteReleaseNoteAttachmentAsync(publicationId);
    isDeletingReleaseNoteAttachment.value = false;
    return response;
  }

  async function deletePublicationAsync(publicationId) {
    isDeletingPublication.value = true;
    const response = await gpcPublicationRepository.deletePublicationAsync(publicationId);
    isDeletingPublication.value = false;
    return response;
  }

  return {
    isFetchingPublications: computed(() => isFetchingPublications.value),
    isFetchingPublicationDelta: computed(() => isFetchingPublicationDelta.value),
    isImportingCombinedPublished: computed(() => isImportingCombinedPublished.value),
    isImportingCombinedPublishedDelta: computed(() => isImportingCombinedPublishedDelta.value),
    isSavingPublication: computed(() => isSavingPublication.value),
    isMergingPublication: computed(() => isMergingPublication.value),
    isDownloadingPublicationDeltaJson: computed(() => isDownloadingPublicationDeltaJson.value),
    isDownloadingPublicationDeltaXlsx: computed(() => isDownloadingPublicationDeltaXlsx.value),
    isUploadingReleaseNoteAttachment: computed(() => isUploadingReleaseNoteAttachment.value),
    isDeletingReleaseNoteAttachment: computed(() => isDeletingReleaseNoteAttachment.value),
    isDeletingPublication: computed(() => isDeletingPublication.value),
    fetchPublicationsAsync,
    fetchPublicationClassificationDeltaAsync,
    fetchPublicationRelationDeltaAsync,
    importCombinedPublishedXmlAsync,
    importCombinedPublishedDeltaXmlAsync,
    savePublicationAsync,
    checkForUpdatesAsync,
    mergePublicationAsync,
    exportPublicationDeltaAsJsonAsync,
    exportPublicationDeltaAsXlsxAsync,
    uploadReleaseNoteAttachmentAsync,
    deleteReleaseNoteAttachmentAsync,
    deletePublicationAsync,
  };
}
