<template>
  <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
    <div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true" v-for="(toast, index) in toasts" :key="toast">
      <div class="toast-header">
        <strong class="me-auto" :class="{ 'text-success': toast.isSuccess, 'text-danger': !toast.isSuccess }">{{ toast.status }}</strong>
        <small>just now</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" @click="removeToast(index)"></button>
      </div>
      <div v-if="!toast.buttonText || !toast.buttonActionFn" class="toast-body" v-html="toast.message"></div>
      <div v-if="toast.buttonText && toast.buttonActionFn" class="toast-body">
        {{ toast.message }}
        <div class="mt-2 pt-2 border-top"></div>
        <a href="#!" @click="toast.buttonActionFn" class="btn btn-secondary text-white btn-sm">{{ toast.buttonText }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import useToast from "../composables/shared/useToast";
export default {
  setup() {
    const { toasts, removeToast } = useToast();
    return { toasts, removeToast };
  },
};
</script>
