import { httpGet, httpPost, httpDelete } from "../services/httpService";
export default (function() {
  async function saveRelationsAsync(languageId, workRequest, comment, relationsToSave) {
    return httpPost(`/relation`, {
      LanguageId: languageId,
      WorkRequest: workRequest,
      Comment: comment,
      Relations: relationsToSave,
    });
  }

  async function getDetailedRelationsAsync(languageId, code, level) {
    return httpGet(`/relation?languageId=${languageId}&code=${code}&level=${level}`);
  }

  async function getRelationLogsAsync(languageId, code = null, wr = "", state = null, userId = null, fromDate = null, toDate = null) {
    return httpGet(`/relation/log?languageId=${languageId}&code=${code}&wr=${wr}&state=${state}&userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`);
  }

  async function undoLogAsync(logId) {
    return httpDelete(`/relation/log/${logId}`);
  }

  return Object.freeze({
    saveRelationsAsync,
    getDetailedRelationsAsync,
    getRelationLogsAsync,
    undoLogAsync
  });
})();
