<template>
  <div class="view-menu card card-body mt-2 rounded-0 text-start">
    <div v-if="!(isImportingCombinedPublished || isImportingCombinedPublishedDelta)">
      <label for="excelFile" class="form-label">GS1 Combined Published .xml</label>
      <div class="input-group mb-1 bg-white">
        <select v-model="selectedLanguage.languageIdForSchema">
          <option v-for="language in languages" :key="language.languageId" :value="language.languageId">
            {{ language.languageName }}
          </option>
        </select>
        <input class="form-control" type="file" id="excelFile" @change="selectFile($event, true)" accept="text/xml" />
        <button class="btn btn-outline-secondary" type="button" @click="clickUpload(true)" title="Upload Translation sheet as .xlsx">Upload</button>
      </div>
    </div>
    <div class="mt-3" v-if="!(isImportingCombinedPublished || isImportingCombinedPublishedDelta)">
      <label for="excelFile" class="form-label">GS1 Combined Published Delta .xml</label>
      <div class="input-group mb-1 bg-white">
        <select v-model="selectedLanguage.languageIdForDelta">
          <option v-for="language in languages" :key="language.languageId" :value="language.languageId">
            {{ language.languageName }}
          </option>
        </select>
        <input class="form-control" type="file" id="excelFile" @change="selectFile($event, false)" accept="text/xml" />
        <button class="btn btn-outline-secondary" type="button" @click="clickUpload(false)" title="Upload Translation sheet as .xlsx">Upload</button>
      </div>
    </div>
    <spinner v-if="isImportingCombinedPublished || isImportingCombinedPublishedDelta" :text="spinnerText" class="d-flex justify-content-center"></spinner>
    <alert v-bind="importAlert" class="mt-3 mb-3"></alert>
  </div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import useAlert from "../../composables/shared/useAlert";
import useUserSingleton from "../../composables/shared/useUserSingleton";
import Alert from "../shared/Alert.vue";
import Spinner from "../shared/Spinner.vue";
import usePublication from "../../composables/publication/usePublication";
import useLanguageSingleton from "../../composables/language/useLanguageSingleton";

export default {
  components: { Alert, Spinner },
  emits: ["onImport"],
  setup(props, { emit }) {
    const { user } = useUserSingleton();
    const { languages, getLanguageCode, appLanguage } = useLanguageSingleton();
    const alert = useAlert();
    const { importCombinedPublishedXmlAsync, importCombinedPublishedDeltaXmlAsync, isImportingCombinedPublishedDelta, isImportingCombinedPublished } = usePublication();

    const spinnerText = ref("Loading");
    const selectedLanguage = reactive({
      languageIdForSchema: user.value.language.languageId,
      languageIdForDelta: user.value.language.languageId,
    });

    const schemaToUpload = reactive({
      languageId: user.value.language.languageId,
      languageCode: user.value.language.languageCode,
      name: "",
      data: null,
      formData: null,
    });

    const deltaToUpload = reactive({
      languageId: user.value.language.languageId,
      languageCode: user.value.language.languageCode,
      name: "",
      data: null,
      formData: null,
    });

    function selectFile(event, isSchema) {
      let file = event.target.files[0];

      if (file == null) {
        return;
      }

      if (isSchema) {
        schemaToUpload.languageId = selectedLanguage.languageIdForSchema;
        schemaToUpload.languageCode = getLanguageCode(selectedLanguage.languageIdForSchema);
        schemaToUpload.formData = new FormData();
        schemaToUpload.formData.append("File", file);
        schemaToUpload.name = file.name;
        schemaToUpload.data = file;
      } else {
        deltaToUpload.languageId = selectedLanguage.languageIdForDelta;
        deltaToUpload.languageCode = getLanguageCode(selectedLanguage.languageIdForDelta);
        deltaToUpload.formData = new FormData();
        deltaToUpload.formData.append("File", file);
        deltaToUpload.name = file.name;
        deltaToUpload.data = file;
      }
    }

    async function clickUpload(isSchema) {
      if (isSchema && schemaToUpload.data == null) {
        alert.warning(["Please choose GS1 Combined Published file .xml"]);
        return;
      }
      if (!isSchema && deltaToUpload.data == null) {
        alert.warning(["Please choose GS1 Combined Published Delta file .xml"]);
        return;
      }

      spinnerText.value = `Uploading ${isSchema ? schemaToUpload.name : deltaToUpload.name}...`;

      let response;
      if (isSchema) {
        response = await importCombinedPublishedXmlAsync(schemaToUpload, schemaToUpload.languageId == appLanguage.value.languageId);
      } else {
        response = await importCombinedPublishedDeltaXmlAsync(deltaToUpload, deltaToUpload.languageId == appLanguage.value.languageId);
      }

      if (response.isSuccess) {
        alert.success([`${isSchema ? schemaToUpload.name : deltaToUpload.name} imported successfully!`]);
        emit("onImport");
        if (isSchema) {
          schemaToUpload.formData = null;
          schemaToUpload.name = "";
          schemaToUpload.data = null;
        } else {
          deltaToUpload.formData = null;
          deltaToUpload.name = "";
          deltaToUpload.data = null;
        }
      } else {
        alert.error([response.message]);
      }
    }

    return {
      languages,
      selectedLanguage,
      isImportingCombinedPublishedDelta,
      isImportingCombinedPublished,
      clickUpload,
      selectFile,
      spinnerText,
      importAlert: alert.bindableAlert,
    };
  },
};
</script>

<style lang="scss" scoped>
.view-menu {
  background-color: #f4f4f4;
}
</style>
