<template>
  <div>
    <panel barColor="blue" class="mb-3">
      <div class="row text-start">
        <div class="col-2">
          <div class="input-group">
            <span class="input-group-text">Code</span>
            <input id="Code" v-model="searchCode" @keyup.enter="search" type="text" class="form-control" placeholder="10000000" autocomplete="off" />
          </div>
        </div>
        <div class="col-5">
          <div class="input-group">
            <span class="input-group-text">Title</span>
            <input id="Title" v-model="searchTitle" @keyup.enter="search" type="text" class="form-control" autocomplete="off"/>
          </div>
        </div>
        <div class="col-2">
          <div class="input-group">
            <span class="input-group-text">WR</span>
            <input id="WorkRequest" v-model="searchWr" @keyup.enter="search" type="text" class="form-control" placeholder="yy-999999" autocomplete="off" />
          </div>
        </div>
        <div class="col d-flex justify-content-between">
          <button @click="search" class="btn btn-light me-3">Search<font-awesome icon="search" class="ms-1 button-icon"></font-awesome></button>
          <button @click="close" class="btn btn-light"><font-awesome icon="times" class="button-icon"></font-awesome></button>
        </div>
      </div>
      <loader v-show="isSearching"></loader>
      <div class="row text-start mt-2" v-if="searchResults.length > 0">
        <ul class="list-group list-group-flush">
          <li v-for="(result, index) in searchResults" :key="index" class="list-group-item">
            <a href="#!" @click="goToSearchResult(result)" v-html="result.context"></a>
          </li>
        </ul>
      </div>
      <div class="row mt-2" v-if="searchResults.length == 0 && !isSearching">
          <div>No results...</div>
      </div>
    </panel>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Panel from "../shared/Panel.vue";
import Loader from "../shared/Loader.vue";
import useClassification from "../../composables/classification/useClassification";
import { nextTick } from "@vue/runtime-core";
import useLogs from '../../composables/log/useLogs';
import useUserSingleton from '../../composables/shared/useUserSingleton';

export default {
  components: { Panel, Loader },
  emits: ["onGoToSearchResult", "onClose"],
  props: {
    classifications: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    String.prototype.ignoreAccents = function() {
      return this.normalize("NFD").replace(/\p{Diacritic}/gu, "");
    };

    const { getLevelName } = useClassification();
    const {fetchLogsAsync} = useLogs();
    const {languageId} = useUserSingleton();

    const searchCode = ref("");
    const searchTitle = ref("");
    const searchWr = ref("");
    const searchResults = ref([]);

    const isSearching = ref(false);
    const isCollapsed = ref(true);

    function close() {
      isCollapsed.value = true;
      searchCode.value = "";
      searchTitle.value = "";
      searchWr.value = "";
      searchResults.value = [];

      emit("onClose");
    }

    async function search() {
      isSearching.value = true;
      await nextTick();
      const searchResultToFetch = [];

      const searchParam = {
        codes: [],
        words: [],
      };
      if (searchCode.value) {
        searchParam.codes = [searchCode.value];
      }
      if (searchTitle.value) {
        searchParam.words = [searchTitle.value.trim()];
      }
      if (searchWr.value) {
          let logsResponse = await fetchLogsAsync(languageId.value, null, searchWr.value.trim());
          if (logsResponse.isSuccess) {
              searchParam.codes = [...new Set(logsResponse.result.map(m=>m.code.toString()))];
          }
      }

      props.classifications.forEach((segment) => {
        fetchSearchedItem(searchResultToFetch, segment, searchParam);
        segment.childs.forEach((family) => {
          fetchSearchedItem(searchResultToFetch, family, searchParam, [segment]);
          family.childs.forEach((clas) => {
            fetchSearchedItem(searchResultToFetch, clas, searchParam, [segment, family]);
            clas.childs.forEach((brick) => {
              fetchSearchedItem(searchResultToFetch, brick, searchParam, [segment, family, clas]);
              brick.childs.forEach((attribute) => {
                fetchSearchedItem(searchResultToFetch, attribute, searchParam, [segment, family, clas, brick]);
                attribute.childs.forEach((attributeValue) => {
                  fetchSearchedItem(searchResultToFetch, attributeValue, searchParam, [segment, family, clas, brick, attribute]);
                });
              });
            });
          });
        });
      });

      searchResults.value = searchResultToFetch;
      await nextTick();
      isSearching.value = false;
    }

    function goToSearchResult(searchResult) {
      emit("onGoToSearchResult", searchResult);
    }

    const fetchSearchedItem = (arrayToFetch, item, searchParam, parents = []) => {
      if (searchParam.codes.length > 0 && searchParam.codes.includes(item.code.toString())) {
        arrayToFetch.push(createItemToFetch(item, parents));
      }
      if (searchParam.words.length > 0) {
        searchParam.words.forEach((word) => {
          const indexOfMatchedWord = item.title
            .ignoreAccents()
            .toLowerCase()
            .indexOf(word.ignoreAccents().toLowerCase());
          if (indexOfMatchedWord > -1) {
            arrayToFetch.push(createItemToFetch(item, parents));
          }
        });
      }
    };

    const createItemToFetch = (item, parents = []) => {
      let context = "";
      parents.forEach((p) => {
        context += `${getLevelName(p.level)}: ${p.title} (${p.code}) > `;
      });
      context += `<a href="#!" class="fw-bold">${getLevelName(item.level)}: ${item.title} (${item.code})</a>`;
      return {
        parents: parents,
        item: item,
        context: context,
      };
    };

    return {
      searchCode,
      searchTitle,
      searchWr,
      search,
      searchResults,
      close,
      isCollapsed,
      isSearching,
      goToSearchResult,
    };
  },
};
</script>

<style lang="scss" scoped>
.panel {
  & .classification-row:hover {
    & td .button-icon {
      &.small {
        cursor: pointer;
        user-select: initial;
        pointer-events: initial;
        opacity: 1;
      }
    }
  }

  & .button-icon {
    height: 15px;
    width: 15px;
    cursor: pointer;

    &.small {
      height: 17px;
      width: 17px;
    }
  }
  & .disabled {
    cursor: default;
    user-select: none;
    opacity: 0.3;
  }
}
.open-search-btn-wrapper {
  margin-bottom: -0.5rem;
}
.open-search-btn {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
</style>
