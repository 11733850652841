<template>
  <div>
    <button v-if="!isLoggedIn" @click="login" type="button" class="btn btn-login btn-primary">Sign in</button>
    <span title="Log out"><font-awesome v-if="isLoggedIn" @click="logout" icon="sign-out-alt" class="button-icon"></font-awesome></span>
    <iframe id="authFrame" width="0" height="0" ref="frameRef" :src="refreshUrl" title="SSO"></iframe>
  </div>
</template>

<script>
import { ref, watch } from "@vue/runtime-core";
import authService from "../services/authService";
import useUserSingleton from "../composables/shared/useUserSingleton";
import useLanguageSingleton from "../composables/language/useLanguageSingleton";
import useToast from "../composables/shared/useToast";

export default {
  setup() {
    const { showToast, showToastWithAction } = useToast();
    const { languages, isLoadedLangueges } = useLanguageSingleton();
    const { setupUser, clearUser, loginAsync } = useUserSingleton();

    const isLoggedIn = ref(false);
    const frameRef = ref(null);
    const refreshUrl = ref("");

    authService.setExpirationNotificationCallback(handleExpirationNotification);
    authService.authenticate(window.location.hash);
    isLoggedIn.value = authService.isAuthenticated() && authService.isAuthorized();

    function login() {
      authService.login();
    }
    function logout() {
      isLoggedIn.value = false;
      clearUser();
      authService.logout();
    }

    watch(isLoadedLangueges, () => {
      handleLogin();
    });

    async function handleLogin() {
      if (isLoadedLangueges.value && authService.isAuthenticated()) {
        if (authService.isAuthorized()) {
          const loginResponse = await loginAsync();
          if (!loginResponse.isSuccess) {
            showToast(loginResponse.message, false);
            isLoggedIn.value = false;
            logout();
            return;
          }
          isLoggedIn.value = true;
          setupUser(authService.getUser(), languages.value);
          showToast(`Signed In as ${authService.getUser().firstName} ${authService.getUser().lastName}.`);
        } else {
          isLoggedIn.value = false;
          logout();
        }
      }
    }

    function handleExpirationNotification(minutes) {
      const messageTimePart = minutes < 1 ? `in less than minute` : `in ${Math.round(minutes)} minutes`;
      showToastWithAction(`Your session will expire ${messageTimePart}. Please relogin.`, `Extend Session`, handleSilentReloginAsync);
    }

    let useManualRelogin = false
    async function handleSilentReloginAsync() {
      if (useManualRelogin || authService.isSessionExpired()) { 
        authService.login();
        return;
      }
      const silentLoginPromise = authService.silentLoginAsync();
      refreshUrl.value = authService.getSilentLoginUrl();
      let document = frameRef.value.contentDocument;
      document.open();
      document.close();
      const isSuccessLogin = await silentLoginPromise;
      if (isSuccessLogin) {
        showToast("Your session has been extended succesfully.");
      } else {
        useManualRelogin = true;
        showToastWithAction(`Something went wrong. Please relogin.`, `Relogin`, handleSilentReloginAsync);
      }
    }

    return { isLoggedIn, login, logout, frameRef, refreshUrl };
  },
};
</script>

<style scoped>
.btn-login {
  background-color: #cd3c0d;
  border-color: #cd3c0d;
}
.btn-login:hover {
  background-color: #a82c05;
  border-color: #a82c05;
}
.btn-login:active {
  background-color: #912305;
  border-color: #912305;
}
.btn-login:focus {
  box-shadow: none;
  border: 1px solid #002c6c;
}

.btn-logout {
  color: #cd3c0d;
  border-color: #cd3c0d;
}
.btn-logout:hover {
  color: white;
  background-color: #a82c05;
  border-color: #a82c05;
}
.btn-logout:active {
  color: white;
  background-color: #912305;
  border-color: #912305;
}
.btn-logout:focus {
  box-shadow: none;
  border: 1px solid #002c6c;
  color: white;
  background-color: #912305;
  border-color: #912305;
}
.button-icon {
  width: 30px;
}
</style>
