<template>
  <loader v-if="isLoadingTranslations"></loader>
  <panel v-show="!isLoadingTranslations && state.showTranslation">
    <div class="row align-items-center">
      <div class="col-4 d-flex align-items-start p-1">
        <div class="button-icon ms-2" @click="state.showTranslationMenu = !state.showTranslationMenu" title="Menu">
          <font-awesome icon="bars"></font-awesome>
        </div>
        <div class="form-check form-switch mt-1 ms-2">
          <input v-model="showAttributes" class="form-check-input" type="checkbox" id="SwitchAttributes" />
          <label class="form-check-label" for="SwitchAttributes">Include Attributes/Values</label>
        </div>
      </div>
      <div class="col-4 align-self-center panel-title p-1">Translations</div>
      <div class="col-4 d-flex align-items-center">
        <input v-model="searchLocal" id="SearchLocal" class="form-control rounded-0" type="text" placeholder="Type to search level/title/code..." />
        <div class="d-flex justify-content-end ms-2">
          <span @click="refreshTranslations">
            <font-awesome icon="sync-alt" class="button-icon"></font-awesome>
          </span>
        </div>
      </div>
      <div class="col-12">
        <translation-menu v-show="state.showTranslationMenu" @onImport="refreshTranslations"></translation-menu>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-3">
        <div class="d-flex justify-content-center">
          <div v-show="showTranslationSummary.total" class="pe-2">{{ translationSummary.total }}</div>
          <div v-show="showTranslationSummary.segments" class="px-2">{{ translationSummary.segments }}</div>
          <div v-show="showTranslationSummary.families" class="px-2">{{ translationSummary.families }}</div>
          <div v-show="showTranslationSummary.classes" class="px-2">{{ translationSummary.classes }}</div>
          <div v-show="showTranslationSummary.bricks" class="px-2">{{ translationSummary.bricks }}</div>
          <div v-show="showAttributes && showTranslationSummary.attributes" class="px-2">{{ translationSummary.attributes }}</div>
          <div v-show="showAttributes && showTranslationSummary.attributeValues" class="px-2">{{ translationSummary.attributeValues }}</div>
        </div>
        <div>
          <div v-if="false" class="tooltip-custom">
            <button
              id="push-button"
              @click="clickPushChanges"
              type="button"
              class="btn btn-push btn-primary text-white"
            >
              Push changes
              <span class="ms-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-upload" viewBox="0 0 16 16">
                  <path
                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
                  />
                  <path
                    d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"
                  />
                </svg>
              </span>
            </button>
            <span class="tooltiptext">Warning: This button can only be triggered once every 10 minutes.</span>
          </div>
          <spinner v-if="false" :text="'Pushing changes...'" class="d-flex justify-content-center"></spinner>
        </div>
      </div>
      <div class="col-12 text-start mt-2">
        <alert v-bind="bindableAlert" class="mt-3 mb-3"></alert>
        <div class="level-table table-responsive">
          <table class="table table-hover table-sm" aria-label="Translation table">
            <thead>
              <tr>
                <th scope="col" class="col-1 fw-bold">State</th>
                <th scope="col" class="col-1 fw-bold">Level</th>
                <th scope="col" class="col-8 fw-bold">Title</th>
                <th scope="col" class="col-2 fw-bold text-end pe-5">Code</th>
                <th scope="col" class="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="translation in shownTranslations"
                :key="translation.code"
                @dblclick="doubleClickTranslation(translation)"
                class="translation-row"
              >
                <td :class="getLogStatesClass(translation.states)">{{ getLogStatesLabel(translation.states) }}</td>
                <td>{{ getLevelName(translation.level) }}</td>
                <td>{{ translation.from.title }}</td>
                <td class="text-end">{{ translation.from.code }}</td>
                <td @click="clickEdit(translation)" class="fw-bold tex-end">
                  <font-awesome icon="pencil-alt" class="small button-icon"></font-awesome>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </panel>
  <translation-classification
    v-if="state.showTranslationClassification"
    @onSave="handleSaveTranslation"
    @onClose="handleCloseTranslation"
    :itemToTranslate="itemToTranslate"
  ></translation-classification>
</template>

<script>
import TranslationClassification from "../components/translation/TranslationClassification";
import TranslationMenu from "../components/translation/TranslationMenu";
import useTranslation from "../composables/translation/useTranslation";
import Loader from "../components/shared/Loader.vue";
import Panel from "../components/shared/Panel.vue";
import { reactive, ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import useUserSingleton from "../composables/shared/useUserSingleton";
import useAlert from "../composables/shared/useAlert";
import useLogs from "../composables/log/useLogs";
import gpcEnumRepository from "../repositories/gpcEnumRepository";
import { downloadXlsx } from "../utils/fileUtil";
import useClassification from "../composables/classification/useClassification";
import Alert from "../components/shared/Alert.vue";
import useDebouncedRef from "../composables/shared/useDebouncedRef";
import useBlob from "../composables/shared/useBlob";
import Spinner from "../components/shared/Spinner.vue";

export default {
  components: { TranslationClassification, Loader, Panel, TranslationMenu, Alert, Spinner },
  setup() {
    const { user, isMainLanguage } = useUserSingleton();
    const alert = useAlert();
    const {
      isLoadingTranslations,
      isExportingXlsx,
      translations,
      fetchTranslationsAsync,
      exportAsXlsxAsync,
      removeTranslationFromList,
    } = useTranslation();
    const { getLogStatesLabel, getLogStatesClass } = useLogs();
    const { getLevelName, isAttribute, isAttributeValue } = useClassification();
    const { uploadLatestBlobForLanguageAsync, isUploadingForLanguage } = useBlob();

    const state = reactive({
      showTranslation: true,
      showTranslationClassification: false,
      showTranslationMenu: false,
    });

    const searchLocal = useDebouncedRef("", 800);
    const showAttributes = ref(user.value.language.includeAttributeAndValues);
    const itemToTranslate = ref({});

    const shownTranslations = computed(() => {
      let itemsToshow;
      if (!showAttributes.value) {
        itemsToshow = translations.value.filter((f) => !(isAttribute(f) || isAttributeValue(f)));
      } else {
        itemsToshow = translations.value;
      }

      if (searchLocal.value === "") {
        return sortItems(itemsToshow);
      }
      return sortItems(itemsToshow).filter(
        (item) =>
          getLevelName(item.from.level)
            .toLowerCase()
            .includes(searchLocal.value.toLowerCase()) ||
          item.from.title.toLowerCase().includes(searchLocal.value.toLowerCase()) ||
          item.from.code.toString().includes(searchLocal.value.toLowerCase())
      );
    });

    const sortItems = (items) => {
      return items.sort((a, b) => a.level - b.level);
    };

    const showTranslationSummary = reactive({
      total: computed(() => translations.value.length > 0),
      segments: computed(() => translations.value.filter((f) => f.level == gpcEnumRepository.ClassificationLevel.Segment).length > 0),
      families: computed(() => translations.value.filter((f) => f.level == gpcEnumRepository.ClassificationLevel.Family).length > 0),
      classes: computed(() => translations.value.filter((f) => f.level == gpcEnumRepository.ClassificationLevel.Class).length > 0),
      bricks: computed(() => translations.value.filter((f) => f.level == gpcEnumRepository.ClassificationLevel.Brick).length > 0),
      attributes: computed(() => translations.value.filter((f) => f.level == gpcEnumRepository.ClassificationLevel.Attribute).length > 0),
      attributeValues: computed(() => translations.value.filter((f) => f.level == gpcEnumRepository.ClassificationLevel.AttributeValue).length > 0),
    });

    const translationSummary = reactive({
      total: computed(() => `Total(${translations.value.length})`),
      segments: computed(() => `Segments(${translations.value.filter((f) => f.level == gpcEnumRepository.ClassificationLevel.Segment).length})`),
      families: computed(() => `Families(${translations.value.filter((f) => f.level == gpcEnumRepository.ClassificationLevel.Family).length})`),
      classes: computed(() => `Classes(${translations.value.filter((f) => f.level == gpcEnumRepository.ClassificationLevel.Class).length})`),
      bricks: computed(() => `Bricks(${translations.value.filter((f) => f.level == gpcEnumRepository.ClassificationLevel.Brick).length})`),
      attributes: computed(
        () => `Attributes(${translations.value.filter((f) => f.level == gpcEnumRepository.ClassificationLevel.Attribute).length})`
      ),
      attributeValues: computed(
        () => `AttributeValues(${translations.value.filter((f) => f.level == gpcEnumRepository.ClassificationLevel.AttributeValue).length})`
      ),
    });

    onMounted(async () => {
      if (!isMainLanguage.value) {
        const response = await fetchTranslationsAsync(user.value.language.languageId);
        if (!response.isSuccess) {
          alert.error([response.message]);
        }
      }
    });

    function handleSaveTranslation(code) {
      removeTranslationFromList(code);
    }

    function handleCloseTranslation() {
      state.showTranslation = true;
      state.showTranslationClassification = false;
    }

    function refreshTranslations() {
      fetchTranslationsAsync(user.value.language.languageId);
    }

    function clickEdit(translation) {
      itemToTranslate.value = translation;
      state.showTranslation = false;
      state.showTranslationClassification = true;
    }

    async function clickExportExcel() {
      if (isExportingXlsx.value) {
        return;
      }
      let data = await exportAsXlsxAsync(user.value.language.languageId);
      const fileName = `GPC Translation sheet ${new Date().toISOString().substring(0, 10)} ${user.value.language.languageCode.toUpperCase()}.xlsx`;
      downloadXlsx(fileName, data);
    }

    async function clickPushChanges() {
      if (!isUploadingForLanguage.value) {
        const response = await uploadLatestBlobForLanguageAsync(user.value.language.languageId);
        if (!response.isSuccess) {
          alert.error([response.message]);
        } else {
          alert.success(["Successfully pushed changes."]);
        }
      }
    }

    return {
      state,
      isLoadingTranslations,
      isExportingXlsx,
      shownTranslations,
      showAttributes,
      searchLocal,
      bindableAlert: alert.bindableAlert,
      refreshTranslations,
      clickEdit,
      clickExportExcel,
      getLogStatesLabel,
      getLogStatesClass,
      translationSummary,
      showTranslationSummary,
      getLevelName,
      handleSaveTranslation,
      handleCloseTranslation,
      itemToTranslate,
      clickPushChanges,
      isUploadingForLanguage,
    };
  },
};
</script>

<style lang="scss" scoped>
.panel {
  & .translation-row:hover {
    & td .button-icon {
      &.small {
        cursor: pointer;
        user-select: initial;
        pointer-events: initial;
        opacity: 1;
      }
    }
  }

  & .button-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;

    &.small {
      height: 17px;
      width: 17px;
      user-select: none;
      pointer-events: none;
      opacity: 0;
    }
  }
  & .disabled {
    cursor: default;
    user-select: none;
    opacity: 0.3;
  }
}

.btn-push {
  background-color: #cd3c0d;
  border-color: #cd3c0d;
}
.btn-push:hover {
  background-color: #a82c05;
  border-color: #a82c05;
}
.btn-push:active {
  background-color: #912305;
  border-color: #912305;
}
.btn-push:focus {
  box-shadow: none;
  border: 1px solid #002c6c;
}

/* Tooltip container */
.tooltip-custom {
  position: relative;
  display: inline-block;
}

.tooltip-custom .tooltiptext {
  visibility: hidden;
    width: 250px;
    background-color: wheat;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 130%;
    left: 20%;
    margin-left: -60px;
}

.tooltip-custom .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 85%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent wheat;
}
.tooltip-custom:hover .tooltiptext {
  visibility: visible;
}
</style>
