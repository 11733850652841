<template>
  <loader v-if="isGettingDetailedRelations" />
  <panel v-show="relations.length > 0" barColor="blue" class="mb-3">
    <div class="row pb-2">
      <div class="col-2 col-sm-2 col-md-4 d-flex align-items-start"></div>
      <div class="col-3 col-sm-4 align-self-center panel-title">Relations</div>
      <div class="col-2 d-none d-sm-none d-md-none d-lg-none d-xl-block"></div>
      <div class="col d-flex justify-content-end"></div>
    </div>
    <div class="row">
      <div class="logs-table text-start table-responsive">
        <table class="table table-hover" aria-label="Classification relations">
          <thead>
            <tr>
              <th scope="col" class="fw-bold">Brick</th>
              <th scope="col"></th>
              <th scope="col" class="fw-bold">Attribute</th>
              <th scope="col"></th>
              <th scope="col" class="fw-bold">Attribute Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(relation, index) in relations" :key="index">
              <td class="col-4">{{ relation.brick.title }} ({{ relation.brick.code }})</td>
              <td><font-awesome icon="long-arrow-alt-right" class="hw-20"></font-awesome></td>
              <td class="col-4">{{ relation.attribute.title }} ({{ relation.attribute.code }})</td>
              <td><font-awesome icon="long-arrow-alt-right" class="hw-20"></font-awesome></td>
              <td class="col-4">{{ relation.attributeValue.title }} ({{ relation.attributeValue.code }})</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <alert v-bind="bindableAlert" class="mt-3" />
  </panel>
</template>

<script>
import useAlert from "../../composables/shared/useAlert";
import Loader from "../shared/Loader.vue";
import Panel from "../shared/Panel.vue";
import Alert from "../shared/Alert.vue";
import { onMounted, ref } from "vue";
import useClassification from "../../composables/classification/useClassification";

export default {
  components: { Panel, Loader, Alert },
  props: {
    languageId: {
      type: Number,
      required: true,
    },
    code: {
      type: Number,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const alert = useAlert();
    const { isGettingDetailedRelations, getDetailedRelationsAsync } = useClassification();

    const relations = ref([]);

    onMounted(async () => {
      let response = await getDetailedRelationsAsync(props.languageId, props.code, props.level);
      if (response.isSuccess) {
        relations.value = response.result;
      } else {
        alert.error([response.message]);
      }
    });

    return { isGettingDetailedRelations, relations, bindableAlert: alert.bindableAlert };
  },
};
</script>
