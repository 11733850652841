<template>
  <div>
    <div class="card border-primary">
      <div class="card-header text-center bg-primary text-white">{{ cardTitle }}</div>
      <div class="card-body text-dark">
        <div class="mb-2 d-flex align-items-center">
          <div class="col-10">
            <input v-model="search" class="form-control rounded-0" type="text" placeholder="Type to search title or code..." autocomplete="off" />
          </div>
          <div v-if="itemsToShow.length > 0" class="col-2">
            <button @click="clickAddAll" class="btn btn-sm btn-primary text-white float-end" title="Add item">Add All</button>
          </div>
        </div>
        <div class="link-table table-responsive">
          <table class="table table-hover table-sm" aria-label="Relation table">
            <thead>
              <tr>
                <th scope="col" class="col-1"></th>
                <th scope="col" class="col-11"></th>
                <th scope="col" class="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in itemsToShow" :key="item.code" class="link-item">
                <td :class="{'fw-bold': item.isActive, 'text-black-50': !item.isActive}">{{ item.code }}</td>
                <td class="item-title" :class="{'text-black-50': !item.isActive}">{{ item.title }}</td>
                <td class=" button-icon small">
                  <button @click="clickAdd(item)" class="btn btn-sm btn-primary text-white" title="Add item">Add</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import useDebouncedRef from '../../composables/shared/useDebouncedRef';

export default {
  emits: ["onInspect", "onAdd"],
  props: {
    title: String,
    items: Array,
    itemsToSearchIn: Array,
    itemsInitSearch: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const cardTitle = ref(props.title);
    const search = useDebouncedRef("", 800);

    const itemsToShow = computed(() => {
      if (search.value === "") {
        return sortItems(props.itemsInitSearch);
      }
      return sortItems(props.itemsToSearchIn).filter((item) => item.title.toLowerCase().includes(search.value.toLowerCase()) || item.code.toString().includes(search.value.toLowerCase()));
    });

    const sortItems = (items) => {
      return items.sort((a, b) => {
        {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          if (textA < textB) {
            return -1;
          }
          return textA > textB ? 1 : 0;
        }
      });
    };

    function clickAdd(item) {
      emit("onAdd", item);
    }

    function clickAddAll() {
      itemsToShow.value.forEach(item => {
        clickAdd(item);
      });
    }

    function clickInspect(item) {
      emit("onInspect", item);
    }

    return {
      cardTitle,
      search,
      itemsToShow,
      clickAdd,
      clickAddAll,
      clickInspect,
    };
  },
};
</script>
<style lang="scss" scoped>
.link-table {
  max-height: 200px;
  & .button-icon {
    opacity: 0;
    width: auto;
    height: auto;

    &.small {
      & svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
.link-item {
  &:hover {
    & .button-icon {
      opacity: 1;
    }
  }
  & .item-title {
    max-width: 200px !important;
  }
}
svg.action-icon:hover {
  & > path {
    color: #0d6efd !important;
  }
}
</style>
