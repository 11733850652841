import { computed, ref } from "vue";
import gpcTranslationRepository from "../../repositories/gpcTranslationRepository";

export default function useTranslation() {
  const translations = ref([]);

  const isLoadingTranslations = ref(false);
  const isExportingXlsx = ref(false);
  const isImportingXlsx = ref(false);
  const isTranslatingText = ref(false);

  async function fetchTranslationsAsync(languageId) {
    isLoadingTranslations.value = true;
    const response = await gpcTranslationRepository.getTranslationsAsync(languageId);
    translations.value = response.result;
    isLoadingTranslations.value = false;
    return response;
  }

  async function translateTextAsync(fromLanguageCode, toLanguageCode, text) {
    isTranslatingText.value = true;
    const response = await gpcTranslationRepository.translateTextAsync(fromLanguageCode, toLanguageCode, text);
    isTranslatingText.value = false;
    return response.result;
  }

  async function importTranslationAsync(toImport) {
    isImportingXlsx.value = true;
    let response = await gpcTranslationRepository.importTranslationAsync(toImport);
    isImportingXlsx.value = false;
    return response;
  }

  async function exportAsXlsxAsync(languageId) {
    isExportingXlsx.value = true;
    let response = await gpcTranslationRepository.exportAsXlsxAsync(languageId);
    isExportingXlsx.value = false;
    return response;
  }

  function removeTranslationFromList(code) {
    translations.value = translations.value.filter((f) => f.from.code != code);
  }

  return {
    isLoadingTranslations: computed(() => isLoadingTranslations.value),
    isExportingXlsx: computed(() => isExportingXlsx.value),
    isImportingXlsx: computed(() => isImportingXlsx.value),
    isTranslatingText: computed(() => isTranslatingText.value),
    translations: computed(() => translations.value),
    fetchTranslationsAsync,
    translateTextAsync,
    importTranslationAsync,
    exportAsXlsxAsync,
    removeTranslationFromList,
  };
}
