<template>
  <panel v-if="isLogPanelVisible" barColor="sky">
    <div class="row pb-2">
      <div class="col-2 col-sm-2 col-md-4 d-flex align-items-start"></div>
      <div class="col-3 col-sm-4 align-self-center panel-title">Logs</div>
      <div class="col-2 d-none d-sm-none d-md-none d-lg-none d-xl-block"></div>
      <div class="col d-flex justify-content-end"></div>
    </div>
    <div class="row">
      <div class="logs-table text-start table-responsive">
        <table class="table table-hover" aria-label="Classification logs">
          <thead>
            <tr>
              <th scope="col" class="fw-bold">Date UTC</th>
              <th scope="col" class="fw-bold">Title</th>
              <th scope="col" class="fw-bold">Definition</th>
              <th v-if="hasDefinitionExcludes" scope="col" class="fw-bold">Definition Excludes</th>
              <th scope="col" class="fw-bold">State</th>
              <th scope="col" class="fw-bold">Comment</th>
              <th scope="col" class="fw-bold">WR</th>
              <th scope="col" class="fw-bold">User</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(log, index) in mappedLogs" :key="index">
              <td>{{ log.insertDate }}</td>
              <td>{{ log.title }}</td>
              <td>{{ log.definition }}</td>
              <td v-if="hasDefinitionExcludes">{{ log.definitionExcludes }}</td>
              <td>{{ log.state }}</td>
              <td>{{ log.comment }}</td>
              <td>{{ log.workRequest }}</td>
              <td>{{ log.userName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="isFetchingLogs">
      <loader />
    </div>
    <alert v-bind="bindableAlert" class="mt-3" />
  </panel>
</template>

<script>
import useAlert from "../../composables/shared/useAlert";
import Loader from "../shared/Loader.vue";
import Panel from "../shared/Panel.vue";
import Alert from "../shared/Alert.vue";
import useLogs from "../../composables/log/useLogs";
import { computed, onMounted, watchEffect } from "vue";

export default {
  components: { Panel, Loader, Alert },
  props: {
    languageId: {
      type: Number,
      required: true,
    },
    code: {
      type: Number,
      required: true,
    },
    refresh: {
      type: [Boolean, String],
      default: false,
    },
  },
  setup(props) {
    const alert = useAlert();
    const { isFetchingLogs, logs, getLogStateName, fetchLogsAsync } = useLogs();

    const mappedLogs = computed(() => {
      return logs.value.map((item) => {
        item.insertDate = new Date(item.lastUpdate).toLocaleString();
        item.state = getLogStateName(item.state);
        return item;
      });
    });

    const hasDefinitionExcludes = computed(() => {
      let logWithDefinitionExcludes = mappedLogs.value.find((f) => f.definitionExcludes != null && f.definitionExcludes != "");
      return logWithDefinitionExcludes != null;
    });

    const isLogPanelVisible = computed(() => {
      return logs.value.length > 0;
    });

    onMounted(() => {
      fetchLogsAsync(props.languageId, props.code);
    });

    watchEffect(() => {
      if (props.refresh || props.code) {
        fetchLogsAsync(props.languageId, props.code);
      }
    });

    return { isLogPanelVisible, isFetchingLogs, mappedLogs, hasDefinitionExcludes, bindableAlert: alert.bindableAlert };
  },
};
</script>
