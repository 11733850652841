<template>
  <div class="row">
    <div class="col-12 text-start mt-3">
      <h1>GS1 Global Product Classification (GPC) Tool</h1>
      <p>Welcome to the GS1 GPC Tool!</p>
      <p>Oxford English is the reference language for all GS1 standards including GPC.</p>
      <p>
        To support Global Product Classification adoption around the world, GS1 Global Office (GO) can host and support Member Organisation (MO) representatives who provide GPC translations through
        the “GPC Tool”.
      </p>
      <p>This tool will allow your Organisation to:</p>
      <ul>
        <li>Provide an online translation of GPC for your users</li>
        <li>Publish the GPC Standard in your language</li>
        <li>Publish to the GPC Browser in your language</li>
      </ul>
      <p>Translated versions of GPC can be also be accessed by the public via the GPC Browser, along with the official Oxford English version.</p>
      <p v-if="isAutheticated">Access the following training and guidance materials for the GS1 MO Translation Tool <a class="fw-bold" href="https://mozone.gs1.org/gpc" rel="noopener" target="_blank">HERE</a></p>
      <ul v-if="isAutheticated">
        <li>Recorded session of GPC Translation Tool training</li>
        <li>GPC Tool MO Translation Administration Guide</li>
      </ul>
    </div>
  </div>
</template>

<script>
import useUserSingleton from '../composables/shared/useUserSingleton';
export default {
  setup() {
    const {isAdmin, isAutheticated} = useUserSingleton();
    return {isAdmin ,isAutheticated};
  },
};
</script>
