import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import { FontAwesomeIcon } from "@/utils/fontAwesomeUtil";

createApp(App)
.use(router)
.component("FontAwesome", FontAwesomeIcon)
.mount('#app');
