import { computed, reactive, ref, watch } from "vue";
import gpcEnumRepository from "../../repositories/gpcEnumRepository";
import gpcClassificationRepository from "../../repositories/gpcClassificationRepository";

export default function useSchemaClassification() {
  let classificationToEdit;
  let languageId = 0;

  const titleToShow = ref("");
  const editableProperties = reactive({
    code: "",
    title: "",
    definition: "",
    definitionExcludes: "",
    workRequest: "",
    comment: "",
    isDeleted: false,
  });

  const state = reactive({
    isNew: false,
    isSaving: false,
  });

  const validationErrorMessage = reactive({
    title: "",
    code: "",
    workRequest: "",
  });

  watch(
    () => editableProperties.isDeleted,
    () => {
      syncEditableProperties(false);
    }
  );

  const isDirty = () => {
    if (state.isNew) {
      return true;
    }

    return (
      editableProperties.code != classificationToEdit.code ||
      editableProperties.title != classificationToEdit.title ||
      editableProperties.definition != classificationToEdit.definition ||
      editableProperties.definitionExcludes != classificationToEdit.definitionExcludes ||
      editableProperties.isDeleted != !classificationToEdit.isActive ||
      editableProperties.comment != ""
    );
  };

  const validation = reactive({
    isInvalidTitle: computed(() => {
      if (editableProperties.title == null || editableProperties.title == "") {
        validationErrorMessage.title = "Please enter Title";
        return true;
      }
      if (editableProperties.title.length > 500) {
        validationErrorMessage.title = "Title length should be less than 500 characters";
        return true;
      }
      return false;
    }),
    isInvalidCode: computed(() => {
      if (editableProperties.code == null || editableProperties.code == 0 || editableProperties.code == "") {
        validationErrorMessage.code = "Please enter Code";
        return true;
      }
      if (editableProperties.code.toString().startsWith("0")) {
        validationErrorMessage.code = "Invalid code";
        return true;
      }
      if (editableProperties.code < 10000000 || editableProperties.code > 99999999) {
        validationErrorMessage.code = "Code must be between 10000000 to 99999999";
        return true;
      }
      return false;
    }),
    isInvalidWorkRequest: computed(() => {
      const regex = /^\d{2}-\d{6}$/gm; // nn-nnnnnn
      if (editableProperties.workRequest == null || editableProperties.workRequest == "") {
        validationErrorMessage.workRequest = "Please enter WR";
        return true;
      }
      if (!editableProperties.workRequest.match(regex)) {
        validationErrorMessage.workRequest = "WR must match YY-NNNNNN";
        return true;
      }
      return false;
    }),
  });

  function init(item, isNew = false) {
    state.isNew = isNew;

    languageId = item.languageId;
    titleToShow.value = Object.keys(gpcEnumRepository.ClassificationLevel).find((key) => gpcEnumRepository.ClassificationLevel[key] == item.level);

    if (isNew) {
      classificationToEdit = {
        code: "",
        definition: "",
        definitionExcludes: "",
        insertDate: null,
        lastUpdate: null,
        isActive: true,
        languageId: 1,
        level: 0,
        order: 0,
        parentCode: null,
        title: null,
        childs: [],
      };

      classificationToEdit.parentCode = item.parentCode;
      classificationToEdit.level = item.level;
      classificationToEdit.languageId = item.languageId;

      editableProperties.isDeleted = false;
    } else {
      classificationToEdit = item;
      syncEditableProperties();
    }
  }

  function syncEditableProperties(includeDeletion = true) {
    editableProperties.code = classificationToEdit.code;
    editableProperties.title = classificationToEdit.title;
    editableProperties.definition = classificationToEdit.definition;
    editableProperties.definitionExcludes = classificationToEdit.definitionExcludes;
    if (includeDeletion) {
      editableProperties.isDeleted = !classificationToEdit.isActive;
      editableProperties.comment = "";
    }
  }

  async function saveAsync(isMainLanguage = true) {
    if (editableProperties.code == 0 || editableProperties.code == null || editableProperties.title == null || editableProperties.title == "") {
      return { isSuccess: false, message: "Code and Title are required." };
    }
    state.isSaving = true;
    let properties = getModifiedProperties();
    let log = getLogBasedOnChanges(properties, isMainLanguage);
    let response;
    if (log.state == gpcEnumRepository.ClassificationState.Removed) {
      response = await gpcClassificationRepository.removeClassificationAsync(
        classificationToEdit.code,
        classificationToEdit.level,
        classificationToEdit.languageId,
        editableProperties.workRequest,
        editableProperties.comment
      );
    } else {
      const classificationToSave = Object.assign({}, classificationToEdit);
      classificationToSave.code = editableProperties.code;
      classificationToSave.title =
        classificationToEdit.level == gpcEnumRepository.ClassificationLevel.AttributeValue
          ? editableProperties.title.toUpperCase()
          : editableProperties.title;
      classificationToSave.definition = editableProperties.definition;
      classificationToSave.definitionExcludes = editableProperties.definitionExcludes;
      classificationToSave.childs = [];
      response = await gpcClassificationRepository.saveClassificationAsync(log, classificationToSave);
    }

    response.result = classificationToEdit;

    if (response.isSuccess) {
      classificationToEdit.code = editableProperties.code;
      classificationToEdit.title =
        classificationToEdit.level == gpcEnumRepository.ClassificationLevel.AttributeValue
          ? editableProperties.title.toUpperCase()
          : editableProperties.title;
      classificationToEdit.definition = editableProperties.definition;
      classificationToEdit.definitionExcludes = editableProperties.definitionExcludes;

      state.isNew = false;
      syncEditableProperties();
    }
    state.isSaving = false;
    return response;
  }

  function getModifiedProperties() {
    let properties = [];
    if (editableProperties.title != classificationToEdit.title) {
      properties.push(gpcEnumRepository.ClassificationProperty.Title);
    }
    if (editableProperties.definition != classificationToEdit.definition) {
      properties.push(gpcEnumRepository.ClassificationProperty.Definition);
    }
    if (editableProperties.definitionExcludes != classificationToEdit.definitionExcludes) {
      properties.push(gpcEnumRepository.ClassificationProperty.DefinitionExcludes);
    }
    if (properties.length == 0) {
      properties.push(gpcEnumRepository.ClassificationProperty.None);
    }
    return properties;
  }

  function getLogBasedOnChanges(properties, isMainLanguage = true) {
    let log;
    if (editableProperties.isDeleted == classificationToEdit.isActive) {
      if (editableProperties.isDeleted) {
        log = {
          level: classificationToEdit.level,
          state: gpcEnumRepository.ClassificationState.Removed,
          properties: [gpcEnumRepository.ClassificationProperty.None],
          workRequest: editableProperties.workRequest,
          comment: editableProperties.comment,
        };
        classificationToEdit.isActive = false;
        syncEditableProperties(false);
      } else {
        log = {
          level: classificationToEdit.level,
          state: gpcEnumRepository.ClassificationState.Updated,
          properties: [gpcEnumRepository.ClassificationProperty.None],
          workRequest: editableProperties.workRequest,
          comment: editableProperties.comment,
        };
        classificationToEdit.isActive = true;
      }
    } else {
      log = {
        level: classificationToEdit.level,
        workRequest: editableProperties.workRequest,
        comment: editableProperties.comment,
      };

      if (state.isNew) {
        log.state = gpcEnumRepository.ClassificationState.Added;
      } else {
        log.state = gpcEnumRepository.ClassificationState.Updated;
      }

      log.properties = properties;
      log.languageId = languageId;
      log.date = new Date();
    }

    if (log.properties.length <= 0) {
      state.isSaving = false;
      return;
    }

    if (!isMainLanguage) {
      log.state = gpcEnumRepository.ClassificationState.Translated;
    }

    return log;
  }

  async function setNewCodeAsync() {
    if (state.isNew) {
      const codeResponse = await gpcClassificationRepository.getNewCodeAsync(
        classificationToEdit.parentCode,
        classificationToEdit.level,
        classificationToEdit.languageId
      );
      editableProperties.code = codeResponse.result;
    }
  }

  return {
    state,
    titleToShow: computed(() => titleToShow.value),
    editableProperties,
    isDirty,
    validation,
    validationErrorMessage,
    init,
    saveAsync,
    setNewCodeAsync,
  };
}
