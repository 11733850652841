<template>
  <panel>
    <div class="row">
      <div class="col-2 col-sm-2 col-md-4 d-flex align-items-start">
        <div class="button-icon" @click="close">
          <font-awesome icon="arrow-circle-left"></font-awesome>
        </div>
      </div>
      <div class="col-3 col-sm-4 align-self-center panel-title">Translation {{ translateToLabel }}</div>
      <div class="col-1 d-none d-sm-none d-md-none d-lg-none d-xl-block"></div>
      <div class="col d-flex justify-content-end">
        <div class="input-group">
          <span class="input-group-text rounded-0" id="Code">{{ getLevelName(translation.level) }}</span>
          <input v-model="translation.from.code" type="text" class="form-control rounded-0 text-center" disabled />
        </div>
      </div>
    </div>
    <div class="row text-start mt-3 mb-3">
      <div class="col">{{ translation.context }}</div>
    </div>
    <div class="row text-start mt-2">
      <div class="col mb-3">
        <label for="TitleFrom" class="form-label">Title {{ translateFromLabel }}</label>
        <input id="TitleFrom" v-model="translation.from.title" type="text" class="form-control rounded-0" disabled />
      </div>
      <div v-if="translationApiResult.title != ''" class="col-6 mb-3">
        <font-awesome icon="clone" class="copy-translation-btn" @click="translatedProperties.title = translationApiResult.title"></font-awesome>
        <label for="TitleTranslatedByApi" class="form-label">Title {{ translateToLabel }}(online translation)</label>
        <input id="TitleTranslatedByApi" v-model="translationApiResult.title" type="text" class="form-control rounded-0" disabled />
      </div>
      <div class="col-12 mb-3">
        <label for="TitleTo" class="form-label">Title {{ translateToLabel }}</label>
        <input
          id="TitleTo"
          v-model="translatedProperties.title"
          :class="{ 'is-invalid': propertiesToTranslate.title, 'is-valid': !propertiesToTranslate.title }"
          type="text"
          class="form-control rounded-0"
          autocomplete="off"
        />
        <div class="valid-feedback">
          No changes
        </div>
        <div class="invalid-feedback">
          Modified
        </div>
      </div>
      <div v-if="!isBrick" class="col mb-3">
        <label for="DefinitionFrom" class="form-label">Definition {{ translateFromLabel }}</label>
        <textarea id="DefinitionFrom" v-model="translation.from.definition" class="form-control rounded-0" rows="7" disabled></textarea>
      </div>
      <div v-if="!isBrick && translationApiResult.definition != ''" class="col-6 mb-3">
        <font-awesome
          icon="clone"
          class="copy-translation-btn"
          @click="translatedProperties.definition = translationApiResult.definition"
        ></font-awesome>
        <label for="DefinitionTranslatedByApi" class="form-label">Definition {{ translateToLabel }}(online translation)</label>
        <textarea
          id="DefinitionTranslatedByApi"
          v-model="translationApiResult.definition"
          class="form-control rounded-0"
          rows="7"
          disabled
        ></textarea>
      </div>
      <div v-if="!isBrick" class="col-12 mb-3">
        <label for="DefinitionTo" class="form-label">Definition {{ translateToLabel }}</label>
        <textarea
          id="DefinitionTo"
          v-model="translatedProperties.definition"
          :class="{ 'is-invalid': propertiesToTranslate.definition, 'is-valid': !propertiesToTranslate.definition }"
          class="form-control rounded-0"
          rows="5"
          autocomplete="off"
        ></textarea>
        <div class="valid-feedback">
          No changes
        </div>
        <div class="invalid-feedback">
          Modified
        </div>
      </div>
      <div v-if="isBrick" class="col-6 mb-3">
        <label for="IncludesFrom" class="form-label">Definition Includes {{ translateFromLabel }}</label>
        <textarea id="IncludesFrom" v-model="translation.from.definition" class="form-control rounded-0" rows="6" disabled></textarea>
      </div>
      <div v-if="isBrick" class="col-6 mb-3">
        <font-awesome
          icon="clone"
          class="copy-translation-btn"
          @click="translatedProperties.definition = translationApiResult.definition"
        ></font-awesome>
        <label for="IncludesTranslatedByApi" class="form-label">Definition Includes {{ translateToLabel }}(online translation)</label>
        <textarea id="IncludesTranslatedByApi" v-model="translationApiResult.definition" class="form-control rounded-0" rows="6" disabled></textarea>
      </div>
      <div v-if="isBrick" class="mb-3">
        <label for="IncludesTo" class="form-label">Definition Includes {{ translateToLabel }}</label>
        <textarea
          id="IncludesTo"
          v-model="translatedProperties.definition"
          :class="{ 'is-invalid': propertiesToTranslate.definition, 'is-valid': !propertiesToTranslate.definition }"
          class="form-control rounded-0"
          rows="4"
          autocomplete="off"
        ></textarea>
        <div class="valid-feedback">
          No changes
        </div>
        <div class="invalid-feedback">
          Modified
        </div>
      </div>
      <div v-if="isBrick" class="col-6 mb-3">
        <label for="ExcludesFrom" class="form-label">Definition Excludes {{ translateFromLabel }}</label>
        <textarea id="ExcludesFrom" v-model="translation.from.definitionExcludes" class="form-control rounded-0" rows="5" disabled></textarea>
      </div>
      <div v-if="isBrick" class="col-6 mb-3">
        <font-awesome
          icon="clone"
          class="copy-translation-btn"
          @click="translatedProperties.definitionExcludes = translationApiResult.definitionExcludes"
        ></font-awesome>
        <label for="ExcludesTranslatedByApi" class="form-label">Definition Excludes {{ translateToLabel }}(online translation)</label>
        <textarea
          id="ExcludesTranslatedByApi"
          v-model="translationApiResult.definitionExcludes"
          class="form-control rounded-0"
          rows="5"
          disabled
        ></textarea>
      </div>
      <div v-if="isBrick" class="mb-3">
        <label for="ExcludesTo" class="form-label">Definition Excludes {{ translateToLabel }}</label>
        <textarea
          id="ExcludesTo"
          v-model="translatedProperties.definitionExcludes"
          :class="{ 'is-invalid': propertiesToTranslate.definitionExcludes, 'is-valid': !propertiesToTranslate.definitionExcludes }"
          class="form-control rounded-0"
          rows="3"
          autocomplete="off"
        ></textarea>
        <div class="valid-feedback">
          No changes
        </div>
        <div class="invalid-feedback">
          Modified
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 d-flex justify-content-end">
        <div class="button-icon" @click="saveTranslation" :class="{ disabled: !isDirty() }">
          <font-awesome v-if="!actionState.isSaving" icon="save"></font-awesome>
          <font-awesome v-if="actionState.isSaving" icon="spinner" class="fa-spinner"></font-awesome>
        </div>
      </div>
    </div>
    <alert v-bind="bindableAlert" class="mt-3" />
  </panel>
  <logs :languageId="languageId" :code="itemCode" :key="refreshLogs"></logs>
  <relations
    v-if="isAttribute || isAttributeValue"
    :languageId="languageId"
    :code="itemToTranslate.from.code"
    :level="itemToTranslate.from.level"
  ></relations>
</template>

<script>
import { computed, onMounted, reactive, ref } from "vue";
import useAlert from "../../composables/shared/useAlert";
import useUser from "../../composables/shared/useUserSingleton";
import useTranslationClassification from "../../composables/translation/useTranslationClassification";
import Alert from "../shared/Alert.vue";
import Panel from "../shared/Panel.vue";
import Logs from "../shared/Logs.vue";
import Relations from "../shared/Relations.vue";
import useTranslation from "../../composables/translation/useTranslation";
import useClassification from "../../composables/classification/useClassification";
import useLanguageSingleton from "../../composables/language/useLanguageSingleton";
import useUnsavedChanges from "../../composables/shared/useUnsavedChanges";

export default {
  components: { Panel, Alert, Logs, Relations },
  emits: ["onSave", "onClose"],
  props: {
    itemToTranslate: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { user, languageId } = useUser();
    const alert = useAlert();
    const { appLanguage } = useLanguageSingleton();
    const { isBrick: isBrickLevel, isAttribute: isAttributeLevel, isAttributeValue: isAttributeValueLevel, getLevelName } = useClassification();
    const { translateTextAsync } = useTranslation();
    const { actionState, translation, translatedProperties, saveAsync, propertiesToTranslate, isDirty } = useTranslationClassification();
    const { acceptUnsavedChanges } = useUnsavedChanges();

    const translationApiResult = reactive({
      title: "Translating...",
      definition: "Translating...",
      definitionExcludes: "Translating...",
    });

    const translateFromLabel = computed(() => appLanguage.value.languageCode);
    const translateToLabel = computed(() => user.value.language.languageCode);
    const isBrick = computed(() => isBrickLevel(translation.value));
    const isAttribute = computed(() => isAttributeLevel(translation.value));
    const isAttributeValue = computed(() => isAttributeValueLevel(translation.value));
    const refreshLogs = ref(0);
    const itemCode = ref(0);

    onMounted(async () => {
      itemCode.value = props.itemToTranslate.from.code;
      translation.value.from = props.itemToTranslate.from;
      translation.value.to = props.itemToTranslate.to;
      translation.value.properties = props.itemToTranslate.propertiesToTranslate;
      translation.value.states = [];
      translation.value.level = props.itemToTranslate.level;
      translation.value.context = props.itemToTranslate.context;

      if (translation.value.to != null) {
        translatedProperties.value.title = translation.value.to.title;
        translatedProperties.value.definition = translation.value.to.definition;
        translatedProperties.value.definitionExcludes = translation.value.to.definitionExcludes;
      }

      if (translation.value.from.title != null && translation.value.from.title != "") {
        translationApiResult.title = await translateTextAsync(
          appLanguage.value.languageCode,
          user.value.language.languageCode,
          translation.value.from.title
        );
      } else {
        translationApiResult.title = "";
      }
      if (translation.value.from.definition != null && translation.value.from.definition != "") {
        translationApiResult.definition = await translateTextAsync(
          appLanguage.value.languageCode,
          user.value.language.languageCode,
          translation.value.from.definition
        );
      } else {
        translationApiResult.definition = "";
      }
      if (translation.value.from.definitionExcludes != null && translation.value.from.definitionExcludes != "") {
        translationApiResult.definitionExcludes = await translateTextAsync(
          appLanguage.value.languageCode,
          user.value.language.languageCode,
          translation.value.from.definitionExcludes
        );
      } else {
        translationApiResult.definitionExcludes = "";
      }

      refreshLogs.value = ++refreshLogs.value;
    });

    function close() {
      if (isDirty()) {
        if (acceptUnsavedChanges()) {
          emit("onClose");
        }
      } else {
        emit("onClose");
      }
    }

    async function saveTranslation() {
      if (actionState.value.isSaving) {
        return;
      }
      const response = await saveAsync(user.value.language.languageId);
      if (response.isSuccess) {
        alert.success(["Saved successfully!"]);
        emit("onSave", translation.value.from.code);
        refreshLogs.value = ++refreshLogs.value;
      } else {
        alert.error([response.message]);
      }
    }

    return {
      languageId,
      user,
      actionState,
      itemCode,
      translation,
      translationApiResult,
      translatedProperties,
      propertiesToTranslate,
      translateFromLabel,
      translateToLabel,
      isBrick,
      close,
      saveTranslation,
      isDirty,
      bindableAlert: alert.bindableAlert,
      refreshLogs,
      getLevelName,
      isAttribute,
      isAttributeValue,
    };
  },
};
</script>
<style scoped>
.copy-translation-btn {
  width: 20px;
  margin-right: 5px;
}
</style>
