import { httpGet, httpPost, httpPut, httpCustom, httpDelete, getFileName } from "../services/httpService";

export default (function() {
  async function getPublications(languageId) {
    return httpGet(`/publication?languageId=${languageId}`);
  }

  async function getPublicationClassificationDeltaAsync(languageId, publicationId = null) {
    var publicationIdParam = publicationId == null ? "" : `&publicationId=${publicationId}`;
    return httpGet(`/publication/delta/classification?languageId=${languageId}${publicationIdParam}`);
  }

  async function getPublicationRelationDeltaAsync(languageId, publicationId = null) {
    var publicationIdParam = publicationId == null ? "" : `&publicationId=${publicationId}`;
    return httpGet(`/publication/delta/relation?languageId=${languageId}${publicationIdParam}`);
  }

  async function checkForUpdatesAsync(fromLanguageId, forLanguageId) {
    return httpGet(`/publication/check?fromLanguageId=${fromLanguageId}&forLanguageId=${forLanguageId}`);
  }

  async function mergePublicationAsync(mergeTolanguageId, mergeFromPublicationId) {
    return httpGet(`/publication/merge?mergeTolanguageId=${mergeTolanguageId}&mergeFromPublicationId=${mergeFromPublicationId}`);
  }

  async function addPublicationAsync(request) {
    return httpPost(`/publication`, request);
  }

  async function updatePublicationAsync(request) {
    return httpPut(`/publication`, request);
  }

  async function deletePublicationAsync(publicationId) {
    return httpDelete(`/publication?publicationId=${publicationId}`);
  }

  async function importCombinedPublishedXmlAsync(toImport, isMainLanguage) {
    let response = await httpCustom(`/publication/import/combined/schema?languageId=${toImport.languageId}&isMainLanguage=${isMainLanguage}`, {
      method: "POST",
      headers: { "Content-Type": "text/xml" },
      body: toImport.data,
    });
    return !response.ok ? { isSuccess: false, message: response.statusText } : response.json();
  }

  async function importCombinedPublishedDeltaXmlAsync(toImport, isMainLanguage = true) {
    let response = await httpCustom(`/publication/import/combined/delta?languageId=${toImport.languageId}&isMainLanguage=${isMainLanguage}`, {
      method: "POST",
      headers: { "Content-Type": "text/xml" },
      body: toImport.data,
    });
    return !response.ok ? { isSuccess: false, message: response.statusText } : response.json();
  }

  async function exportPublicationDeltaAsJsonAsync(languageId, publicationId = null) {
    var publicationIdParam = publicationId == null ? "" : `&publicationId=${publicationId}`;
    let response = await httpCustom(`/publication/delta/json?languageId=${languageId}${publicationIdParam}`, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=utf-8" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.json();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  async function exportPublicationDeltaAsXlsxAsync(languageId, publicationId = null) {
    var publicationIdParam = publicationId == null ? "" : `&publicationId=${publicationId}`;
    let response = await httpCustom(`/publication/delta/xlsx?languageId=${languageId}${publicationIdParam}`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  async function uploadReleaseNoteAttachmentAsync(attachemnt) {
    let response = await httpCustom(`/publication/attachment/upload`, {
      method: "POST",
      body: attachemnt,
      headers: {},
    });
    return !response.ok ? { isSuccess: false, message: response.statusText } : response.json();
  }

  async function deleteReleaseNoteAttachmentAsync(publicationId) {
    return httpDelete(`/publication/attachment/delete?publicationId=${publicationId}`);
  }

  return Object.freeze({
    getPublications,
    getPublicationClassificationDeltaAsync,
    getPublicationRelationDeltaAsync,
    importCombinedPublishedXmlAsync,
    importCombinedPublishedDeltaXmlAsync,
    addPublicationAsync,
    updatePublicationAsync,
    deletePublicationAsync,
    checkForUpdatesAsync,
    mergePublicationAsync,
    exportPublicationDeltaAsJsonAsync,
    exportPublicationDeltaAsXlsxAsync,
    uploadReleaseNoteAttachmentAsync,
    deleteReleaseNoteAttachmentAsync,
  });
})();
