import { getFileName, httpCustom, httpGet, httpPost } from "../services/httpService";

export default (function() {
  async function getTranslationsAsync(languageId) {
    return httpGet(`/translation?languageId=${languageId}`);
  }

  async function saveAsync(translation) {
    return httpPost(`/translation/save`, {
      LanguageId: translation.languageId,
      Level: translation.level,
      ParentCode: translation.parentCode,
      Code: translation.code,
      Title: translation.title,
      Definition: translation.definition,
      DefinitionExcludes: translation.definitionExcludes,
      Properties: translation.properties,
      State: translation.state,
      IsActive: true,
    });
  }

  async function translateTextAsync(fromLanguageCode, toLanguageCode, text) {
    return httpPost(`/translation/text`, {
      FromLanguageCode: fromLanguageCode,
      ToLanguageCode: toLanguageCode,
      Text: text,
    });
  }

  async function importTranslationAsync(toImport) {
    let response = await httpCustom(`/translation/import/xlsx?languageId=${toImport.languageId}`, {
      method: "POST",
      headers: {},
      body: toImport.formData,
    });
    return !response.ok ? { isSuccess: false, message: response.statusText } : response.json();
  }

  async function exportAsXlsxAsync(languageId) {
    let response = await httpCustom(`/translation/export/xlsx?languageId=${languageId}`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  return Object.freeze({
    getTranslationsAsync,
    saveAsync,
    translateTextAsync,
    importTranslationAsync,
    exportAsXlsxAsync,
  });
})();
