import { reactive, ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import gpcEnumRepository from "../../repositories/gpcEnumRepository";
import gpcRelationRepository from "../../repositories/gpcRelationRepository";

export default function useSchemaRelation() {
  const isSaving = ref(false);

  const bricks = ref([]);
  const attributes = ref([]);
  const attributeValues = ref([]);

  const userSelection = reactive({
    brickCode: null,
    attributeCode: null,
  });

  function init(brickClassifications) {
    const cloned = JSON.parse(JSON.stringify(brickClassifications));
    bricks.value = cloned;
  }

  async function saveAsync(languageId, workRequest, comment) {
    isSaving.value = true;
    const relationsToSave = getRelationsToSave();
    let response = await gpcRelationRepository.saveRelationsAsync(languageId, workRequest, comment, relationsToSave);
    isSaving.value = false;
    return response;
  }

  function resolveRelationChanges(schemaBricks, bricksFromRelation) {
    bricksFromRelation.value.forEach((brick) => {
      const schemaBrick = schemaBricks.find((br) => br.code == brick.code);
      brick.childs.forEach((attr) => {
        if (attr.isAdded) {
          schemaBrick.childs.push(attr);
          attr.isAdded = null;
        }
        const indexOfSchemaAttribute = schemaBrick.childs.findIndex((schAttr) => schAttr.code == attr.code);
        if (attr.isRemoved) {
          schemaBrick.childs.splice(indexOfSchemaAttribute, 1);
          attr.isRemoved = null;
        } else {
          resolveAttributeRelationChanges(attr, indexOfSchemaAttribute, schemaBrick);
        }
      });
    });
  }

  function resolveAttributeRelationChanges(attr, indexOfSchemaAttribute, schemaBrick) {
    attr.childs.forEach((attrV) => {
      if (attrV.isAdded) {
        if (schemaBrick.childs[indexOfSchemaAttribute].childs.find((f) => f.code == attrV.code) == null) {
          schemaBrick.childs[indexOfSchemaAttribute].childs.push(attrV);
        }
        attrV.isAdded = null;
      }
      if (attrV.isRemoved) {
        const indexOfSchemaAttributeValue = schemaBrick.childs[indexOfSchemaAttribute].childs.findIndex((schAttrV) => schAttrV.code == attrV.code);
        schemaBrick.childs[indexOfSchemaAttribute].childs.splice(indexOfSchemaAttributeValue, 1);
        attrV.isRemoved = null;
      }
    });
  }

  function selectBrick(brick) {
    let selectedBrick;
    if (brick == null && bricks.value.length > 0) {
      selectedBrick = bricks.value[0];
    } else {
      selectedBrick = bricks.value.find((br) => br.code == brick.code);
    }

    if (selectedBrick != null) {
      attributes.value = selectedBrick.childs;
      attributeValues.value = [];

      userSelection.brickCode = selectedBrick.code;
      userSelection.attributeCode = null;
    }
  }

  function selectAttribute(attribute) {
    const selectedAttribute = attributes.value.find((attr) => attr.code == attribute.code);
    if (selectedAttribute != null) {
      attributeValues.value = selectedAttribute.childs;
      userSelection.attributeCode = selectedAttribute.code;
    }
  }

  function restoreRelation(item) {
    const selectedBrick = bricks.value.find((br) => br.code == userSelection.brickCode);
    if (item.level == gpcEnumRepository.ClassificationLevel.Attribute) {
      item.isRemoved = false;
      item.childs.forEach((attrV) => restoreRelation(attrV));
    }
    if (item.level == gpcEnumRepository.ClassificationLevel.AttributeValue) {
      const selectedAttribute = selectedBrick.childs.find((attr) => attr.code == userSelection.attributeCode);
      if (selectedAttribute.isRemoved) {
        return;
      }
      item.isRemoved = false;
    }
  }

  function removeRelation(item) {
    item.isRemoved = true;
    const selectedBrick = bricks.value.find((br) => br.code == userSelection.brickCode);
    if (item.level == gpcEnumRepository.ClassificationLevel.Attribute) {
      removeAttributeRelation(item, selectedBrick);
    }
    if (item.level == gpcEnumRepository.ClassificationLevel.AttributeValue) {
      removeAttributeValueRelation(item, selectedBrick);
    }
  }

  function removeAttributeRelation(item, selectedBrick) {
    if (item.isAdded) {
      const attrToRemoveIndex = selectedBrick.childs.findIndex((attr) => attr.code == item.code);
      if (attrToRemoveIndex >= 0) {
        selectedBrick.childs.splice(attrToRemoveIndex, 1);
      }
      if (userSelection.attributeCode == item.code) {
        userSelection.attributeCode = null;
      }

      attributeValues.value = [];
    } else {
      const addedItemsCount = item.childs.filter((attr) => attr.isAdded).length;
      if (addedItemsCount > 0) {
        const indexOfAdded = item.childs.findIndex((attr) => attr.isAdded);
        item.childs.splice(indexOfAdded, addedItemsCount);
      }
      item.childs.forEach((attrV) => (attrV.isRemoved = true));
    }
  }

  function removeAttributeValueRelation(item, selectedBrick) {
    const selectedAttribute = selectedBrick.childs.find((attr) => attr.code == userSelection.attributeCode);
    if (item.isAdded) {
      const attrValueToRemoveIndex = selectedAttribute.childs.findIndex((attr) => attr.code == item.code);
      if (attrValueToRemoveIndex >= 0) {
        selectedAttribute.childs.splice(attrValueToRemoveIndex, 1);
      }
    }
  }

  function addRelation(inputItem) {
    if (userSelection.brickCode == null) {
      return;
    }

    const item = JSON.parse(JSON.stringify(inputItem));
    item.isAdded = true;
    const selectedBrick = bricks.value.find((br) => br.code == userSelection.brickCode);
    if (item.level == gpcEnumRepository.ClassificationLevel.Attribute) {
      if (selectedBrick.childs.find((attr) => attr.code == item.code) != null) {
        return;
      }

      item.childs.forEach((attrValue) => {
        attrValue.isAdded = true;
      });
      selectedBrick.childs.push(item);
    }
    if (item.level == gpcEnumRepository.ClassificationLevel.AttributeValue) {
      if (userSelection.attributeCode == null) {
        return;
      }

      const selectedAttribute = selectedBrick.childs.find((attr) => attr.code == userSelection.attributeCode);
      if (selectedAttribute.isRemoved) {
        return;
      }
      if (selectedAttribute.childs.find((attrV) => attrV.code == item.code) != null) {
        return;
      }
      selectedAttribute.childs.push(item);
    }
  }

  function getRelationsToSave() {
    const relationsToSave = [];
    bricks.value.forEach((br) => {
      br.childs.forEach((attr) => {
        attr.childs.forEach((attrV) => {
          if (attrV.isAdded || attrV.isRemoved) {
            let relation = {
              brickCode: br.code,
              attributeCode: attr.code,
              attributeValueCode: attrV.code,
            };

            if (attrV.isAdded) {
              relation.state = gpcEnumRepository.RelationState.Added;
            }
            if (attrV.isRemoved) {
              relation.state = gpcEnumRepository.RelationState.Removed;
            }

            relationsToSave.push(relation);
          }
        });
      });
    });
    return relationsToSave;
  }

  function isDirty() {
    return getRelationsToSave().length > 0;
  }

  return {
    userSelection: userSelection,
    bricks: computed(() => bricks.value),
    attributes: computed(() => attributes.value),
    attributeValues: computed(() => attributeValues.value),
    isSelectedBrick: (code) => code == userSelection.brickCode,
    isSelectedAttribute: (code) => code == userSelection.attributeCode,
    init,
    selectBrick,
    selectAttribute,
    restoreRelation,
    removeRelation,
    addRelation,
    saveAsync,
    resolveRelationChanges,
    isDirty,
  };
}
