<template>
  <div class="row">
    <panel barColor="sky" class="col-6">
      <div class="row align-items-center">
        <div class="col-4 d-flex align-items-start p-1"></div>
        <div class="col-4 align-self-center panel-title p-1">{{ publicationPanelTitle }}</div>
        <div class="col-4 d-flex justify-content-end"></div>
        <alert v-bind="bindableAlert" class="mt-3 mb-3"></alert>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="form-floating">
            <select id="PublicationSelect" v-model="selectedPublication" class="form-select mb-3">
              <option v-for="(publication, index) in publications" :key="index" :value="publication">
                {{ getPublicationSelectOptionName(publication) }}
              </option>
            </select>
            <label for="PublicationSelect">Publication</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table table-hover" aria-label="Publication reports table">
            <thead>
              <tr class="visually-hidden">
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="align-middle">
                <td class="text-start">Download GPC Brick by Segment Report</td>
                <td>
                  <div class="button-icon ms-2 float-end" @click="downloadBrickBySegmentReport" title="Download GPC Brick by Segment Report as .xlsx">
                    <font-awesome v-if="!isDownloadingBrickBySegmentReport" icon="file-excel"></font-awesome>
                    <font-awesome v-if="isDownloadingBrickBySegmentReport" icon="spinner" class="fa-spinner"></font-awesome>
                  </div>
                </td>
              </tr>
              <tr class="align-middle">
                <td class="text-start">Download Translation Verification Report</td>
                <td>
                  <div
                    class="button-icon ms-2 float-end"
                    @click="downloadTranslationVerificationReport"
                    title="Download Translation Verification Report as .xlsx"
                  >
                    <font-awesome v-if="!isDownloadingTranslationVerificationReport" icon="file-excel"></font-awesome>
                    <font-awesome v-if="isDownloadingTranslationVerificationReport" icon="spinner" class="fa-spinner"></font-awesome>
                  </div>
                </td>
              </tr>
              <tr class="align-middle">
                <td class="text-start">Download GPC GDSN Bricks Mapping Report</td>
                <td>
                  <div class="button-icon ms-2 float-end" @click="downloadGdsnBricksMappingReport" title="GPC GDSN Bricks Mapping Report as .xlsx">
                    <font-awesome v-if="!isDownloadingGdsnBricksMappingReport" icon="file-excel"></font-awesome>
                    <font-awesome v-if="isDownloadingGdsnBricksMappingReport" icon="spinner" class="fa-spinner"></font-awesome>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </panel>
    <panel barColor="sky" class="col-6">
      <div class="row align-items-center">
        <div class="col-4 d-flex align-items-start p-1"></div>
        <div class="col-4 align-self-center panel-title p-1">{{ schemaPanelTitle }}</div>
        <div class="col-4 d-flex justify-content-end"></div>
        <alert v-bind="bindableAlert" class="mt-3 mb-3"></alert>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <table class="table table-hover" aria-label="Schema reports table">
            <thead>
              <tr class="visually-hidden">
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="align-middle">
                <td class="text-start">Download GPC Hierarchy Report</td>
                <td>
                  <div class="button-icon ms-2 float-end" @click="downloadHierarchyReport" title="Download GPC Hierarchy Report as .xlsx">
                    <font-awesome v-if="!isDownloadingHierarchyReport" icon="file-excel"></font-awesome>
                    <font-awesome v-if="isDownloadingHierarchyReport" icon="spinner" class="fa-spinner"></font-awesome>
                  </div>
                </td>
              </tr>
              <tr class="align-middle">
                <td class="text-start">Download GPC Segment Report</td>
                <td>
                  <div class="button-icon ms-2 float-end" @click="downloadSegmentReport" title="Download GPC Segment Report as .xlsx">
                    <font-awesome v-if="!isDownloadingSegmentReport" icon="file-excel"></font-awesome>
                    <font-awesome v-if="isDownloadingSegmentReport" icon="spinner" class="fa-spinner"></font-awesome>
                  </div>
                </td>
              </tr>
              <tr class="align-middle">
                <td class="text-start">Download GPC Top 10 Segments Report</td>
                <td>
                  <div class="button-icon ms-2 float-end" @click="downloadTopTenSegmentsReport" title="Download GPC Top 10 Segments Report as .xlsx">
                    <font-awesome v-if="!isDownloadingTopTenSegmentsReport" icon="file-excel"></font-awesome>
                    <font-awesome v-if="isDownloadingTopTenSegmentsReport" icon="spinner" class="fa-spinner"></font-awesome>
                  </div>
                </td>
              </tr>
              <tr class="align-middle">
                <td class="text-start">Download GPC Attributes by Brick Report</td>
                <td>
                  <div
                    class="button-icon ms-2 float-end"
                    @click="downloadAttributesByBrickReport"
                    title="Download GPC Attributes by Brick Report as .xlsx"
                  >
                    <font-awesome v-if="!isDownloadingAttributesByBrickReport" icon="file-excel"></font-awesome>
                    <font-awesome v-if="isDownloadingAttributesByBrickReport" icon="spinner" class="fa-spinner"></font-awesome>
                  </div>
                </td>
              </tr>
              <tr class="align-middle">
                <td class="text-start">Download GPC Number of Attributes by Brick Report</td>
                <td>
                  <div
                    class="button-icon ms-2 float-end"
                    @click="downloadNumberOfAttributesByBrickReport"
                    title="Download GPC Number of Attributes by Brick Report as .xlsx"
                  >
                    <font-awesome v-if="!isDownloadingNumberOfAttributesByBrickReport" icon="file-excel"></font-awesome>
                    <font-awesome v-if="isDownloadingNumberOfAttributesByBrickReport" icon="spinner" class="fa-spinner"></font-awesome>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import Panel from "../components/shared/Panel.vue";
import Alert from "../components/shared/Alert.vue";
import useAlert from "../composables/shared/useAlert";
import useUserSingleton from "../composables/shared/useUserSingleton";
import usePublication from "../composables/publication/usePublication";
import useReport from "../composables/report/useReport";
import { downloadXlsx } from "../utils/fileUtil";

export default {
  components: { Panel, Alert },
  setup() {
    const alert = useAlert();
    const { languageId } = useUserSingleton();
    const { isFetchingPublications, fetchPublicationsAsync } = usePublication();
    const {
      isDownloadingBrickBySegmentReport,
      isDownloadingTranslationVerificationReport,
      isDownloadingHierarchyReport,
      isDownloadingSegmentReport,
      isDownloadingTopTenSegmentsReport,
      isDownloadingAttributesByBrickReport,
      isDownloadingNumberOfAttributesByBrickReport,
      isDownloadingGdsnBricksMappingReport,
      downloadBrickBySegmentReportAsXlsxAsync,
      downloadTranslationVerificationReportAsXlsxAsync,
      downloadHierarchyReportAsXlsxAsync,
      downloadSegmentReportAsXlsxAsync,
      downloadTopTenSegmentsReportAsXlsxAsync,
      downloadAttributesByBrickReportAsXlsxAsync,
      downloadNumberOfAttributesByBrickReportAsXlsxAsync,
      downloadGdsnBricksMappingReportAsXlsxAsync,
    } = useReport();

    const publicationPanelTitle = ref(`Publication Reports`);
    const schemaPanelTitle = ref(`Schema Reports`);
    const publications = ref([]);
    const selectedPublication = ref(null);

    onMounted(async () => {
      let response = await fetchPublicationsAsync(languageId.value);
      if (!response.isSuccess) {
        alert.error([response.message]);
      } else {
        let utcDateNow = new Date(new Date().toUTCString());
        let draftPublication = {
          publicationId: null,
          publicationName: `DRAFT GPC as of ${utcDateNow.toISOString().slice(0, 10)}`,
          publicationDate: utcDateNow,
        };
        publications.value = [draftPublication, ...response.result];
        if (response.result.length) {
          publications.value = publications.value.sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate));
          selectedPublication.value = publications.value[0];
        }
      }
    });

    function getPublicationSelectOptionName(publication) {
      return publication.publicationId == null ? publication.publicationName : publication.publicationName + " (" + publication.version + ")";
    }

    async function downloadBrickBySegmentReport() {
      if (isDownloadingBrickBySegmentReport.value || selectedPublication.value == null) {
        return;
      }
      let data = await downloadBrickBySegmentReportAsXlsxAsync(selectedPublication.value.publicationId, languageId.value);
      downloadXlsx(data.name, data.file);
    }

    async function downloadTranslationVerificationReport() {
      if (isDownloadingTranslationVerificationReport.value || selectedPublication.value == null) {
        return;
      }
      let data = await downloadTranslationVerificationReportAsXlsxAsync(selectedPublication.value.publicationId, languageId.value);
      downloadXlsx(data.name, data.file);
    }

    async function downloadGdsnBricksMappingReport() {
      if (isDownloadingGdsnBricksMappingReport.value || selectedPublication.value == null) {
        return;
      }
      let data = await downloadGdsnBricksMappingReportAsXlsxAsync(selectedPublication.value.publicationId, languageId.value);
      downloadXlsx(data.name, data.file);
    }

    async function downloadHierarchyReport() {
      if (isDownloadingHierarchyReport.value) {
        return;
      }
      let data = await downloadHierarchyReportAsXlsxAsync(languageId.value);
      downloadXlsx(data.name, data.file);
    }
    async function downloadSegmentReport() {
      if (isDownloadingSegmentReport.value) {
        return;
      }
      let data = await downloadSegmentReportAsXlsxAsync(languageId.value);
      downloadXlsx(data.name, data.file);
    }
    async function downloadTopTenSegmentsReport() {
      if (isDownloadingTopTenSegmentsReport.value) {
        return;
      }
      let data = await downloadTopTenSegmentsReportAsXlsxAsync(languageId.value);
      downloadXlsx(data.name, data.file);
    }
    async function downloadAttributesByBrickReport() {
      if (isDownloadingAttributesByBrickReport.value) {
        return;
      }
      let data = await downloadAttributesByBrickReportAsXlsxAsync(languageId.value);
      downloadXlsx(data.name, data.file);
    }
    async function downloadNumberOfAttributesByBrickReport() {
      if (isDownloadingNumberOfAttributesByBrickReport.value) {
        return;
      }
      let data = await downloadNumberOfAttributesByBrickReportAsXlsxAsync(languageId.value);
      downloadXlsx(data.name, data.file);
    }

    return {
      publicationPanelTitle,
      schemaPanelTitle,
      isFetchingPublications,
      bindableAlert: alert.bindableAlert,
      publications,
      selectedPublication,
      isDownloadingBrickBySegmentReport,
      isDownloadingTranslationVerificationReport,
      isDownloadingHierarchyReport,
      isDownloadingSegmentReport,
      isDownloadingTopTenSegmentsReport,
      isDownloadingAttributesByBrickReport,
      isDownloadingNumberOfAttributesByBrickReport,
      isDownloadingGdsnBricksMappingReport,
      downloadBrickBySegmentReport,
      downloadTranslationVerificationReport,
      downloadHierarchyReport,
      downloadSegmentReport,
      downloadTopTenSegmentsReport,
      downloadAttributesByBrickReport,
      downloadNumberOfAttributesByBrickReport,
      downloadGdsnBricksMappingReport,
      getPublicationSelectOptionName,
    };
  },
};
</script>
