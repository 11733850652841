import { getFileName, httpCustom, httpGet } from "../services/httpService";
export default (function() {
  async function getSchemaAsync(languageId, asTree = true) {
    const asTreeParam = asTree ? "&asTree=true" : "&asTree=false";
    return httpGet(`/schema?languageId=${languageId}${asTreeParam}`);
  }

  async function getSegmentFamilyClassAsync(languageId) {
    return httpGet(`/schema/segment-family-class?languageId=${languageId}`);
  }

  async function getBricksAsync(languageId) {
    return httpGet(`/schema/brick?languageId=${languageId}`);
  }

  async function getAttributesAsync(languageId) {
    return httpGet(`/schema/attribute?languageId=${languageId}`);
  }

  async function getAttributeValuesAsync(languageId) {
    return httpGet(`/schema/attributevalue?languageId=${languageId}`);
  }

  async function getAttributesWithValuesAsync(languageId) {
    return httpGet(`/schema/attribute-attributevalue?languageId=${languageId}`);
  }

  async function exportAsJsonAsync(languageId, onlyActive) {
    let response = await httpCustom(`/schema/export/json?languageId=${languageId}&onlyActive=${onlyActive ? "true" : "false"}`, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=utf-8" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.json();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  async function exportAsXmlAsync(languageId, onlyActive) {
    let response = await httpCustom(`/schema/export/xml?languageId=${languageId}&onlyActive=${onlyActive ? "true" : "false"}`, {
      method: "GET",
      headers: { "Content-Type": "application/xml;charset=utf-8" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.text();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  async function exportAsXlsxAsync(languageId, onlyActive) {
    let response = await httpCustom(`/schema/export/xlsx?languageId=${languageId}&onlyActive=${onlyActive ? "true" : "false"}`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  async function uploadSchemaModificationAsync(toImport) {
    let response = await httpCustom(`/schema/modification/upload`, {
      method: "POST",
      headers: {},
      body: toImport.formData,
    });
    return !response.ok ? { isSuccess: false, message: response.statusText } : response.json();
  }

  async function downloadSchemaModificationAsync() {
    let response = await httpCustom(`/schema/modification/download`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  return Object.freeze({
    getSchemaAsync,
    getSegmentFamilyClassAsync,
    getBricksAsync,
    getAttributesAsync,
    getAttributeValuesAsync,
    getAttributesWithValuesAsync,
    exportAsJsonAsync,
    exportAsXmlAsync,
    exportAsXlsxAsync,
    uploadSchemaModificationAsync,
    downloadSchemaModificationAsync
  });
})();
