import { computed, onUnmounted, ref } from "vue";
import gpcEnumRepository from "../../repositories/gpcEnumRepository";

const links = ref([]);
export default function useSchemaNavigationSingleton() {
  function getLink() {
    if (links.value.length <= 0) {
      return null;
    }
    return links.value[links.value.length - 1].classification;
  }

  function addLink(item) {
    if (item.level == gpcEnumRepository.ClassificationLevel.AttributeValue) {
      return;
    }
    const link = {
      classification: item,
      level: item.level,
      title: Object.keys(gpcEnumRepository.ClassificationLevel).find((key) => gpcEnumRepository.ClassificationLevel[key] == item.level),
      childs: item.childs,
    };
    links.value.push(link);
    return link;
  }

  function removeLastLink() {
    return links.value.pop();
  }

  function goToLink(link) {
    if (link.level == gpcEnumRepository.ClassificationLevel.Segment) {
      links.value = [];
      return;
    }

    do {
      removeLastLink();
    } while (getLink().level != link.level - 1);
  }

  function removeLinks() {
    links.value = [];
  }

  onUnmounted(() => {
    links.value = [];
  });

  return {
    links: computed(() => links.value),
    getLink,
    addLink,
    removeLastLink,
    goToLink,
    removeLinks,
  };
}
