<template>
  <panel v-if="result.length > 0">
    <div class="row mb-3">
      <div class="col-2 col-sm-2 col-md-4 d-flex align-items-start"></div>
      <div class="col-3 col-sm-4 align-self-center panel-title">Result</div>
      <div class="col-1 d-none d-sm-none d-md-none d-lg-none d-xl-block"></div>
      <div class="col d-flex justify-content-end">
        <div class="input-group">
          <span class="input-group-text rounded-0" id="WorkRequest">WR</span>
          <input
            v-model="workRequest"
            type="text"
            class="form-control rounded-0"
            :class="{ 'is-invalid': validation.isInvalidWorkRequest, 'is-valid': !validation.isInvalidWorkRequest }"
            placeholder="yy-999999"
            required
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table table-sm text-start" aria-label="Result of configured relations">
          <thead>
            <tr>
              <th scope="col">Brick</th>
              <th scope="col">Attribute</th>
              <th scope="col">AttributeValue</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="relation in result" :key="relation.attriuteValueCode">
              <td class="col-5">
                <span v-show="relation.brickCode != null" class="text-truncate">
                  ({{ relation.brickCode }}) {{ relation.brickTitle }}
                  <font-awesome icon="long-arrow-alt-right" class="hw-20"></font-awesome>
                </span>
              </td>
              <td
                class="col-4"
                :class="{ 'table-danger': relation.attributeCode != null && relation.isAttributeRemoved, 'table-success': relation.attributeCode != null && relation.isAttributeAdded }"
              >
                <span v-show="relation.attributeCode != null">
                  ({{ relation.attributeCode }}) {{ relation.attributeTitle }}
                  <font-awesome icon="long-arrow-alt-right" class="hw-20"></font-awesome>
                </span>
              </td>
              <td class="col-3" :class="{ 'table-danger': relation.isAttributeValueRemoved, 'table-success': relation.isAttributeValueAdded }">
                ({{ relation.attributeValueCode }}) {{ relation.attributeValueTitle }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row align-items-center mt-2">
      <div class="col-11">
        <input v-model="comment" id="Comment" class="form-control rounded-0" type="text" placeholder="Comment" autocomplete="off" />
      </div>
      <div class="col-1 d-flex justify-content-center">
        <div class="button-icon" @click="saveRelations" :class="{ disabled: validation.isInvalidWorkRequest || result.length == 0 }">
          <font-awesome icon="save"></font-awesome>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import Panel from "../shared/Panel.vue";
import { computed } from "@vue/runtime-core";

export default {
  components: { Panel },
  emits: ["onSave"],
  props: {
    bricks: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const workRequest = ref("");
    const comment = ref("");

    const result = computed(() => {
      const relations = [];

      props.bricks.forEach((br) => {
        br.childs.forEach((attr) => {
          attr.childs.forEach((attrV) => {
            if (attrV.isAdded || attrV.isRemoved) {
              if (relations.find((rel) =>rel.brickCode == br.code && rel.attributeCode == attr.code) != null) {
                relations.push({
                  brickCode: null,
                  brickTitle: null,
                  attributeCode: null,
                  attributeTitle: null,
                  attributeValueCode: attrV.code,
                  attributeValueTitle: attrV.title,
                  isAttributeValueAdded: attrV.isAdded,
                  isAttributeValueRemoved: attrV.isRemoved,
                  isAttributeAdded: attr.isAdded,
                  isAttributeRemoved: attr.isRemoved,
                });
              } else {
                relations.push({
                  brickCode: br.code,
                  brickTitle: br.title,
                  attributeCode: attr.code,
                  attributeTitle: attr.title,
                  attributeValueCode: attrV.code,
                  attributeValueTitle: attrV.title,
                  isAttributeValueAdded: attrV.isAdded,
                  isAttributeValueRemoved: attrV.isRemoved,
                  isAttributeAdded: attr.isAdded,
                  isAttributeRemoved: attr.isRemoved,
                });
              }
            }
          });
        });
      });
      return relations;
    });

    const validationErrorMessage = reactive({
      workRequest: "",
    });

    const validation = reactive({
      isInvalidWorkRequest: computed(() => {
        const regex = /^\d{2}-\d{6}$/gm; // nn-nnnnnn
        if (workRequest.value == null || workRequest.value == "") {
          validationErrorMessage.workRequest = "Please enter WR";
          return true;
        }
        if (!workRequest.value.match(regex)) {
          validationErrorMessage.workRequest = "WR must match YY-NNNNNN";
          return true;
        }
        return false;
      }),
    });

    function saveRelations() {
      emit("onSave", workRequest.value, comment.value);
    }

    return {
      result,
      workRequest,
      comment,
      validation,
      saveRelations,
    };
  },
};
</script>
