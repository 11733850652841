<template>
  <div class="nav-level-select col px-0 pb-0 text-start mt-3" v-if="links.length > 0">
    <a href="#!" class="text-decoration-none p-0" v-for="item in links" :key="item.level" @click="clickLink(item)">
      <a href="#!" class="fw-bold">{{ item.title }}: </a>
      {{ linkText(item) }}
    </a>
  </div>
</template>

<script>
import useSchemaNavigation from "../../composables/schema/useSchemaNavigationSingleton";
export default {
  emits: ["onLinkChange"],
  setup(props, { emit }) {
    const { links, goToLink, getLink } = useSchemaNavigation();

    const linkText = (link) => {
      return `${link.classification.title}(${link.classification.code}) > `;
    };

    function clickLink(link) {
      goToLink(link);
      emit("onLinkChange", getLink());
    }

    return {
      clickLink,
      links,
      linkText,
    };
  },
};
</script>