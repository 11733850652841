<template>
  <div
    v-show="bindableAlert.show"
    class="alert text-start rounded-0 p-2 mb-0"
    :class="{ 'alert-success': bindableAlert.type == 1, 'alert-danger': bindableAlert.type == 2, 'alert-warning': bindableAlert.type == 3 }"
    role="alert"
  >
    <div class="d-flex justify-content-between align-items-center pb-1">
      <div class="alert-heading fw-bold">{{ bindableAlert.header }}</div>
      <button type="button" class="btn-close" @click="close"></button>
    </div>
    <div v-for="(message, index) in bindableAlert.messages" :key="index" class="py-1">{{ message }}</div>
  </div>
</template>

<script>
import { watchEffect } from "vue";
import useAlert from "../../composables/shared/useAlert";
export default {
  props: {
    show: Boolean,
    type: Number,
    header: String,
    messages: Array,
  },
  setup(props) {
    const { bindableAlert } = useAlert();

    watchEffect(() => {
      bindableAlert.show = props.show;
      bindableAlert.type = props.type;
      bindableAlert.header = props.header;
      bindableAlert.messages = props.messages;
    });

    const close = () => {
      bindableAlert.show = false;
    };

    return {
      bindableAlert,
      close,
    };
  },
};
</script>