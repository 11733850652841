<template>
  <svg xmlns="http://www.w3.org/2000/svg" :class="$props.class" class="action-icon" :viewBox="`0 0 ${width} ${height}`">
    <path fill="currentColor" :d="svgPath" />
  </svg>
</template>

<script>
import { defineComponent, computed } from "vue";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";

export default defineComponent({
  name: "FontAwesomeIcon",

  props: {
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "fas",
      required: false,
    },
    class: String,
  },

  setup(props) {
    const definition = computed(() =>
      findIconDefinition({
        prefix: props.type,
        iconName: props.icon,
      })
    );

    const width = computed(() => definition.value.icon[0]);
    const height = computed(() => definition.value.icon[1]);
    const svgPath = computed(() => definition.value.icon[4]);

    return { width, height, svgPath };
  },
});
</script>

<style lang="scss" scoped>
.action-icon {
  cursor: pointer;
  &:hover {
    & > path {
      color: $gs1-color-orange;
    }
  }
}

svg.hw-20{
  height: 20px !important;
  width: 20px !important;
}
svg.hw-30{
  height: 30px !important;
  width: 30px !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.fa-spinner {
  cursor: default;
  user-select: none;
  pointer-events: none;
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite;
  & > path {
    color: $gs1-color-orange;
  }
}
</style>
