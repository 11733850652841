<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-start mt-3">
        <h1>GS1 Global Product Classification (GPC) Tool</h1>
        <p>Welcome to the GS1 GPC Tool!</p>
        <p>Oxford English is the reference language for all GS1 standards including GPC.</p>
        <p>
          To support Global Product Classification adoption around the world, GS1 Global Office (GO) can host and support Member Organisation (MO) representatives who provide GPC translations through
          the “GPC Tool”.
        </p>
        <p>This tool will allow your Organisation to:</p>
        <ul>
          <li>Provide an online translation of GPC for your users</li>
          <li>Publish the GPC Standard in your language</li>
          <li>Publish to the GPC Browser in your language</li>
        </ul>
        <p>Translated versions of GPC can be also be accessed by the public via the GPC Browser, along with the official Oxford English version.</p>
        <p>Please <a href="#" @click="signIn">SIGN IN</a> to explore the Global Product Classification (GPC) Tool.</p>
      </div>
    </div>
  </div>
</template>

<script>
import authService from '../services/authService';
export default {
  setup() {
    const signIn = ()=> authService.login();
    return {signIn};
  },
};
</script>
<style scoped>
a {
  font-weight: bold;
}
svg {
  width: 20px;
}
</style>
