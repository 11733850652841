<template>
  <panel barColor="blue">
    <div class="row align-items-center">
      <div class="col-4 d-flex align-items-start p-1">
        <div class="button-icon ms-2" @click="clickClose" title="Go back">
          <font-awesome icon="arrow-circle-left"></font-awesome>
        </div>
      </div>
      <div class="col-4 align-self-center panel-title p-1">{{ panelTitle }}</div>
      <div class="col-4 d-flex justify-content-end">
        <div class="button-icon ms-2" @click="clickExportJson" title="Download Combined Published Schema as .json">
          <font-awesome v-if="!exportState.isDownloadingJson" icon="file-alt"></font-awesome>
          <font-awesome v-if="exportState.isDownloadingJson" icon="spinner" class="fa-spinner"></font-awesome>
        </div>
        <div class="button-icon" @click="clickExportXml" title="Download Combined Published Schema as .xml">
          <font-awesome v-if="!exportState.isDownloadingXml" icon="file-code"></font-awesome>
          <font-awesome v-if="exportState.isDownloadingXml" icon="spinner" class="fa-spinner"></font-awesome>
        </div>
        <div class="button-icon" @click="clickExportExcel" title="Download Combined Published Schema as .xlsx">
          <font-awesome v-if="!exportState.isDownloadingExcel" icon="file-excel"></font-awesome>
          <font-awesome v-if="exportState.isDownloadingExcel" icon="spinner" class="fa-spinner"></font-awesome>
        </div>
      </div>
      <div class="col-12"><alert v-bind="bindableAlert" class="mt-3 mb-3"></alert></div>
      <div class="col-12">
        <div class="row text-start mt-3">
          <div class="col-6">
            <label for="PublicationName" class="form-label">Publication name</label>
            <input
              v-model="publication.publicationName"
              type="text"
              class="form-control"
              id="PublicationName"
              :placeholder="publicationPlaceholder"
              :class="{ 'is-invalid': publicationValidation.isInvalidName }"
              required
            />
            <div class="invalid-feedback">{{ publicationValidation.invalidNameMessage }}</div>
          </div>
          <div class="col-2">
            <label for="PublicationDate" class="form-label">Publication date</label>
            <input
              v-model="publication.publicationDate"
              type="date"
              class="form-control"
              id="PublicationDate"
              disabled
              :class="{ 'is-invalid': publicationValidation.isInvalidDate }"
              required
            />
            <div class="invalid-feedback">{{ publicationValidation.invalidDateMessage }}</div>
          </div>
          <div v-if="isAdmin" class="col-4">
            <label for="ReleaseNote" class="form-label">Upload Release Note</label>
            <div class="input-group">
              <input
                v-show="publication.releaseNoteAttachment != null && publication.releaseNoteAttachment != ''"
                class="form-control"
                :class="{ 'is-valid': publication.releaseNoteAttachment != null && publication.releaseNoteAttachment != '' }"
                type="text"
                id="ReleaseNoteUploaded"
                value="File uploaded"
                disabled
                readonly
              />
              <input
                v-show="publication.releaseNoteAttachment == null || publication.releaseNoteAttachment == ''"
                class="form-control"
                type="file"
                id="ReleaseNote"
                @change="selectAttachmentFile($event)"
                accept="application/pdf"
              />
              <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Action
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <a
                    @click.prevent="uploadAttachmentFile"
                    title="Upload Release Note PDF"
                    :class="{ disabled: attachment == null || publication.publicationId == 0 }"
                    class="dropdown-item"
                    href="#"
                    >Upload</a
                  >
                </li>
                <li>
                  <a @click.prevent="deleteAttachmentFile" title="Delete Release Note PDF" class="dropdown-item" href="#">Remove</a>
                </li>
              </ul>
            </div>
            <div v-if="isUploadingReleaseNoteAttachment" class="button-icon d-flex align-items-center justify-content-center">
              <span>Uploading...</span>
              <span><font-awesome icon="spinner" class="fa-spinner"></font-awesome></span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 text-start mt-3">
        <spinner
          v-if="isFetchingPublicationDelta"
          :texts="['Fetching Publication Delta...', 'Finishing...']"
          style="margin-bottom: -10px !important"
          class="justify-content-center my-3"
        ></spinner>
        <div v-if="!isFetchingPublicationDelta" class="table-responsive">
          <table class="table table-hover table-sm" aria-label="Publication release notes">
            <thead>
              <tr>
                <th scope="col" class="col"></th>
                <th scope="col" class="col fw-bold">Added</th>
                <th scope="col" class="col fw-bold">Updated</th>
                <th scope="col" class="col fw-bold">Removed</th>
                <th scope="col" class="col fw-bold">Moved</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="level in levels" :key="level">
                <td class="fw-bold">{{ getLevelName(level) }}</td>
                <td
                  :class="{
                    'text-success': publicationDeltaSummaryDictionary[level].added > 0,
                    'fw-bold': publicationDeltaSummaryDictionary[level].added > 0,
                  }"
                >
                  {{ publicationDeltaSummaryDictionary[level].added }}
                </td>
                <td
                  :class="{
                    'text-warning': publicationDeltaSummaryDictionary[level].updated > 0,
                    'fw-bold': publicationDeltaSummaryDictionary[level].updated > 0,
                  }"
                >
                  {{ publicationDeltaSummaryDictionary[level].updated }}
                </td>
                <td
                  :class="{
                    'text-danger': publicationDeltaSummaryDictionary[level].removed > 0,
                    'fw-bold': publicationDeltaSummaryDictionary[level].removed > 0,
                  }"
                >
                  {{ publicationDeltaSummaryDictionary[level].removed }}
                </td>
                <td :class="{ 'fw-bold': publicationDeltaSummaryDictionary[level].moved > 0 }">
                  {{ publicationDeltaSummaryDictionary[level].moved }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-3 text-start mt-3 d-flex align-self-start">
        <spinner
          v-if="isFetchingPublicationDelta"
          :texts="['Fetching Relation Delta...', 'Finishing...']"
          style="margin-bottom: -10px !important"
          class="justify-content-center my-3"
        ></spinner>
        <div v-if="!isFetchingPublicationDelta" class="table-responsive w-100">
          <table class="table table-hover table-sm" aria-label="Publication relation delta">
            <thead>
              <tr>
                <th scope="col" class="col"></th>
                <th scope="col" class="col fw-bold">Added</th>
                <th scope="col" class="col fw-bold">Removed</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="fw-bold">Relations</td>
                <td :class="{ 'text-success': relationDeltaAddedCount > 0, 'fw-bold': relationDeltaAddedCount > 0 }">
                  {{ relationDeltaAddedCount }}
                </td>
                <td :class="{ 'text-danger': relationDeltaRemovedCount > 0, 'fw-bold': relationDeltaRemovedCount > 0 }">
                  {{ relationDeltaRemovedCount }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-between mt-1">
        <div class="d-flex align-items-center">
          <button
            v-if="isAdmin"
            class="btn btn-sm btn-outline-danger me-3"
            type="button"
            @click="removePublication"
            :disabled="publication.publicationId == 0"
            title="Remove Publication"
          >
            Delete
          </button>
          <div class="button-icon" @click="clickExportDeltaJson" title="Download Publication Delta as .json">
            <font-awesome v-if="!isDownloadingPublicationDeltaJson" icon="file-alt"></font-awesome>
            <font-awesome v-if="isDownloadingPublicationDeltaJson" icon="spinner" class="fa-spinner"></font-awesome>
          </div>
          <div class="button-icon" @click="clickExportReportExcel" title="Download Publication Delta as .xlsx">
            <font-awesome v-if="!isDownloadingPublicationDeltaXlsx" icon="file-excel"></font-awesome>
            <font-awesome v-if="isDownloadingPublicationDeltaXlsx" icon="spinner" class="fa-spinner"></font-awesome>
          </div>
        </div>
        <div class="d-flex">
          <div v-if="isAdmin" class="form-check me-3">
            <input class="form-check-input" type="checkbox" v-model="publication.isGDSN" id="IsGDSN" :disabled="publication.publicationId == 0" />
            <label class="form-check-label" for="IsGDSN">GDSN</label>
          </div>
          <div class="form-check me-3">
            <input class="form-check-input" type="checkbox" v-model="publication.browser" id="IsBrowser" />
            <label class="form-check-label" for="IsBrowser">Browser</label>
          </div>
          <div
            class="button-icon"
            @click="clickSavePublication"
            :class="{ disabled: publicationValidation.isSaveDisabled }"
            title="Save/Update Publication"
          >
            <font-awesome v-if="!isSavingPublication" icon="save"></font-awesome>
            <font-awesome v-if="isSavingPublication" icon="spinner" class="fa-spinner"></font-awesome>
          </div>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import Panel from "../shared/Panel.vue";
import useSchemaExport from "../../composables/schema/useSchemaExport";
import useUserSingleton from "../../composables/shared/useUserSingleton";
import { downloadJson, downloadXml, downloadXlsx } from "../../utils/fileUtil";
import Alert from "../shared/Alert.vue";
import useAlert from "../../composables/shared/useAlert";
import usePublication from "../../composables/publication/usePublication";
import { computed, onMounted } from "@vue/runtime-core";
import gpcEnumRepository from "../../repositories/gpcEnumRepository";
import useClassification from "../../composables/classification/useClassification";
import Spinner from "../shared/Spinner.vue";
import useToast from "../../composables/shared/useToast";
import useUnsavedChanges from "../../composables/shared/useUnsavedChanges";
import useBlob from "../../composables/shared/useBlob";
export default {
  components: { Panel, Alert, Spinner },
  emits: ["onSave", "onClose"],
  props: {
    inputPublication: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const alert = useAlert();
    const { showToast } = useToast();
    const { acceptUnsavedChanges } = useUnsavedChanges();
    const { languageId, user, isAdmin } = useUserSingleton();
    const { exportAsJsonAsync, exportAsXmlAsync, exportAsXlsxAsync } = useSchemaExport();
    const { getLevelName: getClassificationLevelName, isAttributeValue } = useClassification();
    const { uploadBlobsAsync, uploadAllBlobsAsync } = useBlob();
    const {
      isSavingPublication,
      savePublicationAsync,
      isFetchingPublicationDelta,
      fetchPublicationClassificationDeltaAsync,
      fetchPublicationRelationDeltaAsync,
      isDownloadingPublicationDeltaJson,
      exportPublicationDeltaAsJsonAsync,
      isDownloadingPublicationDeltaXlsx,
      exportPublicationDeltaAsXlsxAsync,
      uploadReleaseNoteAttachmentAsync,
      isUploadingReleaseNoteAttachment,
      isDeletingReleaseNoteAttachment,
      deleteReleaseNoteAttachmentAsync,
      deletePublicationAsync,
    } = usePublication();

    const panelTitle = ref("New publication");
    const publicationDelta = ref([]);
    const publicationRelationDelta = ref([]);

    const publication = reactive({
      publicationId: 0,
      publicationName: "",
      publicationDate: null,
      languageId: user.value.language.languageId,
      browser: false,
      isGDSN: false,
      releaseNoteAttachment: null,
    });

    const publicationValidation = reactive({
      invalidNameMessage: "Please enter Publication Name",
      invalidDateMessage: "Please enter Publication Date",
      isInvalidName: computed(() => publication.publicationName == null || publication.publicationName == ""),
      isInvalidDate: computed(() => publication.publicationDate == null || publication.publicationDate == ""),
      isSaveDisabled: computed(() => publicationValidation.isInvalidName || publicationValidation.isInvalidDate || !isDirty()),
    });

    const exportState = reactive({
      isDownloadingJson: false,
      isDownloadingXml: false,
      isDownloadingExcel: false,
    });

    const attachment = ref(null);

    const publicationPlaceholder = computed(() => `GPC as of ${publication.publicationDate}`);

    const levels = Object.values(gpcEnumRepository.ClassificationLevel);
    const publicationDeltaSummaryDictionary = computed(() => {
      const deltaLevelDictionary = Object.values(gpcEnumRepository.ClassificationLevel).reduce((prev, curr) => {
        prev[curr] = {
          added: 0,
          updated: 0,
          removed: 0,
          moved: 0,
        };
        return prev;
      }, {});

      publicationDelta.value.forEach((item) => {
        if (item.state == gpcEnumRepository.ClassificationState.Added || item.state == gpcEnumRepository.ClassificationState.Migrated) {
          ++deltaLevelDictionary[item.level].added;
        }
        if (item.state == gpcEnumRepository.ClassificationState.Updated) {
          ++deltaLevelDictionary[item.level].updated;
        }
        if (item.state == gpcEnumRepository.ClassificationState.Removed) {
          ++deltaLevelDictionary[item.level].removed;
        }
        if (item.state == gpcEnumRepository.ClassificationState.Moved) {
          ++deltaLevelDictionary[item.level].moved;
        }
      });

      return deltaLevelDictionary;
    });
    const relationDeltaAddedCount = computed(() => {
      return publicationRelationDelta.value.filter((f) => f.state == gpcEnumRepository.RelationState.Added).length;
    });
    const relationDeltaRemovedCount = computed(() => {
      return publicationRelationDelta.value.filter((f) => f.state == gpcEnumRepository.RelationState.Removed).length;
    });

    onMounted(async () => {
      publication.publicationId = props.inputPublication.publicationId;
      publication.publicationName = props.inputPublication.publicationName;
      publication.publicationDate = props.inputPublication.publicationDate
        ? props.inputPublication.publicationDate.split("T")[0]
        : new Date().toISOString().substring(0, 10);
      publication.browser = props.inputPublication.browser;
      publication.isGDSN = props.inputPublication.isGDSN;
      publication.releaseNoteAttachment = props.inputPublication.releaseNoteAttachment;

      if (publication.publicationId != 0) {
        panelTitle.value = publication.publicationName;
      } else {
        publication.publicationName = publicationPlaceholder.value;
        publication.browser = true;
      }

      const response = await fetchPublicationClassificationDeltaAsync(
        languageId.value,
        publication.publicationId == 0 ? null : publication.publicationId
      );
      if (!response.isSuccess) {
        alert.error([response.message]);
      } else {
        publicationDelta.value = response.result;
      }

      const relationResponse = await fetchPublicationRelationDeltaAsync(
        languageId.value,
        publication.publicationId == 0 ? null : publication.publicationId
      );
      if (!relationResponse.isSuccess) {
        alert.error([relationResponse.message]);
      } else {
        publicationRelationDelta.value = relationResponse.result;
      }
    });

    async function clickSavePublication() {
      if (isSavingPublication.value) {
        return;
      }
      
      const response = await savePublicationAsync(publication);
      if (!response.isSuccess) {
        alert.error([response.message]);
        return;
      }

      if (response.statusCode == 204) {
        showToast(response.message);
      }
      if (response.statusCode == 201) {
        showToast("Notification emails has been sent");
      }
      if (attachment.value != null && publication.publicationId == 0) {
        attachment.value.set("PublicationId", response.result);
        await uploadAttachmentFile();
      }

      if (publication.browser && user.value.language.usePublicationBlobs) {
        if (publication.isGDSN && isAdmin.value) {
          uploadAllBlobsAsync();
          showToast("Processing GDSN publication files...");
        } else {
          uploadBlobsAsync(publication.publicationId == 0 ? response.result : publication.publicationId);
          showToast("Processing publication files...");
        }
      }

      emit("onSave", publication);
    }

    function clickClose() {
      if (isDirty()) {
        if (acceptUnsavedChanges()) {
          emit("onClose");
        }
      } else {
        emit("onClose");
      }
    }

    function isDirty() {
      return (
        publication.publicationId == 0 ||
        publication.publicationName != props.inputPublication.publicationName ||
        publication.browser != props.inputPublication.browser ||
        publication.isGDSN != props.inputPublication.isGDSN
      );
    }

    function getLevelName(level) {
      if (isAttributeValue({ level })) {
        return "Attribute Values";
      }
      return getClassificationLevelName(level);
    }

    async function clickExportDeltaJson() {
      if (isDownloadingPublicationDeltaJson.value) {
        return;
      }
      let data = await exportPublicationDeltaAsJsonAsync(languageId.value, publication.publicationId == 0 ? null : publication.publicationId);
      downloadJson(data.name, data.file);
    }

    async function clickExportJson() {
      if (exportState.isDownloadingJson) {
        return;
      }
      exportState.isDownloadingJson = true;
      let data = await exportAsJsonAsync(
        publication.publicationId == 0 ? user.value.language.languageId : publication.publicationId,
        publication.publicationId == 0,
        true
      );
      downloadJson(data.name, data.file);
      exportState.isDownloadingJson = false;
    }

    async function clickExportXml() {
      if (exportState.isDownloadingXml) {
        return;
      }
      exportState.isDownloadingXml = true;
      let data = await exportAsXmlAsync(
        publication.publicationId == 0 ? user.value.language.languageId : publication.publicationId,
        publication.publicationId == 0,
        true
      );
      downloadXml(data.name, data.file);
      exportState.isDownloadingXml = false;
    }

    async function clickExportExcel() {
      if (exportState.isDownisDownloadingExcelloadingJson) {
        return;
      }
      exportState.isDownloadingExcel = true;
      let data = await exportAsXlsxAsync(
        publication.publicationId == 0 ? user.value.language.languageId : publication.publicationId,
        publication.publicationId == 0,
        true
      );
      downloadXlsx(data.name, data.file);
      exportState.isDownloadingExcel = false;
    }

    async function clickExportReportExcel() {
      if (isDownloadingPublicationDeltaXlsx.value) {
        return;
      }
      let data = await exportPublicationDeltaAsXlsxAsync(languageId.value, publication.publicationId == 0 ? null : publication.publicationId);
      downloadXlsx(data.name, data.file);
    }

    function selectAttachmentFile(event) {
      let file = event.target.files[0];

      if (file == null) {
        return;
      }

      attachment.value = new FormData();
      attachment.value.append("File", file);
      attachment.value.append("PublicationId", publication.publicationId);
    }

    async function uploadAttachmentFile() {
      if (attachment.value == null) {
        return;
      }
      const response = await uploadReleaseNoteAttachmentAsync(attachment.value);
      if (response.isSuccess) {
        publication.releaseNoteAttachment = "file";
        alert.success(["Successfully uploaded!"]);
      } else {
        alert.error([response.message]);
      }
    }
    async function deleteAttachmentFile() {
      attachment.value = null;
      if (publication.publicationId == 0 || publication.releaseNoteAttachment == null || publication.releaseNoteAttachment == "") {
        return;
      }
      const response = await deleteReleaseNoteAttachmentAsync(publication.publicationId);
      if (response.isSuccess) {
        alert.success(["Successfully deleted attachment!"]);
        publication.releaseNoteAttachment = "";
      } else {
        alert.error([response.message]);
      }
    }

    async function removePublication() {
      if (publication.publicationId == 0) {
        return;
      }
      let isOk = confirm("Do you want to delete this publication?");
      if (!isOk) {
        return;
      }
      const response = await deletePublicationAsync(publication.publicationId);
      if (response.isSuccess) {
        emit("onSave", null);
      } else {
        alert.error([response.message]);
      }
    }

    return {
      isAdmin,
      publication,
      publicationPlaceholder,
      panelTitle,
      bindableAlert: alert.bindableAlert,
      exportState,
      clickExportJson,
      clickExportXml,
      clickExportExcel,
      clickSavePublication,
      clickClose,
      isSavingPublication,
      publicationValidation,
      isFetchingPublicationDelta,
      publicationDeltaSummaryDictionary,
      levels,
      getLevelName,
      isDownloadingPublicationDeltaJson,
      isDownloadingPublicationDeltaXlsx,
      clickExportDeltaJson,
      clickExportReportExcel,
      selectAttachmentFile,
      uploadAttachmentFile,
      isUploadingReleaseNoteAttachment,
      isDeletingReleaseNoteAttachment,
      deleteAttachmentFile,
      relationDeltaAddedCount,
      relationDeltaRemovedCount,
      attachment,
      removePublication,
    };
  },
};
</script>
