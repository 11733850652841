<template>
  <div>
    <div class="panel rounded-0 bg-white mt-3 p-0">
      <div class="g-0 panel-bar" :style="{ backgroundColor: bindedColor }"></div>
      <div class="p-3">
        <div class="col-12" v-if="title != null">
          <div class="panel-title text-start">{{ title }}</div>
          <hr />
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    barColor: {
      type: String,
      default: "orange",
    },
  },
  setup(props) {
    const bindedColor = computed(() => {
      switch (props.barColor) {
        case "orange":
          return "#f26334";
        case "blue":
          return "#002c6c";
        case "sky":
          return "#00b6de";
        default:
          return "#f26334";
      }
    });

    return { bindedColor };
  },
};
</script>

<style lang="scss">
.panel {
  border: 1px solid $gs1-color-gray-lm;
  border-top: 0;

  & .gpc-table{
    & tr{
      cursor: default;
    }
  }

  & input, textarea{
    font-weight: 300;
  }

  & label{
    font-weight: 400;
  }

  & .panel-bar {
    height: 7px;
    background-color: $gs1-color-orange;
  }

  & .panel-title {
    font-weight: 400;
    color: $gs1-color-blue;
    font-size: 18px;
  }

  & .disabled {
    cursor: default;
    user-select: none;
    pointer-events: none;
    opacity: 0.3;
  }

  & .button-icon {
    cursor: pointer;
    height: 30px;
    width: 30px;

    & svg {
      height: 30px;
      width: 30px;
    }
  }
}
</style>
