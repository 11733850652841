<template>
  <div v-show="scene.showPublicationUpdate">
    <publication-update @onMerge="handleMergePublication"></publication-update>
  </div>
  <div class="row">
    <loader v-if="isFetchingPublications"></loader>
    <panel v-show="scene.showPublication && !isFetchingPublications">
      <div class="row align-items-center">
        <div class="col-4 d-flex align-items-start p-1">
          <div class="button-icon ms-2" v-if="isAdmin" @click="scene.showPublicationMenu = !scene.showPublicationMenu" title="Menu">
            <font-awesome icon="bars"></font-awesome>
          </div>
        </div>
        <div class="col-4 align-self-center panel-title p-1">{{ mainTitle }}</div>
        <div class="col-4 d-flex justify-content-end">
          <div v-if="isAdmin" class="ms-2" title="Create New Publication">
            <span @click="addorUpdatePublication(null)">
              <font-awesome icon="plus-circle" class="button-icon"></font-awesome>
            </span>
          </div>
        </div>
        <div class="col-12" v-if="scene.showPublicationMenu">
          <publication-menu @onImport="handleImportPublication"></publication-menu>
        </div>
        <div class="col-12 text-start mt-2">
          <alert v-bind="bindableAlert" class="mt-3 mb-3"></alert>
          <div class="gpc-table table-responsive">
            <table class="table table-hover table-sm" aria-label="Publication overview table">
              <thead>
                <tr>
                  <th scope="col" class="col-2 fw-bold">Publication Date</th>
                  <th scope="col" class="col fw-bold">Publication Name</th>
                  <th scope="col" class="col-2 fw-bold">Published by</th>
                  <th scope="col" class="col-1 fw-bold">Browser</th>
                  <th scope="col" class="col-1 fw-bold">GDSN</th>
                  <th scope="col" class="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="publication in publicationsToShow" :key="publication.id">
                  <td>{{ new Date(publication.publicationDate).toISOString().slice(0, 10) }}</td>
                  <td>{{ publication.publicationName }}</td>
                  <td>{{ publication.userName }}</td>
                  <td class="align-middle"><input class="form-check-input" type="checkbox" v-model="publication.browser" disabled /></td>
                  <td class="align-middle"><input class="form-check-input" type="checkbox" v-model="publication.isGDSN" disabled /></td>
                  <td>
                    <span class="float-end" @click="addorUpdatePublication(publication)">
                      <font-awesome icon="arrow-circle-right" class="hw-20"></font-awesome>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </panel>
  </div>
  <div class="row" v-if="scene.showPublicationItem">
    <publication-item
      @onSave="handleSavePublicationItem"
      @onClose="handleClosePublicationItem"
      :inputPublication="inputPublication"
    ></publication-item>
  </div>
  <div class="row" v-if="scene.showPublicationTable">
    <publication-table></publication-table>
  </div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import Loader from "../components/shared/Loader.vue";
import usePublication from "../composables/publication/usePublication";
import { computed, onMounted } from "@vue/runtime-core";
import Alert from "../components/shared/Alert.vue";
import useUserSingleton from "../composables/shared/useUserSingleton";
import Panel from "../components/shared/Panel.vue";
import useAlert from "../composables/shared/useAlert";
import PublicationItem from "../components/publication/PublicationItem.vue";
import PublicationTable from "../components/publication/PublicationTable.vue";
import PublicationMenu from "../components/publication/PublicationMenu.vue";
import PublicationUpdate from "../components/publication/PublicationUpdate.vue";

export default {
  components: { Loader, Alert, Panel, PublicationItem, PublicationTable, PublicationMenu, PublicationUpdate },
  setup() {
    const alert = useAlert();
    const { isAdmin, isMainLanguage, languageId } = useUserSingleton();
    const { isFetchingPublications, fetchPublicationsAsync } = usePublication();

    const publications = ref([]);
    const mainTitle = ref(`Publications`);

    const scene = reactive({
      showPublication: true,
      showPublicationMenu: false,
      showPublicationTable: !isMainLanguage.value,
      showPublicationItem: false,
      showPublicationUpdate: true,
    });

    const inputPublication = ref({});

    const publicationsToShow = computed(() => {
      return publications.value.sort(function(a, b) {
        return new Date(b.publicationDate) - new Date(a.publicationDate);
      });
    });

    onMounted(() => {
      fetchPublications();
    });

    async function fetchPublications() {
      const response = await fetchPublicationsAsync(languageId.value);
      if (!response.isSuccess) {
        alert.error([response.message]);
      }
      publications.value = response.result;
    }

    function addorUpdatePublication(publication) {
      if (publication == null) {
        inputPublication.value = { publicationId: 0 };
      } else {
        inputPublication.value = publication;
      }
      scene.showPublicationItem = true;
      scene.showPublication = false;
      scene.showPublicationTable = false;
    }

    function handleSavePublicationItem(publication) {
      fetchPublications();
      const message = publication ? "Saved successfully!" : "Removed successfully!";
      alert.success([message]);
      handleClosePublicationItem();
    }

    function handleClosePublicationItem() {
      scene.showPublicationItem = false;
      scene.showPublication = true;
      scene.showPublicationTable = !isMainLanguage.value;
    }

    function handleImportPublication() {
      fetchPublications();
    }

    function handleMergePublication(response) {
      if (!response.isSuccess) {
        alert.error([response.message]);
      } else {
        alert.success(["Merged successfully!"]);
        scene.showPublicationUpdate = false;
        fetchPublications();
      }
    }

    return {
      isAdmin,
      mainTitle,
      scene,
      isFetchingPublications,
      publicationsToShow,
      bindableAlert: alert.bindableAlert,
      addorUpdatePublication,
      inputPublication,
      handleSavePublicationItem,
      handleClosePublicationItem,
      handleImportPublication,
      handleMergePublication,
    };
  },
};
</script>
