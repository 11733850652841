import nonce from "nonce";
import appSettings from "../configuration/appSettings";
import moment from "moment";
import jwt from "jsonwebtoken";
moment.suppressDeprecationWarnings = true;

export default (function() {
  const SSO_HOST = appSettings.SSO_OPEN_ID_PROVIDER;
  const SSO_LOGIN_PATH = `${SSO_HOST}/connect/authorize`;
  const SSO_LOGOUT_PATH = `${SSO_HOST}/connect/endsession`;
  const SSO_OPTIONS = {
    client_id: appSettings.SSO_CLIENT_ID,
    scope: "openid profile",
    response_type: "id_token token",
    redirect_uri: appSettings.SSO_REDIRECT_URL,
  };
  const generateNonceFn = nonce();

  function isAuthenticated() {
    return userManger.isUserAuthenticated();
  }

  function isAuthorized() {
    return isAuthenticated() && userManger.isUserAuthorized();
  }

  function getAuthToken() {
    return isAuthenticated() ? `Bearer ${sessionManager.getSession().accessToken}` : "";
  }

  function getUser() {
    return userManger.getUser();
  }

  function isSessionExpired() {
    return sessionManager.isSessionExpired();
  }

  function authenticate(hash) {
    const params = parseHash(hash);
    if (window.top !== window.self) { //function called from iframe
      window.top.postMessage({ hash, refreshToken: true, ...params }, window.location.origin);
      return;
    }
    const { id: storedIdToken, accessToken: storedAccessToken } = sessionManager.getSession();
    const isValidStoredSession = storedIdToken && storedAccessToken && !sessionManager.isSessionExpired();
    const isVaidParams = params && params.id_token && params.access_token;
    if (isValidStoredSession || isVaidParams) {
      params.id_token = params.id_token || storedIdToken;
      params.access_token = params.access_token || storedAccessToken;
      if (params.id_token && params.access_token) {
        const userOk = userManger.setUser(params);
        if (userOk) {
          sessionManager.setSession(params);
        } else {
          sessionManager.unsetSession();
        }
      }
    }
    setTimeout(function() {
      history.replaceState(history.state, document.title, window.location.pathname + window.location.search);
    }, 100);
  }

  function login() {
    const url = new URL(SSO_LOGIN_PATH, SSO_HOST);
    url.search = new URLSearchParams({
      ...SSO_OPTIONS,
      nonce: generateNonceFn(),
    });
    window.location = url;
  }

  function logout() {
    userManger.removeUser();
    sessionManager.removeSession();
  }

  async function silentLoginAsync() {
    try {
      let refreshToken = null;
      do {
        refreshToken = await new Promise((resolve) => window.top.addEventListener("message", resolve, { once: true }));
      } while (refreshToken.data && !refreshToken.data.refreshToken);

      if (refreshToken.data.error) {
        return false;
      }
      sessionManager.setSession(refreshToken.data);
      return true;
    } catch (e) {
      logout();
      return false;
    }
  }

  function fullLogout() {
    const url = new URL(SSO_LOGOUT_PATH, SSO_HOST);
    const { client_id, scope } = SSO_OPTIONS;
    url.search = new URLSearchParams({
      client_id,
      scope,
      id_token_hint: sessionManager.getSession().id,
      post_logout_redirect_uri: SSO_OPTIONS.redirect_uri,
    });
    window.location = url;
  }

  function parseHash(hash) {
    const params = {};
    (hash || "")
      .replace(/^#/, "")
      .split("&")
      .forEach((item) => {
        let splitedValues = item.split("=");
        if (splitedValues.length == 2) {
          params[splitedValues[0].trim()] = splitedValues[1].trim();
        }
      });
    return params;
  }

  function getSilentLoginUrl() {
    const url = new URL(SSO_LOGIN_PATH, SSO_HOST);
    url.search = new URLSearchParams({
      ...SSO_OPTIONS,
      prompt: "none",
      nonce: generateNonceFn(),
    });
    return url;
  }

  return Object.freeze({
    getAuthToken,
    isAuthenticated,
    isAuthorized,
    isSessionExpired,
    getUser,
    authenticate,
    login,
    logout,
    fullLogout,
    silentLoginAsync,
    getSilentLoginUrl,
    setExpirationNotificationCallback: (fn) => sessionManager.setExpirationNotificationCallback(fn),
  });
})();

const sessionManager = (function() {
  const STORAGE_KEY_PREFIX = "SESSION";
  const STORAGE_ID_KEY = `${STORAGE_KEY_PREFIX}_ID`;
  const STORAGE_ACCESS_KEY = `${STORAGE_KEY_PREFIX}_TOKEN`;
  const STORAGE_EXPIRATION_KEY = `${STORAGE_KEY_PREFIX}_EXPIRE`;
  const SESSION_PROMPT_TIME_TO_EXPIRE = +(appSettings.SESSION_PROMPT_TIME_TO_EXPIRE || 0.7).toString().replace(/^(\d+)%$/, (v, n) => +n * 0.01);

  const sessionData = {
    id: localStorage.getItem(STORAGE_ID_KEY) || "",
    accessToken: localStorage.getItem(STORAGE_ACCESS_KEY) || "",
    expiration: moment(localStorage.getItem(STORAGE_EXPIRATION_KEY) || ""),
  };

  let expirationCallback = (time) => time;

  const getSession = () => sessionData;

  function setSession(session) {
    sessionData.id = session && (session.id_token || session.id);
    sessionData.accessToken = session && (session.access_token || session.accessToken);
    sessionData.expiration = session && getSessionExpiration(session);
    localStorage.setItem(STORAGE_ID_KEY, sessionData.id);
    localStorage.setItem(STORAGE_ACCESS_KEY, sessionData.accessToken);
    localStorage.setItem(STORAGE_EXPIRATION_KEY, sessionData.expiration);
    runExpirationJob();
  }

  function removeSession() {
    sessionData.id = "";
    sessionData.accessToken = "";
    sessionData.expiration = "";
    localStorage.removeItem(STORAGE_ID_KEY);
    localStorage.removeItem(STORAGE_ACCESS_KEY);
    localStorage.removeItem(STORAGE_EXPIRATION_KEY);
    if (expirationJob) {
      clearTimeout(expirationJob);
    }
  }

  let expirationJob = null;
  function runExpirationJob() {
    if (expirationJob) {
      clearTimeout(expirationJob);
    }

    let expiration = sessionData.expiration;
    let now = moment();
    let duration = moment.duration(
      SESSION_PROMPT_TIME_TO_EXPIRE <= 1.0
        ? expiration.diff(now) * SESSION_PROMPT_TIME_TO_EXPIRE
        : expiration
            .clone()
            .subtract(SESSION_PROMPT_TIME_TO_EXPIRE, "seconds")
            .diff(now)
    );

    expirationJob = setTimeout(() => {
      if (expirationCallback != null && typeof expirationCallback === "function") {
        expirationCallback(moment.duration(expiration.diff(moment())).as("minutes"));
      }
    }, duration);
  }

  const getSessionExpiration = (session) => {
    if (session.expires_in) {
      return moment().add(parseInt(session.expires_in), "seconds");
    }
    if (sessionData.expiration) {
      return moment(sessionData.expiration);
    }
    return moment();
  };

  const isSessionExpired = () => !(sessionData.id && sessionData.expiration && sessionData.expiration.isAfter(moment()));

  return Object.freeze({
    setExpirationNotificationCallback: (fn) => {
      expirationCallback = fn;
    },
    getSession,
    setSession,
    removeSession,
    runExpirationJob,
    isSessionExpired,
  });
})();

const userManger = (function() {
  const CLAIM_URL = "http://gs1.org/claims/crm-";
  const GPC_STATUS = "Active";
  let authUser = null;

  const getUser = () => authUser;
  const isUserAuthenticated = () => authUser != null;
  const isUserAuthorized = () =>
    isUserAuthenticated() && authUser.gpcStatus == GPC_STATUS && authUser.gpcRole != null && authUser.gpcLanguage;
  const removeUser = () => (authUser = null);
  const setUser = (params) => tryExtractUserClaimsFromToken(params.access_token);

  function tryExtractUserClaimsFromToken(token) {
    try {
      let accessDecoded = jwt.decode(token),
        {
          [`${CLAIM_URL}UserID`]: userId,
          [`${CLAIM_URL}Email`]: email,
          [`${CLAIM_URL}FirstName`]: firstName,
          [`${CLAIM_URL}LastName`]: lastName,
          [`${CLAIM_URL}CompanyID`]: companyId,
          [`${CLAIM_URL}MOID`]: moId,
          [`${CLAIM_URL}CompanyName`]: companyName,
          [`${CLAIM_URL}TimeZone`]: timezone,
          [`${CLAIM_URL}GPCStatus`]: gpcStatus,
          [`${CLAIM_URL}GPCRole`]: gpcRole,
          [`${CLAIM_URL}GPCLanguage`]: gpcLanguage,
        } = accessDecoded;

      authUser = {
        userId,
        email,
        firstName,
        lastName,
        timezone,
        company: { companyId, name: companyName, moId },
        gpcStatus,
        gpcRole,
        gpcLanguage: JSON.parse(gpcLanguage),
      };
      return authUser;
    } catch (error) {
      return null;
    }
  }

  return Object.freeze({
    getUser,
    isUserAuthenticated,
    isUserAuthorized,
    setUser,
    removeUser,
  });
})();
