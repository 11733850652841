import { computed, ref } from "vue";
import gpcPublishedSchemaRepository from "../../repositories/gpcPublishedSchemaRepository";
import gpcSchemaRepository from "../../repositories/gpcSchemaRepository";

export default function useSchemaExport() {
  const isExportingJson = ref(false);
  const isExportingXml = ref(false);
  const isExportingXlsx = ref(false);

  async function exportAsJsonAsync(id, isLive = true, isPublication = false) {
    isExportingJson.value = true;
    let response = null;
    if (isLive) {
      response = await gpcSchemaRepository.exportAsJsonAsync(id, isPublication);
    } else {
      response = await gpcPublishedSchemaRepository.exportPublishedSchemaAsJsonAsync(id);
    }
    isExportingJson.value = false;
    return response;
  }

  async function exportAsXmlAsync(id, isLive = true, isPublication = false) {
    isExportingXml.value = true;
    let response = null;
    if (isLive) {
      response = await gpcSchemaRepository.exportAsXmlAsync(id, isPublication);
    } else {
      response = await gpcPublishedSchemaRepository.exportPublishedSchemaAsXmlAsync(id);
    }
    isExportingXml.value = false;
    return response;
  }

  async function exportAsXlsxAsync(id, isLive = true, isPublication = false) {
    isExportingXlsx.value = true;
    let response = null;
    if (isLive) {
      response = await gpcSchemaRepository.exportAsXlsxAsync(id, isPublication);
    } else {
      response = await gpcPublishedSchemaRepository.exportPublishedSchemaAsXlsxAsync(id);
    }
    isExportingXlsx.value = false;
    return response;
  }
  return {
    isExportingXlsx: computed(() => isExportingXlsx.value),
    isExportingXml: computed(() => isExportingXml.value),
    isExportingJson: computed(() => isExportingJson.value),
    exportAsJsonAsync,
    exportAsXmlAsync,
    exportAsXlsxAsync,
  };
}
