import { reactive } from "vue";

export default function useAlert() {
  const alert = reactive({
    show: false,
    type: 0,
    header: "",
    messages: [],
 });

  const showAlert = (messages, type, header = null) => {
    alert.show = true;
    alert.type = type;
    if (header == null) {
      switch (type) {
        case 1:
          alert.header = "Success";
          break;
        case 2:
          alert.header = "Error";
          break;
        case 3:
          alert.header = "Warning";
          break;
        default:
          alert.header = "Info";
      }
    } else {
      alert.header = header;
    }

    alert.messages = messages;
  };

  const hide = () => {
    alert.show = false;
  };

  const success = (messages) => {
    showAlert(messages, 1);
  };

  const error = (messages) => {
    showAlert(messages, 2);
  };

  const warning = (messages) => {
    showAlert(messages, 3);
  };

  return {
    bindableAlert: alert,
    hide,
    success,
    error,
    warning
  };
}
