<template>
  <div class="view-menu card card-body mt-2 rounded-0">
    <div v-if="!(isExportingXlsx || isImportingXlsx)">
      <label for="excelFile" class="form-label">Download/Upload Translation sheet</label>
      <div class="input-group mb-1">
        <button class="btn btn-outline-secondary" type="button" @click="clickDownload" title="Download Translation sheet as .xlsx">Download</button>
        <input class="form-control" type="file" id="excelFile" @change="selectFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
        <button class="btn btn-outline-secondary" type="button" @click="clickUpload" title="Upload Translation sheet as .xlsx">Upload</button>
      </div>
    </div>
    <spinner v-if="isExportingXlsx || isImportingXlsx" :text="spinnerText" class="d-flex justify-content-center"></spinner>
    <alert v-bind="bindableAlert" class="mt-3 mb-3"></alert>
  </div>
</template>

<script>
import useTranslation from "../../composables/translation/useTranslation";
import Alert from "../shared/Alert.vue";
import useAlert from "../../composables/shared/useAlert";
import { downloadXlsx } from "../../utils/fileUtil";
import { reactive, ref } from "vue";
import Spinner from "../shared/Spinner.vue";
import useUserSingleton from "../../composables/shared/useUserSingleton";

export default {
  components: { Alert, Spinner },
  emits: ["onImport"],
  setup(props, { emit }) {
    const { user } = useUserSingleton();
    const alert = useAlert();
    const { isExportingXlsx, exportAsXlsxAsync, isImportingXlsx, importTranslationAsync } = useTranslation();

    const spinnerText = ref("Loading");

    const toImport = reactive({
      languageId: user.value.language.languageId,
      languageCode: user.value.language.languageCode,
      name: "",
      data: null,
      formData: null,
    });

    function selectFile(event) {
      let file = event.target.files[0];

      if (file == null) {
        return;
      }

      toImport.formData = new FormData();
      toImport.formData.append("File", file);
      toImport.name = file.name;
      toImport.data = file;
    }

    async function clickUpload() {
      if (toImport.data == null) {
        alert.warning(["Please Choose File."]);
        return;
      }

      spinnerText.value = "Uploading...";

      const response = await importTranslationAsync(toImport);

      if (!response.isSuccess) {
        alert.error([response.message]);
        return;
      }

      emit("onImport");

      if (response.isSuccess && response.statusCode == 206) {
        alert.warning(response.message.split(/\r?\n/));
        return;
      }

      alert.success(["Imported successfully!"]);
    }

    async function clickDownload() {
      if (isExportingXlsx.value) {
        return;
      }

      spinnerText.value = "Downloading...";

      let data = await exportAsXlsxAsync(user.value.language.languageId);
      downloadXlsx(data.name, data.file);
    }
    return {
      props,
      isExportingXlsx,
      isImportingXlsx,
      clickUpload,
      clickDownload,
      selectFile,
      spinnerText,
      bindableAlert: alert.bindableAlert,
    };
  },
};
</script>

<style lang="scss" scoped>
.view-menu {
  background-color: #f4f4f4;
}
</style>
